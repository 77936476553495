import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input } from 'reactstrap';
import Flatpickr from 'react-flatpickr';

const EffectMetaForm = ({ fields, meta, handleChangeMeta }) => {
  return fields?.map((field) => {
    let fieldId = field?.values?.find(
      (v) => v?.fieldCode === 'fieldId'
    )?.fieldValue;

    if (!Object?.keys(meta)?.includes(fieldId)) {
      return null;
    }

    const fieldLabel = field?.values?.find(
      (v) => v?.fieldCode === 'configLabel'
    )?.fieldValue;

    let dataType = field?.values?.find(
      (v) => v?.fieldCode === 'dataType'
    )?.fieldValue;

    let fieldType =
      field?.values?.find((v) => v?.fieldCode === 'fieldType')?.fieldValue ||
      null;

    const options =
      field?.values?.find((v) => v?.fieldCode === 'optionValues')?.fieldValue ||
      [];

    dataType = dataType?.toLowerCase();
    fieldType = fieldType?.toLowerCase();

    let inputElement;
    if (fieldType === 'switch' || dataType === 'boolean') {
      inputElement = (
        <Input
          id={fieldId}
          name={fieldId}
          type="switch"
          role="switch"
          checked={meta?.[fieldId]}
          onChange={() => {
            handleChangeMeta({
              value: !meta?.[fieldId],
              fieldId: fieldId,
            });
          }}
        />
      );
    } else if (fieldType === 'number' || dataType === 'number') {
      inputElement = (
        <Input
          id={fieldId}
          name={fieldId}
          type="number"
          value={meta?.[fieldId]}
          onChange={(e) => {
            handleChangeMeta({
              value: parseFloat(e?.target?.value),
              fieldId: fieldId,
            });
          }}
        />
      );
    } else if (fieldType === 'text' || dataType === 'string') {
      inputElement = (
        <Input
          id={fieldId}
          name={fieldId}
          type="text"
          value={meta?.[fieldId]}
          onChange={(e) => {
            handleChangeMeta({ value: e?.target?.value, fieldId: fieldId });
          }}
        />
      );
    } else if (fieldType === 'textarea' || dataType === 'text') {
      inputElement = (
        <Input
          id={fieldId}
          name={fieldId}
          type="textarea"
          value={meta?.[fieldId]}
          onChange={(e) => {
            handleChangeMeta({ value: e?.target?.value, fieldId: fieldId });
          }}
        />
      );
    } else if (dataType === 'date') {
      inputElement = (
        <Flatpickr
          id={fieldId}
          value={meta?.[fieldId]}
          onChange={(date) => {
            handleChangeMeta({ value: date[0], fieldId: fieldId });
          }}
          options={{ dateFormat: 'Y-m-d' }}
          className="form-control"
        />
      );
    } else if (dataType === 'datetime') {
      inputElement = (
        <Flatpickr
          id={fieldId}
          value={meta?.[fieldId]}
          onChange={(date) => {
            handleChangeMeta({ value: date[0], fieldId: fieldId });
          }}
          options={{
            enableTime: true,
            dateFormat: 'Y-m-d H:i',
            time_24hr: false,
          }}
          className="form-control"
        />
      );
    } else if (fieldType === 'select') {
      inputElement = (
        <Input
          id={fieldId}
          name={fieldId}
          type="select"
          value={meta?.[fieldId]}
          onChange={(e) => {
            handleChangeMeta({ value: e?.target?.value, fieldId: fieldId });
          }}
        >
          <option value="">Select an option</option>
          {JSON.parse(options)?.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </Input>
      );
    } else if (fieldType === 'multiselect') {
      inputElement = (
        <Input
          id={fieldId}
          name={fieldId}
          type="select"
          value={meta?.[fieldId]}
          onChange={(e) => {
            const selectedOptions = Array?.from(
              e?.target?.selectedOptions,
              (option) => option?.value
            );
            handleChangeMeta({ value: selectedOptions, fieldId: fieldId });
          }}
          onBlur={handleBlur}
          multiple
          invalid={isTouched && Boolean(hasError)}
        >
          {JSON.parse(options)?.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </Input>
      );
    } else if (fieldType === 'radio') {
      inputElement = JSON.parse(options)?.map((option) => (
        <div className="form-check" key={option?.value}>
          <Input
            id={`${fieldId}_${option?.value}`}
            name={fieldId}
            type="radio"
            value={option?.value}
            checked={meta?.[fieldId] === option?.value}
            onChange={(e) => {
              handleChangeMeta({ value: e?.target?.value, fieldId: fieldId });
            }}
          />
          <Label check for={`${fieldId}_${option?.value}`}>
            {option?.label}
          </Label>
        </div>
      ));
    } else {
      inputElement = (
        <Input
          id={fieldId}
          name={fieldId}
          type="text"
          value={meta?.[fieldId]}
          onChange={(e) => {
            handleChangeMeta({ value: e?.target?.value, fieldId: fieldId });
          }}
        />
      );
    }

    return (
      <FormGroup
        key={field?._id || fieldId}
        switch={dataType === 'boolean'}
        className={dataType === 'boolean' ? 'ms-2' : ''}
      >
        <Label for={fieldId}>{fieldLabel}</Label>
        {inputElement}
      </FormGroup>
    );
  });
};

EffectMetaForm.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  meta: PropTypes.object.isRequired,
  handleChangeMeta: PropTypes.func.isRequired,
};

export default EffectMetaForm;
