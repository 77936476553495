import React, { useCallback, useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Row,
  Button,
  Input,
  Spinner,
} from 'reactstrap';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import Flatpickr from 'react-flatpickr';
import BreadCrumb from '../../../components/Common/BreadCrumb';
import SegmentTable from './segmentTable';

const promotionStatusOptions = [
  { value: '', label: 'All' },
  { value: 'published', label: 'Published' },
  { value: 'draft', label: 'Draft' },
  { value: 'updated', label: 'Updated' },
  { value: 'created', label: 'Created' },
];

const countryOptions = [
  { value: '', label: 'All' },
  { value: 'UAE', label: 'UAE' },
  { value: 'BAHRAIN', label: 'Bahrain' },
  { value: 'OMAN', label: 'Oman' },
  { value: 'QATAR', label: 'Qatar' },
];

const sortByOptions = [
  {
    label: 'Default',
    value: null,
  },
  {
    label: 'Start Date (asc)',
    value: {
      key: 'launcher.startDate',
      order: 'asc',
    },
  },
  {
    label: 'Start Date (desc)',
    value: {
      key: 'launcher.startDate',
      order: 'desc',
    },
  },
  {
    label: 'End Date (asc)',
    value: {
      key: 'launcher.endDate',
      order: 'asc',
    },
  },
  {
    label: 'End Date (desc)',
    value: {
      key: 'launcher.endDate',
      order: 'desc',
    },
  },
  {
    label: 'Creation Date (asc)',
    value: {
      key: 'createdAt',
      order: 'asc',
    },
  },
  {
    label: 'Creation Date (desc)',
    value: {
      key: 'createdAt',
      order: 'desc',
    },
  },
];

const PromotionSegmentation = () => {
  const navigate = useNavigate();
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [searchKey, setSearchKey] = useState('');
  const [dateRange, setDateRange] = useState('');
  const [selectedSortBy, setSelectedSortBy] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [pageOffset, setPageOffset] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [segments, setSegments] = useState([]);
  const [pageSize, setPageSize] = useState(10);

  const resetFilter = () => {
    setSelectedStatus(null);
    setSelectedCountry(null);
    setSearchKey('');
    setStartDate(null);
    setEndDate(null);
    setDateRange('');
    setSelectedSortBy(null);
  };

  const handleDateChange = (selectedDates) => {
    // Normalize dates to remove any time component
    const normalizedDates = selectedDates?.map(
      (date) =>
        new Date(
          Date.UTC(date?.getFullYear(), date?.getMonth(), date?.getDate())
        )
    );
    setDateRange(formatRange(normalizedDates));
  };

  const getSegmentsFunc = async () => {
    try {
      setIsLoading(true);

      const data = {
        pageOffset: pageOffset,
        pageSize: pageSize,
      };
      const res = await getSegments(data);

      setTotalPages(res?.data?.totalPages);
      setSegments(res?.data?.segments || []);
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSegmentsFunc();
  }, [pageOffset]);

  const pages = [...Array(totalPages)?.keys()];

  document.title = 'Segmentations';
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb titleInActive={'Admin'} titleActive="Segmentations" />
        <Card>
          <CardHeader className="border-0">
            <Row className="g-4 align-items-center">
              <Col sm></Col>
              <Col sm="auto">
                <div className="d-flex flex-wrap align-items-start gap-2">
                  {/* add user */}
                  <button
                    className="btn btn-success add-btn"
                    onClick={() => {
                      navigate(`/promotions/segmentation/detail/new`);
                    }}
                  >
                    <i className="ri-user-fill me-1 align-bottom"></i> Create
                    new Segment
                  </button>
                </div>
              </Col>
            </Row>
          </CardHeader>
          {/* Filters and search */}
          <CardBody className="border border border-dashed card-body">
            <div className="d-flex flex-row gap-2 flex-wrap">
              <div
                className="search-box d-inline-block"
                style={{ maxWidth: 450, minWidth: 450 }}
              >
                <Input
                  className="form-control search"
                  placeholder="Search by Segment Id / Segment Name"
                  type="text"
                  value={searchKey ?? ''}
                  onChange={(event) => setSearchKey(event?.target?.value || '')}
                />
                <i className="bx bx-search-alt search-icon"></i>
              </div>

              <div>
                <Flatpickr
                  className="form-control"
                  style={{ width: 200 }}
                  options={{
                    mode: 'range',
                    dateFormat: 'Y-m-d',
                    onChange: handleDateChange,
                  }}
                  value={dateRange}
                  placeholder="Start Date to End Date"
                />
              </div>

              <div>
                <Select
                  placeholder="Select Status"
                  options={promotionStatusOptions}
                  value={selectedStatus}
                  onChange={(option) => setSelectedStatus(option)}
                />
              </div>

              <div>
                <Select
                  placeholder="Select Country"
                  options={countryOptions}
                  value={selectedCountry}
                  onChange={(option) => setSelectedCountry(option)}
                />
              </div>

              <div>
                <Select
                  placeholder="Sort By"
                  options={sortByOptions}
                  value={selectedSortBy}
                  onChange={(option) => setSelectedSortBy(option)}
                />
              </div>

              <Button
                color="light"
                outline
                className="shadow-none py-0 lh-1 d-flex align-items-center justify-content-center border-dark-subtle"
                style={{ height: 38 }}
                onClick={() => resetFilter()}
              >
                <i className="mdi mdi-restore fs-18 text-dark"></i>
              </Button>
            </div>
          </CardBody>
          <CardBody>
            <SegmentTable
              selectedCountry={selectedCountry}
              selectedStatus={selectedStatus}
              searchKey={searchKey}
              selectedSortBy={selectedSortBy}
            />
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default PromotionSegmentation;
