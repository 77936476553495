import React from 'react';
import { Draggable } from '@hello-pangea/dnd';
import QueryBuilderApp from '../QueryBuilderApp';

import {
  Button,
  FormGroup,
  Input,
  Label,
  FormFeedback,
  Row,
  Col,
  CardBody,
  Tooltip,
  Card,
  CardHeader,
} from 'reactstrap';

import Effect from './Effect';
import ErrorMessagesInLang from '../../Configuration/Fields/ErrorMessagesInLang';

export default function Tier({
  tier,
  tIndex,
  duplicateRule,
  deleteRuleHandler,
  updateTierQuery,
  generateId,
  effectTypes,
  discountTypes,
  tiers,
  setTiers,
  duplicateEffect,
  effectChangeHandler,
  tierChangeHandler,
  deleteEffectHandler,
  rewardTypes,
  walletTypes,
  rewardList,
  promotionId,
  draghandleProps,
  enableMilestone,
}) {
  return (
    <Card className="shadow-none border">
      <CardHeader className="p-0">
        <>
          <Row>
            <Col className="d-flex align-items-center gap-4">
              <div className="d-flex align-items-center gap-2">
                <i
                  className="bx bx-grid-vertical ms-2 me-2 fs-18"
                  {...draghandleProps}
                ></i>
                <span variant="subtitle2" display="block">
                  {`Rule ${tIndex + 1}`}
                </span>
                <i> of </i>
                <span variant="subtitle2" display="block">
                  {tiers.length}
                </span>
              </div>
              <div className="d-flex align-items-center gap-2">
                <Button
                  aria-label="duplicate"
                  // color="outline-secondary"

                  color="soft-dark"
                  size="sm"
                  onClick={() => duplicateRule(tIndex)}
                  id="duplicateRule"
                  className="shadow-none rule-remove"
                >
                  <i className="bx bx-copy fs-18"></i>
                </Button>
                <Button
                  aria-label="delete"
                  color="soft-dark"
                  onClick={() => deleteRuleHandler(tIndex)}
                  size="sm"
                  className="shadow-none rule-remove btn-danger fs-20"
                >
                  <i class="bi bi-x"></i>
                </Button>
              </div>
            </Col>
          </Row>
        </>
      </CardHeader>
      <CardBody className="border-bottom">
        <Row>
          <Col>
            <label className="fs-14 fw-medium mb-2">Conditions</label>
            <QueryBuilderApp
              updateQuery={(value) => updateTierQuery(value, tIndex)}
              data={tier?.rule}
              fieldGroups={['basket', 'user', 'derived']}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <ErrorMessagesInLang
              value={tier?.conditionErrors || []}
              onChange={(values) => {
                tierChangeHandler(
                  'conditionErrors',
                  values.filter((val) => val.value),
                  tIndex
                );
              }}
            />
          </Col>
        </Row>
      </CardBody>

      <Effect
        tier={tier}
        tIndex={tIndex}
        generateId={generateId}
        effectTypes={effectTypes}
        discountTypes={discountTypes}
        tiers={tiers}
        setTiers={setTiers}
        duplicateEffect={duplicateEffect}
        effectChangeHandler={effectChangeHandler}
        deleteEffectHandler={deleteEffectHandler}
        rewardTypes={rewardTypes}
        walletTypes={walletTypes}
        rewardList={rewardList}
        promotionId={promotionId}
        enableMilestone={enableMilestone}
      />
    </Card>
  );
}
