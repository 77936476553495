import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col,
  Card,
  Form,
  FormFeedback,
  ModalFooter,
} from 'reactstrap';
import Dropzone from 'react-dropzone';
import { Link } from 'react-router-dom';
import { alertError, alertSuccess } from '../../../helpers/errorHandling';
import { generateCouponsFromCsv } from '../../../actions/promotion';
import Flatpickr from 'react-flatpickr';
import { discountTypes } from './constants';

const CreateCouponFromCsv = ({
  onCancel,
  getStores = () => {},
  setCoupons,
  coupons,
  setPageOffset,
  setTotalPages,
  setIsRefreshTable,
  promotionId,
  promotionDetail,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const csvCouponSchema = Yup?.object()?.shape({
    csvFile: Yup.mixed()
      .required('CSV file is required')
      .test(
        'fileFormat',
        'Unsupported file format. Please upload a CSV file.',
        (value) => value && value.type === 'text/csv'
      ),
    isUseLauncherConfig: Yup.boolean().required('This field is required'),
    isUseConditionsAndTriggers: Yup.boolean().required(
      'This field is required'
    ),
  });

  const formik = useFormik({
    initialValues: {
      csvFile: null,
      startsAt: null,
      expiresAt: null,
      redeemableQuantity: null,
      isUseLauncherConfig: true,
      isUseConditionsAndTriggers: true,
      discountType: 'fixed',
    },
    validationSchema: csvCouponSchema,
    onSubmit: async (values, { resetForm }) => {
      const file = values.csvFile;
      try {
        setIsLoading(true);
        const variables = {
          input: {
            file,
            promotionId,
            startsAt: values.startsAt,
            expiresAt: values.expiresAt,
            redeemableQuantity: values.redeemableQuantity,
            discountType: values.discountType,
            isUseConditionsAndTriggers: values.isUseConditionsAndTriggers,
            isUseLauncherConfig: values.isUseLauncherConfig,
          },
        };

        const { data, success, message } = await generateCouponsFromCsv(
          variables
        );
        if (data?.coupons?.length > 0 && success) {
          setCoupons([...data?.coupons]);
          setPageOffset(data?.pageOffset);
          setTotalPages(data?.totalPages);
          setIsRefreshTable(true);
        }
        if (success) {
          resetForm();
          alertSuccess(message);
        } else {
          alertError(message);
        }

        setTimeout(() => {
          onCancel();
        }, 500);
      } catch (error) {
        console.error(error);
        alertError('An error occurred while processing the file.');
      } finally {
        setIsLoading(false);
      }
    },
  });

  const {
    values,
    errors,
    touched,
    getFieldProps,
    handleSubmit,
    setFieldValue,
  } = formik;

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col>
          <Dropzone
            onDrop={(acceptedFiles) => {
              setFieldValue('csvFile', acceptedFiles[0]);
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <div className="dropzone d-flex align-items-center">
                <div className="dz-message needsclick mt-2" {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="mb-3">
                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                  </div>
                  <h4>Drop files here or click to upload.</h4>
                </div>
              </div>
            )}
          </Dropzone>
          {values?.csvFile && (
            <div className="dropzone-previews mt-3" id="file-previews">
              <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                <div className="p-2">
                  <Row className="align-items-center">
                    <Col>
                      <Link to="#" className="text-muted font-weight-bold">
                        {values?.csvFile?.name}
                      </Link>
                    </Col>
                  </Row>
                </div>
              </Card>
            </div>
          )}
          {errors?.csvFile && touched?.csvFile && (
            <div className="text-danger mt-2">{errors?.csvFile}</div>
          )}
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xl={3} lg={3} md={3} sm={12} xs={12}>
          <div className="form-check form-switch form-switch-custom form-switch-dark mb-2 mt-2 d-flex gap-2 align-items-center p-0">
            <Label className="form-check-label" htmlFor="isUseLauncherConfig">
              Use Launcher Config
            </Label>
            <Input
              type="checkbox"
              className="form-check-input m-0"
              id="isUseLauncherConfig"
              {...getFieldProps('isUseLauncherConfig')}
              checked={formik.values.isUseLauncherConfig}
            />
          </div>
        </Col>
      </Row>

      {!values?.isUseLauncherConfig ? (
        <Row spacing={3} className="mt-2">
          <Col xl={3} lg={3} md={3} sm={12} xs={12}>
            <FormGroup>
              <Label for="startsAt">Start Date</Label>
              <Flatpickr
                className="form-control"
                id="startsAt"
                name="startsAt"
                placeholder="Start Date"
                options={{
                  enableTime: true,
                  dateFormat: 'Y-m-d h:i K',
                  time_24hr: false,
                }}
                onChange={(date) => setFieldValue('startsAt', date[0])}
              />
              {touched.startsAt && errors.startsAt && (
                <div className="text-danger">{errors.startsAt}</div>
              )}
            </FormGroup>
          </Col>
          <Col xl={3} lg={3} md={3} sm={12} xs={12}>
            <FormGroup>
              <Label for="expiresAt">End Date</Label>
              <Flatpickr
                className="form-control"
                id="expiresAt"
                name="expiresAt"
                placeholder="End Date"
                options={{
                  enableTime: true,
                  dateFormat: 'Y-m-d h:i K',
                  time_24hr: false,
                }}
                onChange={(date) => setFieldValue('expiresAt', date[0])}
              />
              {touched.expiresAt && errors.expiresAt && (
                <div className="text-danger">{errors.expiresAt}</div>
              )}
            </FormGroup>
          </Col>
          <Col xl={3} lg={3} md={3} sm={12} xs={12}>
            <FormGroup>
              <Label for="redeemableQuantity">Redeemable Quantity</Label>
              <Input
                type="number"
                id="redeemableQuantity"
                name="redeemableQuantity"
                placeholder="Redeemable Quantity"
                min={0}
                {...getFieldProps('redeemableQuantity')}
                invalid={
                  touched.redeemableQuantity && !!errors.redeemableQuantity
                }
              />
              <FormFeedback>{errors.redeemableQuantity}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
      ) : null}

      <Row>
        <Col xl={3} lg={3} md={3} sm={12} xs={12}>
          <div className="form-check form-switch form-switch-custom form-switch-dark mb-2 mt-2 d-flex gap-2 align-items-center p-0">
            <Label
              className="form-check-label"
              htmlFor="isUseConditionsAndTriggers"
            >
              Use conditions and triggers
            </Label>
            <Input
              type="checkbox"
              className="form-check-input m-0"
              id="isUseConditionsAndTriggers"
              {...getFieldProps('isUseConditionsAndTriggers')}
              checked={formik.values.isUseConditionsAndTriggers}
            />
          </div>
        </Col>
      </Row>

      {!values?.isUseConditionsAndTriggers ? (
        <Col xl={3} lg={3} md={3} sm={12} xs={12}>
          <FormGroup>
            <Label for="type">Discount Type</Label>
            <Input
              type="select"
              id="discountType"
              name="discountType"
              {...getFieldProps('discountType')}
              invalid={touched?.discountType && !!errors?.discountType}
            >
              {discountTypes?.map((item) => (
                <option key={item?.value} value={item?.value}>
                  {item?.label}
                </option>
              ))}
            </Input>
            <FormFeedback>{errors?.discountType}</FormFeedback>
          </FormGroup>
        </Col>
      ) : null}

      <ModalFooter>
        <Button
          className="btn btn-light py-1 px-4"
          size="md"
          data-bs-dismiss="modal"
          onClick={onCancel}
        >
          Close
        </Button>
        <Button
          color="secondary"
          className="py-1 px-4 ms-2"
          size="md"
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? 'Processing...' : 'Create'}
        </Button>
      </ModalFooter>
    </Form>
  );
};

CreateCouponFromCsv.propTypes = {
  onCancel: PropTypes.func,
  getStores: PropTypes.func,
  setCoupons: PropTypes.func.isRequired,
  coupons: PropTypes.array.isRequired,
  setPageOffset: PropTypes.func.isRequired,
  setTotalPages: PropTypes.func.isRequired,
  setIsRefreshTable: PropTypes.func.isRequired,
  promotionId: PropTypes.string.isRequired,
  promotionDetail: PropTypes.object,
};

export default CreateCouponFromCsv;
