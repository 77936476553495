import React, { useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';

const GenerateTestCaseModal = ({ show, handleClose, saveBasketFunction }) => {
  const [testCaseFileName, setTestCaseFileName] = useState('');
  const [testCaseName, setTestCaseName] = useState('');

  const handleSave = () => {
    saveBasketFunction({ testCaseFileName, testCaseName });
    handleClose();
  };

  return (
    <Modal isOpen={show} toggle={handleClose}>
      <ModalHeader toggle={handleClose}>Generate Test Case</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="formTestCaseFileName">Test Case File Name</Label>
            <Input
              type="text"
              id="formTestCaseFileName"
              placeholder="Enter test case file name"
              value={testCaseFileName}
              onChange={(e) => setTestCaseFileName(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="formTestCaseName">Test Case Name</Label>
            <Input
              type="text"
              id="formTestCaseName"
              placeholder="Enter test case name"
              value={testCaseName}
              onChange={(e) => setTestCaseName(e.target.value)}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button color="primary" onClick={handleSave}>
          Save Changes
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default GenerateTestCaseModal;
