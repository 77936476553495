import React, { useState, useEffect } from 'react';
import { getRedemptions } from '../../../actions/promotion/redemption';
import { Col, Row, CardBody, Input, Button } from 'reactstrap';
import { formatDate } from '../../../helpers/formatDate';
import CustomPagination from '../CustomPagination';
import { Link } from 'react-router-dom';
import Flatpickr from 'react-flatpickr';
import Select from 'react-select';
import { isValidUUID } from '../../../helpers/isValidUUID';
import debounce from 'lodash.debounce';
import RedemptionItem from './RedemptionItem';

const promotionStatusOptions = [
  { value: '', label: 'All' },
  { value: 'hold', label: 'Hold' },
  { value: 'confirmed', label: 'Confirmed' },
  { value: 'canceled', label: 'canceled' },
  { value: 'expired', label: 'expired' },
];

const countryOptions = [
  { value: '', label: 'All' },
  { value: 'AE', label: 'UAE' },
  { value: 'BH', label: 'Bahrain' },
  { value: 'OM', label: 'Oman' },
  { value: 'QA', label: 'Qatar' },
];

const sortByOptions = [
  {
    label: 'Default',
    value: null,
  },
  {
    label: 'Creation Date (asc)',
    value: {
      key: 'createdAt',
      order: 'asc',
    },
  },
  {
    label: 'Creation Date (desc)',
    value: {
      key: 'createdAt',
      order: 'desc',
    },
  },
];

const PromotionRedemption = ({ promotionId }) => {
  const [redemptions, setRedemptions] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [pageOffset, setPageOffset] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [searchKey, setSearchKey] = useState('');
  const [dateRange, setDateRange] = useState('');
  const [selectedSortBy, setSelectedSortBy] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    if (pageOffset === 0) {
      getRedemptionsFunc();
    } else {
      setPageOffset(0);
    }
  }, [selectedCountry, selectedStatus, endDate, selectedSortBy]);

  const getRedemptionsFunc = async ({
    offset = pageOffset,
    searchKeyword = searchKey,
  } = {}) => {
    try {
      if (searchKeyword !== searchKey) {
        setPageOffset(0);
        setSearchKey(searchKeyword);
      }
      const inputData = {
        pageOffset: offset,
        pageSize: 6,
        promotionId: promotionId,
        filters: {
          status: selectedStatus?.value ? [selectedStatus?.value] : [],
          country: selectedCountry?.value ? [selectedCountry?.value] : [],
          searchKey: !isValidUUID(searchKeyword) ? searchKeyword : null,
          redemptionIds: isValidUUID(searchKeyword) ? [searchKeyword] : [],
          startDate: startDate,
          endDate: endDate,
        },
        sortBy: selectedSortBy?.value,
      };

      const { data, success } = await getRedemptions(inputData);
      if (success) {
        setRedemptions(data?.redemptions);
        setTotalPages(data?.totalPages);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formatRange = (selectedDates) => {
    try {
      if (selectedDates.length === 2) {
        const sDate = selectedDates[0].toISOString().split('T')[0];
        const eDate = selectedDates[1].toISOString().split('T')[0];
        setStartDate(sDate);
        setEndDate(eDate);
        return `${sDate} to ${eDate}`;
      } else {
        setStartDate(null);
        setEndDate(null);
      }
      return '';
    } catch (error) {
      console.log(error);
    }
  };

  const handleDateChange = (selectedDates) => {
    try {
      const normalizedDates = selectedDates?.map(
        (date) =>
          new Date(
            Date.UTC(date?.getFullYear(), date?.getMonth(), date?.getDate())
          )
      );
      setDateRange(formatRange(normalizedDates));
    } catch (error) {
      console.log(error);
    }
  };

  const resetFilter = () => {
    setSelectedStatus(null);
    setSelectedCountry(null);
    setSearchKey('');
    setStartDate(null);
    setEndDate(null);
    setDateRange('');
    setSelectedSortBy(null);
  };

  const paginationHandler = (offset) => {
    getRedemptionsFunc({ offset });
  };

  const handleSearch = (keyword = '') => {
    try {
      getRedemptionsFunc({
        offset: 0,
        searchKeyword: keyword,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const debouncedSearchHandler = debounce(handleSearch, 400);

  console.log(redemptions, 'redemptions');

  return (
    <>
      <CardBody className="border border border-dashed card-body">
        <div className="d-flex flex-row gap-2 flex-wrap">
          <div
            className="search-box d-inline-block"
            style={{ maxWidth: 450, minWidth: 450 }}
          >
            <Input
              className="form-control search"
              placeholder="Search by Order ref/Promotion Name/Coupon Code/Email/Mobile.."
              type="text"
              onChange={(e) => {
                debouncedSearchHandler(e?.target?.value);
              }}
            />
            <i className="bx bx-search-alt search-icon"></i>
          </div>

          <div>
            <Flatpickr
              className="form-control"
              style={{ width: 200 }}
              options={{
                mode: 'range',
                dateFormat: 'Y-m-d',
                onChange: handleDateChange,
              }}
              value={dateRange}
              placeholder="Start Date to End Date"
            />
          </div>

          <div>
            <Select
              placeholder="Select Status"
              options={promotionStatusOptions}
              value={selectedStatus}
              onChange={(option) => setSelectedStatus(option)}
            />
          </div>

          <div>
            <Select
              placeholder="Select Country"
              options={countryOptions}
              value={selectedCountry}
              onChange={(option) => setSelectedCountry(option)}
            />
          </div>

          <div>
            <Select
              placeholder="Sort By"
              options={sortByOptions}
              value={selectedSortBy}
              onChange={(option) => setSelectedSortBy(option)}
            />
          </div>

          <Button
            color="light"
            outline
            className="shadow-none py-0 lh-1 d-flex align-items-center justify-content-center border-dark-subtle"
            style={{ height: 38 }}
            onClick={() => resetFilter()}
          >
            <i className="mdi mdi-restore fs-18 text-dark"></i>
          </Button>
        </div>
      </CardBody>
      {redemptions?.length ? (
        <div className="table-responsive p-3">
          {(redemptions || [])?.map((redemption, key) => (
            <RedemptionItem redemption={redemption} key={key} />
          ))}
        </div>
      ) : (
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{ minHeight: 350 }}
        >
          <div style={{ color: '#939393' }}>
            <i className="bx bx-file-blank  p-3 fs-60"></i>
          </div>
          <div className="text-muted fs-18">No redemptions yet</div>
        </div>
      )}
      <CustomPagination
        pageOffset={pageOffset}
        totalPages={totalPages}
        setPageOffset={setPageOffset}
        paginationHandler={paginationHandler}
      />
    </>
  );
};

export default PromotionRedemption;
