import React, { useEffect, useState } from 'react';
import { If, Then } from 'react-if';
import { useSelector } from 'react-redux';
import {
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col,
  InputGroup,
} from 'reactstrap';
const ErrorMessagesInLang = ({
  onChange = () => {},
  addTitle,
  editTitle,
  ...rest
}) => {
  const { languages = [] } = useSelector((state) => state.dashboard);
  const [showErrorMessages, setShowErrorMessages] = useState(false);
  const [options, setOptions] = useState(rest.value || []);

  const handleValueChange = (index, value) => {
    const updatedOptions = [...options];
    if (!updatedOptions[index]) {
      updatedOptions[index] = {
        langCode: languages[index]?.value,
        value: value,
      };
    }

    updatedOptions[index].value = value;
    setOptions(updatedOptions);
  };

  useEffect(() => {
    onChange(options);
  }, [options]);
  // useEffect(() => {
  //   setOptions(rest.value || []);
  // }, [rest.value]);

  const [selectedLang, setSelectedLang] = useState('');

  const handleAddLanguage = () => {
    if (selectedLang) {
      const newOption = {
        langCode: selectedLang,
        value: '',
      };
      setOptions([...options, newOption]);
      setSelectedLang('');
    }
  };

  const handleRemoveLanguage = (index) => {
    const updatedOptions = options.filter((_, i) => i !== index);
    setOptions(updatedOptions);
  };

  return (
    <>
      <Label for="optionValues-value">
        <Button
          color="link"
          size="sm"
          onClick={() => setShowErrorMessages(!showErrorMessages)}
        >
          {options?.length
            ? editTitle || '✅ Error Messages Set ✍️'
            : addTitle ||
              'Set error messages if trigger conditions are not met'}{' '}
        </Button>
      </Label>
      <If condition={showErrorMessages}>
        <Then>
          <div className="border border-1 ms-2 p-2 p-3">
            <FormGroup>
              <Label for="language-select">Select Language</Label>
              <InputGroup>
                <Input
                  type="select"
                  id="language-select"
                  value={selectedLang}
                  onChange={(e) => setSelectedLang(e.target.value)}
                >
                  <option value="" disabled>
                    Select a language
                  </option>
                  {languages
                    .filter(
                      (lang) =>
                        !options.some((opt) => opt.langCode === lang.value)
                    )
                    .map((lang) => (
                      <option key={lang.value} value={lang.value}>
                        {lang.label}
                      </option>
                    ))}
                </Input>
                <Button
                  color="primary"
                  size="sm"
                  onClick={handleAddLanguage}
                  disabled={!selectedLang}
                >
                  Add Language
                </Button>
              </InputGroup>
            </FormGroup>
            {options.map((option, index) => (
              <Row className="align-items-end" key={index}>
                <Col>
                  <Label for={`field-${index}`}>
                    {
                      languages.find((lang) => lang.value === option.langCode)
                        ?.label
                    }
                  </Label>
                  <FormGroup>
                    <InputGroup>
                      <Input
                        id={`field-${index}`}
                        value={option.value}
                        onChange={(e) =>
                          handleValueChange(index, e.target.value)
                        }
                      />

                      <Button
                        color="danger"
                        size="sm"
                        onClick={() => handleRemoveLanguage(index)}
                      >
                        Remove
                      </Button>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
            ))}
          </div>
        </Then>
      </If>
    </>
  );
};

export default ErrorMessagesInLang;
