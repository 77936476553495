import React, { useState, useEffect } from 'react';
import { formatDate } from '../../../helpers/formatDate';
import { Modal } from 'reactstrap';
import PromotionRedemptionDetails from './PromotionRedemptionDetails';
const RedemptionItem = ({ redemption }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const closeModal = () => {
    setIsOpenModal(false);
  };

  return (
    <>
      <div className="card shadow-sm mb-3">
        <div className="card-body">
          <div className="row">
            {/* Left Section */}
            <div className="col-md-8 border-end pe-4 d-flex gap-3">
              <div className="icon-container">
                <span
                  className="d-flex justify-content-center align-items-center bg-light p-3 rounded-circle text-secondary"
                  style={{ fontSize: '2.5rem' }}
                >
                  <i className="bx bxs-discount"></i>
                </span>
              </div>
              <div className="details-container flex-grow-1">
                <div className="mb-2">
                  <p className="small text-muted mb-1">
                    Ref: <strong>#{redemption?.transactionRef}</strong>
                  </p>
                  <p className="small text-muted mb-1">
                    Redeemed By: <strong>{redemption?.user?._em}</strong>
                  </p>
                  <p className="small text-muted mb-1">
                    Status: <strong>{redemption?.status}</strong>
                  </p>
                </div>
                <div className="d-flex flex-wrap small text-muted gap-3">
                  <div>
                    Hold At:
                    <strong>
                      {redemption?.holdAt
                        ? formatDate(redemption?.holdAt)
                        : '-'}
                    </strong>
                  </div>
                  <div>
                    Confirmed At:
                    <strong>
                      {redemption?.confirmedAt
                        ? formatDate(redemption?.confirmedAt)
                        : '-'}
                    </strong>
                  </div>
                </div>
              </div>
            </div>

            {/* Right Section */}
            <div className="col-md-4 d-flex align-items-center justify-content-between">
              {/* Summary Section */}
              <div className="summary-container">
                <p className="small text-muted mb-1">
                  Sub Total:{' '}
                  <strong>
                    {`AED ${redemption?.response?.summary?.subTotal}` || '-'}
                  </strong>
                </p>
                <p className="small text-muted mb-1">
                  Total Discount:{' '}
                  <strong>
                    {`AED ${redemption?.response?.summary?.totalDiscount}` ||
                      '-'}
                  </strong>
                </p>
                <p className="small text-muted mb-1">
                  Grand Total:{' '}
                  <strong>
                    {`AED ${redemption?.response?.summary?.finalValue}` || '-'}
                  </strong>
                </p>
                {redemption?.response?.summary?.refundSummary?.extraPayment && (
                  <p className="small text-muted mb-1">
                    Extra Payment:{' '}
                    <strong>{`AED ${redemption?.response?.summary?.refundSummary?.extraPayment}`}</strong>
                  </p>
                )}
                {redemption?.response?.summary?.refundSummary
                  ?.finalRefundableAmount && (
                  <p className="small text-muted mb-1">
                    Refundable Amount:{' '}
                    <strong>{`AED ${redemption?.response?.summary?.refundSummary?.finalRefundableAmount}`}</strong>
                  </p>
                )}
              </div>

              {/* Button Section */}
              <div className="button-group d-flex flex-column gap-2">
                <button
                  className="btn btn-sm btn-outline-secondary me-2"
                  onClick={() => setIsOpenModal(true)}
                >
                  View Details
                </button>
                <button className="btn btn-sm btn-outline-danger me-2">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal isOpen={isOpenModal} toggle={closeModal} centered size="xl">
        <div class="modal-header">
          <h5 class="modal-title">Redemption Details</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              setIsOpenModal(false);
            }}
          ></button>
        </div>
        <div class="modal-body">
          <PromotionRedemptionDetails redemption={redemption} />
        </div>
      </Modal>
    </>
  );
};

export default RedemptionItem;
