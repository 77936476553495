import React, { useEffect, useState } from 'react';
import CouponList from './CouponList';
import AutoGenerateCoupons from './AutoGenerateCoupons';
import GenerateCouponDirect from './GenerateCouponDirect';
import CreateCouponFromCsv from './CreateCouponFromCsv';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import {
  createOrUpdateCoupon,
  getCoupons,
  deleteCouponById,
} from '../../../actions/promotion';
import { Button, Col, Modal, ModalBody, Row } from 'reactstrap';
import InnerLoader from '../../../components/Common/InnerLoader';
import CheckboxRect from '../../../components/Common/CheckboxBigRect';
import { alertSuccess } from '../../../helpers/errorHandling';
import { alertError } from '../../../helpers/errorHandling';

const createCouponOptions = [
  {
    label: 'Auto Generate',
    value: 'auto_generate',
    icon: 'ri-rocket-line',
    description: 'Generate random coupon codes in bulk using the given pattern',
  },
  {
    label: 'Direct Input',
    value: 'direct_input',
    icon: 'ri-pencil-line',
    description: 'Enter coupon codes manually',
  },
  {
    label: 'Import from CSV',
    value: 'import_data_from_csv',
    icon: 'ri-file-list-2-line',
    description: 'Import coupon codes from a CSV file',
  },
];

const CreateCouponOptions = ({
  promotionId = '',
  promotionDetail = {},
  onCancel = () => {},
}) => {
  const [selectedCreateOption, setSelectedCreateOption] =
    useState('auto_generate');

  const [coupons, setCoupons] = useState([]);
  const [pageOffset, setPageOffset] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [isShowSummery, setIsShowSummery] = useState(true);
  const [isRefreshTable, setIsRefreshTable] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [createModalOpen, setCreateModalOpen] = useState(false);

  const createOrUpdateCouponFunc = async (coupons, offset) => {
    setIsLoading(true);
    try {
      const variables = {
        input: {
          pageOffset: offset,
          pageSize: pageSize,
          coupons: coupons?.map((coupon) => ({
            code: coupon?.code,
            expiresAt: coupon?.expiresAt,
            redeemableQuantity: Number(coupon?.redeemableQuantity),
            startsAt: coupon?.startsAt,
            _id: coupon?._id || null,
            promotionId: promotionId,
            isActive: !!coupon?.isActive,
            discountType: coupon?.discountType,
          })),
        },
      };

      if (coupons?.length > 0) {
        const { success, message, data } = await createOrUpdateCoupon(
          variables
        );

        // if (success && promotionId) {
        //   navigate(`/promotions/detail/${promotionId}/conditions-effects`);
        // }

        if (success) {
          setCoupons([...data?.coupons]);
          setPageOffset(data?.pageOffset);
          setTotalPages(data?.totalPages);
          setIsRefreshTable(true);
          setIsShowSummery(true);
          updateHandler();
          alertSuccess('Coupon updated successfully');
        } else {
          alertError('Failed!. Please try again later..');
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const deleteCouponByIdFunc = async (id) => {
    try {
      const variables = {
        input: {
          _id: id,
        },
      };

      const { success } = await deleteCouponById(variables);

      await getCouponsFunc();

      if (success) {
        alertSuccess('Coupon deleted successfully');
      } else {
        alertError('Failed!, Please try again after some time');
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getCouponsFunc = async ({
    offset = pageOffset,
    size = pageSize,
    searchKeyword = keyword,
  } = {}) => {
    try {
      setIsLoading(true);
      setKeyword(keyword);
      const variables = {
        input: {
          pageOffset: offset,
          pageSize: size,
          promotionId: promotionId,
          keyword: searchKeyword,
        },
      };

      const { data, success } = await getCoupons(variables);

      if (success) {
        setCoupons([...data?.coupons]);
        setPageOffset(data?.pageOffset);
        setTotalPages(data?.totalPages);
        setIsRefreshTable(true);
      }

      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    if (promotionId) {
      getCouponsFunc();
    }
  }, [pageOffset]);

  const updateCoupon = async (index, couponObj) => {
    try {
      let temp = [...coupons];
      temp[index] = { ...couponObj };
      setCoupons([...temp]);
    } catch (e) {
      console.log(e);
    }
  };

  const CouponSchema = Yup.object().shape({
    code: Yup.string().required('Code is required'),
    startsAt: Yup.date().required('Starts At is required'),
    expiresAt: Yup.date().required('Expires At is required'),
    redeemableQuantity: Yup.number().required('Quantity is required'),
    isCouponRequired: Yup.boolean(),
  });

  const ArrayCouponSchema = Yup.array().of(CouponSchema);

  const validationSchema = Yup.object().shape({
    codes: ArrayCouponSchema,
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: coupons,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const coupons = values
          ?.map((coupon) => {
            if (coupon?.isEdited) {
              return coupon;
            }
            return null;
          })
          ?.filter((coupon) => coupon !== null);
        if (coupons?.length > 0) {
          createOrUpdateCouponFunc(coupons, pageOffset);
        } else {
          navigate(`/promotions/detail/${promotionId}/conditions-effects`);
        }
      } catch (e) {
        console.log(e);
      }
    },
  });

  const handleCloseCreateModal = () => {
    setCreateModalOpen(false);
  };

  const { handleSubmit, isSubmitting } = formik;

  return (
    <>
      <div className=" p-0 mb-5">
        <Modal isOpen={createModalOpen} size="xl">
          <div class="modal-header">
            <h5 class="modal-title" id="myModalLabel">
              Create Coupon Codes
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setCreateModalOpen(false);
              }}
            >
              {' '}
            </button>
          </div>
          <ModalBody>
            {!promotionId?.includes('__v') && (
              <Row className="mb-2">
                {createCouponOptions?.map((option) => (
                  <div className="col-auto ">
                    <CheckboxRect
                      name="select-option"
                      value={option.value}
                      title={option.label}
                      description={option.description}
                      checked={selectedCreateOption === option.value}
                      onChange={() => {
                        setSelectedCreateOption(option.value);
                      }}
                      icon={option.icon}
                    />
                  </div>
                ))}
              </Row>
            )}

            {selectedCreateOption === 'auto_generate' &&
              !promotionId?.includes('__v') && (
                <AutoGenerateCoupons
                  coupons={coupons}
                  setCoupons={setCoupons}
                  setPageOffset={setPageOffset}
                  setTotalPages={setTotalPages}
                  setIsRefreshTable={setIsRefreshTable}
                  promotionId={promotionId}
                  promotionDetail={promotionDetail}
                  onCancel={handleCloseCreateModal}
                />
              )}

            {selectedCreateOption === 'direct_input' &&
              !promotionId?.includes('__v') && (
                <GenerateCouponDirect
                  coupons={coupons}
                  setCoupons={setCoupons}
                  setPageOffset={setPageOffset}
                  setTotalPages={setTotalPages}
                  setIsRefreshTable={setIsRefreshTable}
                  promotionId={promotionId}
                  promotionDetail={promotionDetail}
                  onCancel={handleCloseCreateModal}
                />
              )}

            {selectedCreateOption === 'import_data_from_csv' &&
              !promotionId?.includes('__v') && (
                <CreateCouponFromCsv
                  coupons={coupons}
                  setCoupons={setCoupons}
                  setIsShowSummery={setIsShowSummery}
                  setPageOffset={setPageOffset}
                  setTotalPages={setTotalPages}
                  setIsRefreshTable={setIsRefreshTable}
                  promotionId={promotionId}
                  promotionDetail={promotionDetail}
                  onCancel={handleCloseCreateModal}
                />
              )}
          </ModalBody>
        </Modal>
      </div>

      <FormikProvider value={formik}>
        <Form autoComplete="off" onSubmit={handleSubmit}>
          <Row className="mt-2">
            <Col xs={12} lg={12} className="mb-2">
              <CouponList
                onCreateClick={() => {
                  setCreateModalOpen(true);
                }}
                coupons={[...coupons]}
                updateCoupon={updateCoupon}
                //   isShowSummery={isShowSummary}
                totalPages={totalPages}
                pageOffset={pageOffset}
                pageSize={pageSize}
                setPageOffset={setPageOffset}
                isRefreshTable={isRefreshTable}
                setIsRefreshTable={setIsRefreshTable}
                createOrUpdateCouponFunc={createOrUpdateCouponFunc}
                deleteCouponByIdFunc={deleteCouponByIdFunc}
                getCouponsFunc={getCouponsFunc}
              />
            </Col>
          </Row>

          {!promotionId?.includes('__v') && (
            <div className="d-flex gap-2 justify-content-end">
              <Button outline color="danger" size="md" onClick={onCancel}>
                Cancel
              </Button>

              <Button
                loading={false}
                type="submit"
                color="primary"
                size="md"
                className="bg-gradient px-5"
                disabled={isSubmitting}
              >
                {promotionDetail ? 'Save Draft' : 'Save & Next'}
              </Button>
            </div>
          )}
        </Form>
      </FormikProvider>

      {isLoading ? <InnerLoader /> : null}
    </>
  );
};
export default CreateCouponOptions;
