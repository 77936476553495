import promoRestService from '../../services/PromoRestService';

export const createPromotionLauncher = async (data) => {
  try {
    const result = await promoRestService?.post('launcher/create', data);
    return result?.data;
  } catch (error) {
    return {
      success: false,
      message: 'Failed!. Please try again later.',
    };
  }
};

export const getPromotionLauncherDetails = async (data) => {
  try {
    const result = await promoRestService?.post('launcher/details', data);
    return result?.data;
  } catch (error) {
    return {
      success: false,
      message: 'Failed!. Please try again later.',
    };
  }
};

export const getPromotionLauncherList = async (data) => {
  try {
    const result = await promoRestService?.post('launcher/list', data);
    return result?.data;
  } catch (error) {
    return {
      success: false,
      message: 'Failed!. Please try again later.',
    };
  }
};
