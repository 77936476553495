import { PROMOTION_API_SERVICE_BASE_URL } from '../../constants';
import promoRestService from '../../services/PromoRestService';

export const viewBasket = async (data, testCaseDetails = {}) => {
  let api = `${PROMOTION_API_SERVICE_BASE_URL}basket/calculate/view`;
  if (testCaseDetails.testCase) {
    api = `${PROMOTION_API_SERVICE_BASE_URL}basket/calculate/testcase`;
  }
  const result = await promoRestService.post(api, {
    ...data,
    ...testCaseDetails,
  });
  return result;
};

export const saveBasket = async (data) => {
  let api = `${PROMOTION_API_SERVICE_BASE_URL}basket/save`;

  const result = await promoRestService.post(api, {
    ...data,
  });
  return result;
};

export const getBaskets = async (data) => {
  const result = await promoRestService.get(
    `${PROMOTION_API_SERVICE_BASE_URL}basket/list`,
    { params: data }
  );
  return result;
};
