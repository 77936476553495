import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import get from 'lodash.get';
import Flatpickr from 'react-flatpickr';

const DynamicForm = ({ fieldConfigs, formik, parentField = null }) => {
  const { values, touched, errors, setFieldValue, handleBlur, handleChange } =
    formik;

  const prioritizeEn = (locales) => {
    return ['en', ...locales.filter((locale) => locale !== 'en')];
  };

  const renderFields = ({
    fieldId,
    fieldLabel,
    dataType,
    fieldType,
    options,
  }) => {
    const value = get(values, fieldId);
    const isTouched = get(touched, fieldId);
    const hasError = get(errors, fieldId);
    const error = get(errors, fieldId);

    let inputElement;
    if (fieldType === 'switch' || dataType === 'boolean') {
      inputElement = (
        <Input
          id={fieldId}
          name={fieldId}
          type="switch"
          role="switch"
          checked={value}
          onChange={() => setFieldValue(fieldId, !value)}
          onBlur={handleBlur}
          invalid={isTouched && Boolean(hasError)}
        />
      );
    } else if (fieldType === 'number' || dataType === 'number') {
      inputElement = (
        <Input
          id={fieldId}
          name={fieldId}
          type="number"
          value={value}
          onChange={(e) => setFieldValue(fieldId, parseFloat(e?.target?.value))}
          onBlur={handleBlur}
          invalid={isTouched && Boolean(hasError)}
        />
      );
    } else if (fieldType === 'text' || dataType === 'string') {
      inputElement = (
        <Input
          id={fieldId}
          name={fieldId}
          type="text"
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          invalid={isTouched && Boolean(hasError)}
        />
      );
    } else if (fieldType === 'textarea' || dataType === 'text') {
      inputElement = (
        <Input
          id={fieldId}
          name={fieldId}
          type="textarea"
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          invalid={isTouched && Boolean(hasError)}
          rows={4}
        />
      );
    } else if (dataType === 'date') {
      inputElement = (
        <Flatpickr
          id={fieldId}
          value={value}
          onChange={(date) => setFieldValue(fieldId, date[0])}
          options={{ dateFormat: 'Y-m-d' }}
          onBlur={handleBlur}
          className="form-control"
        />
      );
    } else if (dataType === 'datetime') {
      inputElement = (
        <Flatpickr
          id={fieldId}
          value={value}
          onChange={(date) => setFieldValue(fieldId, date[0])}
          options={{
            enableTime: true,
            dateFormat: 'Y-m-d H:i',
            time_24hr: false,
          }}
          onBlur={handleBlur}
          className="form-control"
        />
      );
    } else if (fieldType === 'select') {
      inputElement = (
        <Input
          id={fieldId}
          name={fieldId}
          type="select"
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          invalid={isTouched && Boolean(hasError)}
        >
          <option value="">Select an option</option>
          {JSON.parse(options)?.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </Input>
      );
    } else if (fieldType === 'multiselectcheckbox') {
      inputElement = JSON.parse(options)?.map((option, index) => (
        <div className="form-check" key={index}>
          <Input
            id={`${fieldId}_${index}`}
            name={`${fieldId}[${index}]`}
            type="checkbox"
            value={option.value}
            checked={Array.isArray(value) && value.includes(option.value)}
            onChange={(e) => {
              const isChecked = e.target.checked;
              const updatedValues = isChecked
                ? [...(value || []), option.value]
                : (value || []).filter((val) => val !== option.value);
              setFieldValue(fieldId, updatedValues);
            }}
            onBlur={handleBlur}
            invalid={isTouched && Boolean(hasError)}
            className="form-check-input"
          />
          <Label for={`${fieldId}_${index}`} className="form-check-label">
            {option.label}
          </Label>
        </div>
      ));
    } else if (fieldType === 'multiselect') {
      inputElement = (
        <Input
          id={fieldId}
          name={fieldId}
          type="select"
          value={value}
          onChange={(e) => {
            const selectedOptions = Array?.from(
              e?.target?.selectedOptions,
              (option) => option?.value
            );
            setFieldValue(fieldId, selectedOptions);
          }}
          onBlur={handleBlur}
          multiple
          invalid={isTouched && Boolean(hasError)}
        >
          {JSON.parse(options)?.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </Input>
      );
    } else if (fieldType === 'radio') {
      inputElement = JSON.parse(options)?.map((option) => (
        <div className="form-check" key={option?.value}>
          <Input
            id={`${fieldId}_${option?.value}`}
            name={fieldId}
            type="radio"
            value={option?.value}
            checked={value === option?.value}
            onChange={(e) => setFieldValue(fieldId, e.target.value)}
            onBlur={handleBlur}
            invalid={isTouched && Boolean(hasError)}
          />
          <Label check for={`${fieldId}_${option?.value}`}>
            {option?.label}
          </Label>
        </div>
      ));
    } else {
      inputElement = (
        <Input
          id={fieldId}
          name={fieldId}
          type="text"
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          invalid={isTouched && Boolean(hasError)}
        />
      );
    }

    return (
      <FormGroup
        key={fieldId}
        switch={dataType === 'boolean' || fieldType === 'switch'}
      >
        <Label for={fieldId} className="text-capitalize">
          {fieldLabel}
        </Label>
        {inputElement}
        {isTouched && hasError && <FormFeedback>{error}</FormFeedback>}
      </FormGroup>
    );
  };

  return fieldConfigs?.map((field) => {
    const fieldLabel = field?.values?.find(
      (v) => v?.fieldCode === 'configLabel'
    )?.fieldValue;

    let fieldId = field?.values?.find(
      (v) => v?.fieldCode === 'fieldId'
    )?.fieldValue;

    let dataType = field?.values?.find(
      (v) => v?.fieldCode === 'dataType'
    )?.fieldValue;

    let fieldType =
      field?.values?.find((v) => v?.fieldCode === 'fieldType')?.fieldValue ||
      null;

    const options =
      field?.values?.find((v) => v?.fieldCode === 'optionValues')?.fieldValue ||
      [];

    let locales = JSON?.parse(
      field?.values?.find((v) => v?.fieldCode === 'locales')?.fieldValue || '[]'
    );

    locales = prioritizeEn(locales);

    const isLocaleEnabled = field?.values?.find(
      (v) => v?.fieldCode === 'isLocaleEnabled'
    )?.fieldValue;

    if (parentField) {
      fieldId = `${parentField}.${fieldId}`;
    }

    dataType = dataType?.toLowerCase();
    fieldType = fieldType?.toLowerCase();

    return isLocaleEnabled === 'true' ? (
      <div className="d-flex flex-row flex-wrap w-100">
        {(locales || ['en', 'ar'])?.map((locale) => {
          return (
            <div className="col-6 pe-2">
              {renderFields({
                fieldId: `${fieldId}.${locale}`,
                fieldLabel: `${fieldLabel} (${locale})`,
                dataType,
                fieldType,
                options,
              })}
            </div>
          );
        })}
      </div>
    ) : (
      renderFields({ fieldId, fieldLabel, dataType, fieldType, options })
    );
  });
};

DynamicForm.propTypes = {
  fieldConfigs: PropTypes.arrayOf(PropTypes.object).isRequired,
  formik: PropTypes.object.isRequired,
  parentField: PropTypes.string,
};

export default DynamicForm;
