import {
  ApolloClientPromoService,
  ApolloClientPromoUploadService,
} from '../../services/ApolloClientPromoService';
import {
  CREATE_UPDATE_BASIC_SETTINGS,
  DELETE_FIELD,
  GET_CONFIG_LIST,
  GET_CONFIG_DETAIL,
  GET_FIELD_LIST,
} from '../../gql/generals';
import {
  setMemberConfigs,
  setMemberConfigsFields,
} from '../../slices/promotions/reducer';
import {
  convertFieldToQueryBuilderFormat,
  convertMemberFieldsToQueryBuilderFormat,
} from '../../helpers/convertFieldToQueryBuilderFormat';
import { setGlobalLanguages } from '../../slices/dashboard/reducer';

export const createUpdateConfigs = async (input, configId) => {
  // must return all users and update
  try {
    const { data } = await ApolloClientPromoService.mutate({
      mutation: CREATE_UPDATE_BASIC_SETTINGS,
      variables: { input, configId },
    });

    return data.createAndUpdateBasicDetailsConfig;
  } catch (error) {
    console.error('CREATE_UPDATE_BASIC_SETTINGS', error);
    return {
      success: false,
      error: { ...error },
      data: null,
    };
  }
};

export const getAllConfigs = async (
  { configName, configEnv, pageSize, pageOffset, searchKey } = {},
  dispatch
) => {
  try {
    const { data } = await ApolloClientPromoService.query({
      query: GET_CONFIG_LIST,
      fetchPolicy: 'no-cache',
      variables: {
        input: { pageSize, pageOffset, configName, configEnv, searchKey },
      },
    });
    if (dispatch) {
      if (configName === 'languages') {
        const result = data?.getConfigList?.configs?.map((item) => {
          const label =
            item?.values?.find((v) => v?.fieldCode === 'name')?.fieldValue ||
            '';
          const value =
            item?.values?.find((v) => v?.fieldCode === 'code')?.fieldValue ||
            '';
          return { label, value };
        });
        dispatch(setGlobalLanguages(result));
      } else {
        const converted = convertMemberFieldsToQueryBuilderFormat(
          data?.getConfigList?.configs || []
        );
        dispatch(setMemberConfigs(data?.getConfigList?.configs));
        dispatch(setMemberConfigsFields(converted));
      }
    }
    return {
      success: true,
      error: false,
      data: data?.getConfigList?.configs || [],
      meta: {
        totalConfigs: data?.getConfigList?.totalConfigs || 0,
        totalPages: data?.getConfigList?.totalPages || 0,
      },
    };
  } catch (error) {
    console.error('GET_CONFIG_LIST', error);
    return {
      success: false,
      error: { ...error },
      data: [],
    };
  }
};

export const getConfigDetail = async ({ configId } = {}) => {
  try {
    const { data } = await ApolloClientPromoService.query({
      query: GET_CONFIG_DETAIL,
      fetchPolicy: 'no-cache',
      variables: { configId },
    });

    return data;
  } catch (error) {
    console.error('GET_CONFIG_LIST', error);
    return {
      success: false,
      error: { ...error },
      data: [],
    };
  }
};
export const deleteConfig = async ({ configId } = {}) => {
  try {
    const { data } = await ApolloClientPromoService.query({
      query: DELETE_FIELD,
      fetchPolicy: 'no-cache',
      variables: { configId },
    });

    return data;
  } catch (error) {
    return {
      success: false,
      error: { ...error },
      data: [],
    };
  }
};
