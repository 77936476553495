import React, { useMemo, useState } from 'react';
import { ValueEditor, update } from 'react-querybuilder';
import Collections from '../Collections';
import { CheckTreePicker } from 'rsuite';
const categoryData = [
  {
    _id: 1,
    en_label: 'Root Catalog',
    ar_label: null,
    parent_id: 0,
    enabled: null,
    countries: null,
    margin: 0,
    marginRange: null,
    level: 0,
    backToBackGroupCode: null,
    backToBackSubGroupCode: null,
    backToBackSubSubGroupCode: null,
    children: [
      {
        _id: 2,
        en_label: 'Default Category',
        ar_label: null,
        parent_id: 1,
        enabled: null,
        countries: null,
        margin: 0,
        marginRange: null,
        level: 1,
        backToBackGroupCode: null,
        backToBackSubGroupCode: null,
        backToBackSubSubGroupCode: null,
        children: [
          {
            _id: 8,
            en_label: 'Furniture',
            ar_label: 'الأثاث',
            parent_id: 2,
            enabled: null,
            countries: null,
            margin: 0,
            marginRange: null,
            level: 2,
            backToBackGroupCode: null,
            backToBackSubGroupCode: null,
            backToBackSubSubGroupCode: null,
            children: [
              {
                _id: 11,
                en_label: 'Sofas & Recliners',
                ar_label: 'الكنب ',
                parent_id: 8,
                enabled: null,
                countries: null,
                margin: 0,
                marginRange: null,
                level: 3,
                backToBackGroupCode: null,
                backToBackSubGroupCode: null,
                backToBackSubSubGroupCode: null,
                children: [
                  {
                    _id: 14,
                    en_label: 'Sofa Beds',
                    ar_label: 'أرائك تتحول لسرير',
                    parent_id: 11,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 44,
                    en_label: '3 Seater Sofa',
                    ar_label: 'كنب 3 مقاعد',
                    parent_id: 11,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 50,
                    en_label: 'Sofa Sets',
                    ar_label: 'أطقم أرائك',
                    parent_id: 11,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 65,
                    en_label: 'Corner Sofa',
                    ar_label: 'كنب زاوية',
                    parent_id: 11,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 71,
                    en_label: '1 Seater Sofa',
                    ar_label: 'كنب مقعد 1 ',
                    parent_id: 11,
                    enabled: false,
                    countries: ['uae', 'qat', 'omn', 'bah', 'kwt'],
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 74,
                    en_label: 'Recliners',
                    ar_label: 'كرسي استرخاء',
                    parent_id: 11,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 77,
                    en_label: '2 Seater Sofa',
                    ar_label: 'كنب مقعدين',
                    parent_id: 11,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 2882,
                    en_label: 'Chaise Lounge',
                    ar_label: 'تشيز لاونج',
                    parent_id: 11,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                ],
              },
              {
                _id: 17,
                en_label: 'Beds & Bedroom Sets',
                ar_label: 'أسرة وأطقم غرف نوم',
                parent_id: 8,
                enabled: null,
                countries: null,
                margin: 0,
                marginRange: null,
                level: 3,
                backToBackGroupCode: null,
                backToBackSubGroupCode: null,
                backToBackSubSubGroupCode: null,
                children: [
                  {
                    _id: 116,
                    en_label: 'Bedroom Sets',
                    ar_label: 'غرف النوم',
                    parent_id: 17,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 119,
                    en_label: 'Beds',
                    ar_label: 'أسرة',
                    parent_id: 17,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 122,
                    en_label: 'Single Beds',
                    ar_label: 'أسرَة مفردة',
                    parent_id: 17,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 125,
                    en_label: 'Suite Sets',
                    ar_label: 'أطقم أثاث للأجنحة',
                    parent_id: 17,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                ],
              },
              {
                _id: 20,
                en_label: 'Mattresses',
                ar_label: 'المراتب',
                parent_id: 8,
                enabled: null,
                countries: null,
                margin: 0,
                marginRange: null,
                level: 3,
                backToBackGroupCode: null,
                backToBackSubGroupCode: null,
                backToBackSubSubGroupCode: null,
                children: [
                  {
                    _id: 143,
                    en_label: 'Single Bed Mattresses',
                    ar_label: 'مراتب مفردة',
                    parent_id: 20,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 146,
                    en_label: 'Queen Bed Mattresses',
                    ar_label: 'مراتب متوسطة',
                    parent_id: 20,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 149,
                    en_label: 'King Bed Mattresses',
                    ar_label: 'مراتب كبيرة',
                    parent_id: 20,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 2656,
                    en_label: 'Super King Bed Mattresses',
                    ar_label: 'Super King Bed Mattresses',
                    parent_id: 20,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                ],
              },
              {
                _id: 23,
                en_label: 'Kids Furniture',
                ar_label: 'أثات الأطفال',
                parent_id: 8,
                enabled: null,
                countries: null,
                margin: 0,
                marginRange: null,
                level: 3,
                backToBackGroupCode: null,
                backToBackSubGroupCode: null,
                backToBackSubSubGroupCode: null,
                children: [
                  {
                    _id: 179,
                    en_label: 'Kids Bed',
                    ar_label: 'سرير الأطفال',
                    parent_id: 23,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 182,
                    en_label: 'Kids Night Stand',
                    ar_label: 'طاولة جانبية للأطفال',
                    parent_id: 23,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 185,
                    en_label: 'Kids Accessories',
                    ar_label: 'أكسسوارات الأطفال',
                    parent_id: 23,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 797,
                    en_label: 'Kids Desk',
                    ar_label: 'طاولة الأطفال',
                    parent_id: 23,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 1997,
                    en_label: 'Kids Chest of Drawers',
                    ar_label: null,
                    parent_id: 23,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 2000,
                    en_label: 'Kids Wardrobe',
                    ar_label: 'خزائن ملابس الأطفال',
                    parent_id: 23,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 2003,
                    en_label: 'Kids Chair',
                    ar_label: 'Kids Chair',
                    parent_id: 23,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                ],
              },
              {
                _id: 26,
                en_label: 'Living Room',
                ar_label: 'غرفة المعيشة',
                parent_id: 8,
                enabled: null,
                countries: null,
                margin: 0,
                marginRange: null,
                level: 3,
                backToBackGroupCode: null,
                backToBackSubGroupCode: null,
                backToBackSubSubGroupCode: null,
                children: [
                  {
                    _id: 53,
                    en_label: 'Coffee Tables',
                    ar_label: 'طاولات القهوة',
                    parent_id: 26,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 68,
                    en_label: 'End Tables',
                    ar_label: 'طاولات جانبية',
                    parent_id: 26,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 98,
                    en_label: 'Console Tables',
                    ar_label: 'طاولات كونسول',
                    parent_id: 26,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 101,
                    en_label: 'TV & Media Units',
                    ar_label: 'وحدات التلفزيون',
                    parent_id: 26,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 104,
                    en_label: 'Book Cases & Cabinets',
                    ar_label: 'أرفف ومكاتب ',
                    parent_id: 26,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 107,
                    en_label: 'Shoe Cabinets & Racks',
                    ar_label: 'أرفف وخزانات أحذية',
                    parent_id: 26,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 110,
                    en_label: 'Wall Shelves',
                    ar_label: 'رفوف الجدران',
                    parent_id: 26,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 113,
                    en_label: 'Coat Stands',
                    ar_label: 'شماعات معطف ',
                    parent_id: 26,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 806,
                    en_label: 'Organisers',
                    ar_label: 'مقسمات التخزين',
                    parent_id: 26,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                ],
              },
              {
                _id: 29,
                en_label: 'Wardrobes',
                ar_label: 'خزانات الملابس',
                parent_id: 8,
                enabled: null,
                countries: null,
                margin: 0,
                marginRange: null,
                level: 3,
                backToBackGroupCode: null,
                backToBackSubGroupCode: null,
                backToBackSubSubGroupCode: null,
                children: [
                  {
                    _id: 128,
                    en_label: 'Swing Door Wardrobes',
                    ar_label: 'خزائن ملابس بأبواب متأرجحة',
                    parent_id: 29,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 131,
                    en_label: 'Sliding Door Wardrobes',
                    ar_label: 'خزائن ملابس بأبواب إنزلاقية',
                    parent_id: 29,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                ],
              },
              {
                _id: 32,
                en_label: 'Dining Room',
                ar_label: 'غرفة الطعام',
                parent_id: 8,
                enabled: null,
                countries: null,
                margin: 0,
                marginRange: null,
                level: 3,
                backToBackGroupCode: null,
                backToBackSubGroupCode: null,
                backToBackSubSubGroupCode: null,
                children: [
                  {
                    _id: 155,
                    en_label: 'Dining Set',
                    ar_label: 'أطقم سفرة',
                    parent_id: 32,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 158,
                    en_label: 'Dining Chairs',
                    ar_label: 'كراسي طعام',
                    parent_id: 32,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 161,
                    en_label: 'Serving Trolleys',
                    ar_label: 'عربات تقديم',
                    parent_id: 32,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 164,
                    en_label: 'Cabinets & Sideboards',
                    ar_label: 'خزانات',
                    parent_id: 32,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 794,
                    en_label: 'Dining Tables',
                    ar_label: 'طاولات طعام',
                    parent_id: 32,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                ],
              },
              {
                _id: 35,
                en_label: 'Bedroom Storage',
                ar_label: 'خزانات غرفة النوم',
                parent_id: 8,
                enabled: null,
                countries: null,
                margin: 0,
                marginRange: null,
                level: 3,
                backToBackGroupCode: null,
                backToBackSubGroupCode: null,
                backToBackSubSubGroupCode: null,
                children: [
                  {
                    _id: 134,
                    en_label: 'Dressers & Mirrors',
                    ar_label: 'المرايا والتسريحة',
                    parent_id: 35,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 137,
                    en_label: 'Bedside Tables',
                    ar_label: 'طاولات جانبية',
                    parent_id: 35,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 140,
                    en_label: 'Chest of Drawers',
                    ar_label: 'خزانات ذات أدراج',
                    parent_id: 35,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                ],
              },
              {
                _id: 38,
                en_label: 'Seating',
                ar_label: 'مقاعد',
                parent_id: 8,
                enabled: null,
                countries: null,
                margin: 0,
                marginRange: null,
                level: 3,
                backToBackGroupCode: null,
                backToBackSubGroupCode: null,
                backToBackSubSubGroupCode: null,
                children: [
                  {
                    _id: 167,
                    en_label: 'Bean Bags',
                    ar_label: 'مقاعد محشو',
                    parent_id: 38,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 170,
                    en_label: 'Ottomans',
                    ar_label: 'كرسي تخزين',
                    parent_id: 38,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 173,
                    en_label: 'Chairs',
                    ar_label: 'كراسي',
                    parent_id: 38,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 176,
                    en_label: 'Massage Chairs',
                    ar_label: 'كراسي مساج',
                    parent_id: 38,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 2682,
                    en_label: 'Stool',
                    ar_label: null,
                    parent_id: 38,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 3052,
                    en_label: 'Bar Stools',
                    ar_label: 'كراسي بار',
                    parent_id: 38,
                    enabled: true,
                    countries: ['uae', 'qat', 'omn', 'bah'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                ],
              },
              {
                _id: 41,
                en_label: 'Office Furniture',
                ar_label: 'أثاث المكتب',
                parent_id: 8,
                enabled: null,
                countries: null,
                margin: 0,
                marginRange: null,
                level: 3,
                backToBackGroupCode: null,
                backToBackSubGroupCode: null,
                backToBackSubSubGroupCode: null,
                children: [
                  {
                    _id: 188,
                    en_label: 'Office Chairs',
                    ar_label: 'كراسي مكتب',
                    parent_id: 41,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 191,
                    en_label: 'Office Desks',
                    ar_label: 'طاولات مكتب',
                    parent_id: 41,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 3053,
                    en_label: 'Storage Cabinets',
                    ar_label: 'خزائن التخزين',
                    parent_id: 41,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '0',
                  },
                  {
                    _id: 3054,
                    en_label: 'Office Sofa',
                    ar_label: 'أريكة المكتب',
                    parent_id: 41,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '0',
                  },
                ],
              },
            ],
          },
          {
            _id: 47,
            en_label: 'Outdoor',
            ar_label: 'الأثاث الخارجي',
            parent_id: 2,
            enabled: false,
            countries: ['uae', 'qat', 'bah', 'omn', 'kwt'],
            margin: 0,
            marginRange: null,
            level: 2,
            backToBackGroupCode: null,
            backToBackSubGroupCode: null,
            backToBackSubSubGroupCode: null,
            children: [
              {
                _id: 209,
                en_label: 'Outdoor Living',
                ar_label: 'الأثاث الخارجي',
                parent_id: 47,
                enabled: null,
                countries: null,
                margin: 0,
                marginRange: null,
                level: 3,
                backToBackGroupCode: null,
                backToBackSubGroupCode: null,
                backToBackSubSubGroupCode: null,
                children: [
                  {
                    _id: 224,
                    en_label: 'Gazebo',
                    ar_label: 'جازيبو',
                    parent_id: 209,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 227,
                    en_label: 'Outdoor Sofa Sets',
                    ar_label: 'طقم أريكة خارجية',
                    parent_id: 209,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: 'TEST',
                  },
                  {
                    _id: 230,
                    en_label: 'Outdoor Dining Sets',
                    ar_label: 'طقم سفرة خارجية',
                    parent_id: 209,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 233,
                    en_label: 'Balcony Sets',
                    ar_label: 'طقم أثاث بلكونات',
                    parent_id: 209,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 236,
                    en_label: 'Swings',
                    ar_label: 'أرجوحة',
                    parent_id: 209,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 239,
                    en_label: 'Outdoor Bench & Chair',
                    ar_label: 'كرسي ومقعد خارجي',
                    parent_id: 209,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 242,
                    en_label: 'Sun Loungers',
                    ar_label: 'كراسي استرخاء خارجية',
                    parent_id: 209,
                    enabled: false,
                    countries: ['uae', 'bah', 'ksa', 'omn', 'qat', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '0',
                  },
                  {
                    _id: 245,
                    en_label: 'Outdoor Umbrella',
                    ar_label: 'مظلة خارجية',
                    parent_id: 209,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                ],
              },
              {
                _id: 212,
                en_label: 'Garden Accessories',
                ar_label: 'أكسسوارات الحديقة',
                parent_id: 47,
                enabled: null,
                countries: null,
                margin: 0,
                marginRange: null,
                level: 3,
                backToBackGroupCode: null,
                backToBackSubGroupCode: null,
                backToBackSubSubGroupCode: null,
                children: [
                  {
                    _id: 248,
                    en_label: 'BBQ Grill & Camping',
                    ar_label: 'أدوات الشواء والتخييم',
                    parent_id: 212,
                    enabled: false,
                    countries: ['uae', 'qat', 'bah', 'omn'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '0',
                  },
                  {
                    _id: 251,
                    en_label: 'Outdoor Fountains',
                    ar_label: 'نوافير',
                    parent_id: 212,
                    enabled: false,
                    countries: ['uae', 'bah', 'qat', 'ksa', 'omn', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '0',
                  },
                  {
                    _id: 254,
                    en_label: 'Garden Ornaments',
                    ar_label: 'ديكورات الحديقة',
                    parent_id: 212,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 257,
                    en_label: 'Artificial Plants & Fence',
                    ar_label: 'النباتات الصناعية والأسيجة',
                    parent_id: 212,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 260,
                    en_label: 'Outdoor Cushions',
                    ar_label: 'وسائد خارجية',
                    parent_id: 212,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 263,
                    en_label: 'Pots',
                    ar_label: 'أصيص',
                    parent_id: 212,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 266,
                    en_label: 'Garden Utilities',
                    ar_label: 'مستلزمات الحدائق',
                    parent_id: 212,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                ],
              },
              {
                _id: 215,
                en_label: 'Kids Play',
                ar_label: 'ألعاب الأطفال',
                parent_id: 47,
                enabled: null,
                countries: null,
                margin: 0,
                marginRange: null,
                level: 3,
                backToBackGroupCode: null,
                backToBackSubGroupCode: null,
                backToBackSubSubGroupCode: null,
                children: [
                  {
                    _id: 269,
                    en_label: 'Outdoor Kids Play',
                    ar_label: 'ألعاب خارجية للأطفال',
                    parent_id: 215,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 272,
                    en_label: 'Indoor Kids Play',
                    ar_label: 'ألعاب داخلية للأطفال',
                    parent_id: 215,
                    enabled: true,
                    countries: ['uae'],
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 275,
                    en_label: 'Swimming Pools',
                    ar_label: 'مسابح',
                    parent_id: 215,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 278,
                    en_label: 'Beach & Water Accessories',
                    ar_label: 'أكسسوارات و ألعاب مائية',
                    parent_id: 215,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                ],
              },
              {
                _id: 218,
                en_label: 'Garden Lights & Lantern',
                ar_label: 'نافورة الحديقة والإضاءة',
                parent_id: 47,
                enabled: null,
                countries: null,
                margin: 0,
                marginRange: null,
                level: 3,
                backToBackGroupCode: null,
                backToBackSubGroupCode: null,
                backToBackSubSubGroupCode: null,
                children: null,
              },
              {
                _id: 221,
                en_label: 'Artificial Grass Carpet',
                ar_label: 'سجاد عشب صناعي',
                parent_id: 47,
                enabled: false,
                countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                margin: 0,
                marginRange: [],
                level: 3,
                backToBackGroupCode: '',
                backToBackSubGroupCode: '0',
                backToBackSubSubGroupCode: '',
                children: null,
              },
              {
                _id: 2831,
                en_label: 'Live Plants',
                ar_label: 'نباتات طبيعية',
                parent_id: 47,
                enabled: true,
                countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                margin: 0,
                marginRange: null,
                level: 3,
                backToBackGroupCode: null,
                backToBackSubGroupCode: null,
                backToBackSubSubGroupCode: null,
                children: [
                  {
                    _id: 2832,
                    en_label: 'Terrarium',
                    ar_label: 'Terrarium',
                    parent_id: 2831,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                ],
              },
              {
                _id: 2834,
                en_label: 'Pools & Water Fun',
                ar_label: 'أحواض سباحة وألعاب مائية',
                parent_id: 47,
                enabled: true,
                countries: ['uae', 'qat', 'bah', 'omn', 'kwt'],
                margin: 0,
                marginRange: [],
                level: 3,
                backToBackGroupCode: '',
                backToBackSubGroupCode: '0',
                backToBackSubSubGroupCode: '',
                children: [
                  {
                    _id: 2835,
                    en_label: 'Pool & Swimming Accessories',
                    ar_label: 'أحواض السباحة وملحقاتها ',
                    parent_id: 2834,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '0',
                  },
                  {
                    _id: 2836,
                    en_label: 'Inflatables Pools',
                    ar_label: 'أحواض سباحة قابلة للنفخ',
                    parent_id: 2834,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '0',
                  },
                  {
                    _id: 2837,
                    en_label: 'Above Ground Pools',
                    ar_label: 'أحواض سباحة أرضية',
                    parent_id: 2834,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '0',
                  },
                  {
                    _id: 2838,
                    en_label: 'Outdoor Spa & Jacuzzi',
                    ar_label: 'Outdoor Spa & Jacuzzi',
                    parent_id: 2834,
                    enabled: true,
                    countries: ['uae', 'bah', 'omn', 'qat', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2839,
                    en_label: 'Summer Toys & Water Fun',
                    ar_label: 'Summer Toys & Water Fun',
                    parent_id: 2834,
                    enabled: true,
                    countries: ['uae', 'omn', 'bah', 'kwt', 'qat'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2840,
                    en_label: 'Pool Floats & Swimming Rings',
                    ar_label: 'عوّامات',
                    parent_id: 2834,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '0',
                  },
                  {
                    _id: 2841,
                    en_label: 'Water Park',
                    ar_label: 'حديقة ألعاب مائية',
                    parent_id: 2834,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '0',
                  },
                ],
              },
            ],
          },
          {
            _id: 56,
            en_label: 'Furnishings',
            ar_label: 'مفروشات',
            parent_id: 2,
            enabled: null,
            countries: null,
            margin: 0,
            marginRange: null,
            level: 2,
            backToBackGroupCode: null,
            backToBackSubGroupCode: null,
            backToBackSubSubGroupCode: null,
            children: [
              {
                _id: 407,
                en_label: 'Comforters & Bedsheets',
                ar_label: 'ألحفة ومفارش سرير ',
                parent_id: 56,
                enabled: null,
                countries: null,
                margin: 0,
                marginRange: null,
                level: 3,
                backToBackGroupCode: null,
                backToBackSubGroupCode: null,
                backToBackSubSubGroupCode: null,
                children: [
                  {
                    _id: 434,
                    en_label: 'Bedsheets',
                    ar_label: 'ملاءات',
                    parent_id: 407,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 437,
                    en_label: 'Comforter Sets',
                    ar_label: 'أطقم ألحفة',
                    parent_id: 407,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 440,
                    en_label: 'Quilts & Blankets',
                    ar_label: 'أغطية وبطانيات ',
                    parent_id: 407,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                ],
              },
              {
                _id: 410,
                en_label: 'Bed Essentials',
                ar_label: 'ألحفة ووسائد',
                parent_id: 56,
                enabled: false,
                countries: ['uae'],
                margin: 0,
                marginRange: null,
                level: 3,
                backToBackGroupCode: null,
                backToBackSubGroupCode: null,
                backToBackSubSubGroupCode: null,
                children: [
                  {
                    _id: 152,
                    en_label: 'Toppers & Protector',
                    ar_label: 'الأغطية',
                    parent_id: 410,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 443,
                    en_label: 'Pillows',
                    ar_label: 'وسائد',
                    parent_id: 410,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 446,
                    en_label: 'Duvets',
                    ar_label: 'الحفة',
                    parent_id: 410,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                ],
              },
              {
                _id: 413,
                en_label: 'Table Linen',
                ar_label: 'مفروشات طاولات السفرة',
                parent_id: 56,
                enabled: null,
                countries: null,
                margin: 0,
                marginRange: null,
                level: 3,
                backToBackGroupCode: null,
                backToBackSubGroupCode: null,
                backToBackSubSubGroupCode: null,
                children: null,
              },
              {
                _id: 416,
                en_label: 'Curtain',
                ar_label: 'ستائر',
                parent_id: 56,
                enabled: null,
                countries: null,
                margin: 0,
                marginRange: null,
                level: 3,
                backToBackGroupCode: null,
                backToBackSubGroupCode: null,
                backToBackSubSubGroupCode: null,
                children: [
                  {
                    _id: 449,
                    en_label: 'Curtains',
                    ar_label: 'ستائر',
                    parent_id: 416,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                ],
              },
              {
                _id: 419,
                en_label: 'Throws & Cushions',
                ar_label: 'وسائد وبطانيات خفيفة',
                parent_id: 56,
                enabled: null,
                countries: null,
                margin: 0,
                marginRange: null,
                level: 3,
                backToBackGroupCode: null,
                backToBackSubGroupCode: null,
                backToBackSubSubGroupCode: null,
                children: [
                  {
                    _id: 452,
                    en_label: 'Filled Cushions',
                    ar_label: 'وسائد محشوة',
                    parent_id: 419,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 455,
                    en_label: 'Chairpad',
                    ar_label: 'لبادات كراسي',
                    parent_id: 419,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 2635,
                    en_label: 'Throws',
                    ar_label: 'بطانيات خفيفة',
                    parent_id: 419,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 2833,
                    en_label: 'Cushion Covers',
                    ar_label: 'ويغطي وسادة',
                    parent_id: 419,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                ],
              },
              {
                _id: 422,
                en_label: 'Towel',
                ar_label: 'مناشف',
                parent_id: 56,
                enabled: null,
                countries: null,
                margin: 0,
                marginRange: null,
                level: 3,
                backToBackGroupCode: null,
                backToBackSubGroupCode: null,
                backToBackSubSubGroupCode: null,
                children: [
                  {
                    _id: 458,
                    en_label: 'Towels',
                    ar_label: 'مناشف',
                    parent_id: 422,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 461,
                    en_label: 'Bathrobes',
                    ar_label: 'أرواب حمام',
                    parent_id: 422,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 464,
                    en_label: 'Bathmats',
                    ar_label: 'سجاد حمام',
                    parent_id: 422,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                ],
              },
              {
                _id: 425,
                en_label: 'Floor Covering',
                ar_label: 'أغطية للأرضية',
                parent_id: 56,
                enabled: null,
                countries: null,
                margin: 0,
                marginRange: null,
                level: 3,
                backToBackGroupCode: null,
                backToBackSubGroupCode: null,
                backToBackSubSubGroupCode: null,
                children: [
                  {
                    _id: 467,
                    en_label: 'Carpets & Rugs',
                    ar_label: 'سجاد وأغطية للأرضية',
                    parent_id: 425,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 3050,
                    en_label: 'Prayer Mats',
                    ar_label: 'سجاد الصلاة',
                    parent_id: 425,
                    enabled: true,
                    countries: ['uae', 'qat', 'omn', 'bah'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                ],
              },
              {
                _id: 428,
                en_label: 'Mattresses',
                ar_label: 'مراتب',
                parent_id: 56,
                enabled: null,
                countries: null,
                margin: 0,
                marginRange: null,
                level: 3,
                backToBackGroupCode: null,
                backToBackSubGroupCode: null,
                backToBackSubSubGroupCode: null,
                children: [
                  {
                    _id: 470,
                    en_label: 'Single Bed Mattresses',
                    ar_label: 'مراتب مفردة',
                    parent_id: 428,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 473,
                    en_label: 'Queen Bed Mattresses',
                    ar_label: 'مراتب متوسطة',
                    parent_id: 428,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 476,
                    en_label: 'King Bed Mattresses',
                    ar_label: 'مراتب كبيرة',
                    parent_id: 428,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 479,
                    en_label: 'Toppers & Protector',
                    ar_label: 'واقيات ولبادات مراتب',
                    parent_id: 428,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                ],
              },
              {
                _id: 2674,
                en_label: 'Cushions',
                ar_label: null,
                parent_id: 56,
                enabled: null,
                countries: null,
                margin: 0,
                marginRange: null,
                level: 3,
                backToBackGroupCode: null,
                backToBackSubGroupCode: null,
                backToBackSubSubGroupCode: null,
                children: null,
              },
            ],
          },
          {
            _id: 59,
            en_label: 'Tools',
            ar_label: 'المعدات والكهربائيات',
            parent_id: 2,
            enabled: null,
            countries: null,
            margin: 0,
            marginRange: null,
            level: 2,
            backToBackGroupCode: null,
            backToBackSubGroupCode: null,
            backToBackSubSubGroupCode: null,
            children: [
              {
                _id: 62,
                en_label: 'Silicon Adhesives & Sealants',
                ar_label: 'سيليكون لاصقات ومواد مانعة للتسرب',
                parent_id: 59,
                enabled: false,
                countries: ['uae', 'qat', 'bah', 'ksa', 'omn', 'kwt'],
                margin: 0,
                marginRange: [],
                level: 3,
                backToBackGroupCode: '',
                backToBackSubGroupCode: '1',
                backToBackSubSubGroupCode: '',
                children: [
                  {
                    _id: 566,
                    en_label: 'Adhesives',
                    ar_label: 'لاصقات',
                    parent_id: 62,
                    enabled: false,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '1',
                  },
                  {
                    _id: 569,
                    en_label: 'Tapes & Packing Solutions',
                    ar_label: 'أشرطة وحلول التعبئة والتغليف',
                    parent_id: 62,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '1',
                  },
                  {
                    _id: 572,
                    en_label: 'PU Foam',
                    ar_label: 'رغوة البولي يوريثان',
                    parent_id: 62,
                    enabled: true,
                    countries: ['uae', 'bah', 'qat', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '1',
                  },
                  {
                    _id: 986,
                    en_label: 'Other Adhesives',
                    ar_label: 'مواد لاصقة أخرى',
                    parent_id: 62,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 989,
                    en_label: 'Silicon',
                    ar_label: 'مواد سيليكون',
                    parent_id: 62,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                ],
              },
              {
                _id: 545,
                en_label: 'Safety Point',
                ar_label: 'نقطة السلامة',
                parent_id: 59,
                enabled: true,
                countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                margin: 0,
                marginRange: [],
                level: 3,
                backToBackGroupCode: '',
                backToBackSubGroupCode: '1',
                backToBackSubSubGroupCode: '',
                children: [
                  {
                    _id: 575,
                    en_label: 'Wheel Barrow',
                    ar_label: 'عربات يد',
                    parent_id: 545,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 578,
                    en_label: 'Paints & Accessories',
                    ar_label: 'دهانات واكسسوارات',
                    parent_id: 545,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 581,
                    en_label: 'Coveralls & Safety Vests',
                    ar_label: 'بدلات وسترات السلامة',
                    parent_id: 545,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '1',
                  },
                  {
                    _id: 2976,
                    en_label: 'Helmets',
                    ar_label: 'خوذات',
                    parent_id: 545,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2977,
                    en_label: 'Gloves',
                    ar_label: 'قفازات',
                    parent_id: 545,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2978,
                    en_label: 'Glasses & Earplugs',
                    ar_label: 'نظارات وسدادات الأذن',
                    parent_id: 545,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2979,
                    en_label: 'Shoes',
                    ar_label: 'أحذية',
                    parent_id: 545,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2980,
                    en_label: 'Cones Warning Tapes & Accessories',
                    ar_label: 'مخروطات أشرطة تحذير وملحقات',
                    parent_id: 545,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                ],
              },
              {
                _id: 548,
                en_label: 'Doors Handles & Hardware',
                ar_label: 'الأبواب، المقابض، والأجهزة',
                parent_id: 59,
                enabled: true,
                countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                margin: 0,
                marginRange: [],
                level: 3,
                backToBackGroupCode: '',
                backToBackSubGroupCode: '1',
                backToBackSubSubGroupCode: '',
                children: [
                  {
                    _id: 584,
                    en_label: 'Door Accessories & Fittings',
                    ar_label: 'أكسسوارات وتجهيزات الباب',
                    parent_id: 548,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 587,
                    en_label: 'Door Channel & Kit',
                    ar_label: 'أطقم وقنوات الباب',
                    parent_id: 548,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 590,
                    en_label: 'Hinges & Ironmongery',
                    ar_label: 'مفصلات ومعدات الحدادة',
                    parent_id: 548,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '1',
                  },
                  {
                    _id: 593,
                    en_label: 'Locks & Cylinders',
                    ar_label: 'أقفال',
                    parent_id: 548,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 596,
                    en_label: 'Cabinet Handles',
                    ar_label: 'مقابض الخزائن',
                    parent_id: 548,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '1',
                  },
                  {
                    _id: 599,
                    en_label: 'Brass Handle',
                    ar_label: 'مقابض نحاس',
                    parent_id: 548,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 602,
                    en_label: 'Door Handle',
                    ar_label: 'مقابض أبواب',
                    parent_id: 548,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 605,
                    en_label: 'Pull Handles',
                    ar_label: 'مقابض قابلة للسحب',
                    parent_id: 548,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 992,
                    en_label: 'Entrance Lock',
                    ar_label: 'أقفال خزائن',
                    parent_id: 548,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 2939,
                    en_label: 'Smart Locks',
                    ar_label: 'أقفال ذكية',
                    parent_id: 548,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2940,
                    en_label: 'Combos',
                    ar_label: 'مجموعات',
                    parent_id: 548,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2941,
                    en_label: 'Doors',
                    ar_label: 'أبواب',
                    parent_id: 548,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3013,
                    en_label: 'Door Handles',
                    ar_label: 'مقابض الأبواب',
                    parent_id: 548,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                ],
              },
              {
                _id: 551,
                en_label: 'House Hold Essentials',
                ar_label: 'ضروريات المنزل',
                parent_id: 59,
                enabled: false,
                countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                margin: 0,
                marginRange: [],
                level: 3,
                backToBackGroupCode: '',
                backToBackSubGroupCode: '1',
                backToBackSubSubGroupCode: '',
                children: [
                  {
                    _id: 608,
                    en_label: 'Locks',
                    ar_label: 'أقفال',
                    parent_id: 551,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 611,
                    en_label: 'Home Accessories',
                    ar_label: 'اكسسوارات المنزل',
                    parent_id: 551,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 614,
                    en_label: '3M Product',
                    ar_label: 'مواد 3م',
                    parent_id: 551,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 1001,
                    en_label: 'Shelves',
                    ar_label: 'رفوف',
                    parent_id: 551,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 1004,
                    en_label: 'Screw-Nails-Rivet',
                    ar_label: 'أدوات ربط مسامير',
                    parent_id: 551,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 1007,
                    en_label: 'Cleaning',
                    ar_label: 'أدوات تنظيف',
                    parent_id: 551,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 1010,
                    en_label: 'Wardrobe System',
                    ar_label: 'بكرات للخزانة',
                    parent_id: 551,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 2984,
                    en_label: 'Waste Bins',
                    ar_label: 'سلات القمامة',
                    parent_id: 551,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2985,
                    en_label: 'Humidifiers & Air Purifiers',
                    ar_label: 'مرطبات الهواء ومنقيات الهواء',
                    parent_id: 551,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2986,
                    en_label: 'Storage Solutions',
                    ar_label: 'حلول التخزين',
                    parent_id: 551,
                    enabled: true,
                    countries: ['qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2987,
                    en_label: 'Ladders',
                    ar_label: 'سلالم',
                    parent_id: 551,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2988,
                    en_label: 'Trolleys & Hand Trucks',
                    ar_label: 'عربات وعربات يدوية',
                    parent_id: 551,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2989,
                    en_label: 'Household Accessories',
                    ar_label: 'إكسسوارات المنزل',
                    parent_id: 551,
                    enabled: true,
                    countries: ['qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2990,
                    en_label: 'Batteries',
                    ar_label: 'بطاريات',
                    parent_id: 551,
                    enabled: true,
                    countries: ['qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2991,
                    en_label: 'Car Accessories',
                    ar_label: 'إكسسوارات السيارة',
                    parent_id: 551,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                ],
              },
              {
                _id: 554,
                en_label: 'Hand Tools',
                ar_label: 'أدوات',
                parent_id: 59,
                enabled: true,
                countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                margin: 0,
                marginRange: [],
                level: 3,
                backToBackGroupCode: '',
                backToBackSubGroupCode: '1',
                backToBackSubSubGroupCode: '',
                children: [
                  {
                    _id: 617,
                    en_label: 'DIY Tools',
                    ar_label: 'أدوات القيام بذلك بنفسك',
                    parent_id: 554,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '0',
                  },
                  {
                    _id: 2942,
                    en_label: 'Hand Tool Sets',
                    ar_label: 'مجموعات أدوات يدوية',
                    parent_id: 554,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2943,
                    en_label: 'Measurement Tools',
                    ar_label: 'أدوات القياس',
                    parent_id: 554,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2944,
                    en_label: 'Knives & Cutters',
                    ar_label: 'سكاكين وأدوات القطع',
                    parent_id: 554,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2952,
                    en_label: 'Screw & Fasteners',
                    ar_label: 'مسامير ومثبتات',
                    parent_id: 554,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3012,
                    en_label: 'Interior & Finishing Tools',
                    ar_label: 'أدوات التصميم الداخلي والتشطيب',
                    parent_id: 554,
                    enabled: true,
                    countries: ['qat', 'uae', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                ],
              },
              {
                _id: 557,
                en_label: 'Safe',
                ar_label: 'خزائن',
                parent_id: 59,
                enabled: null,
                countries: null,
                margin: 0,
                marginRange: null,
                level: 3,
                backToBackGroupCode: null,
                backToBackSubGroupCode: null,
                backToBackSubSubGroupCode: null,
                children: [
                  {
                    _id: 623,
                    en_label: 'Safe and Security',
                    ar_label: 'الأمان والأمان',
                    parent_id: 557,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '0',
                  },
                ],
              },
              {
                _id: 560,
                en_label: 'Electrical',
                ar_label: 'كهربائيات',
                parent_id: 59,
                enabled: null,
                countries: null,
                margin: 0,
                marginRange: null,
                level: 3,
                backToBackGroupCode: null,
                backToBackSubGroupCode: null,
                backToBackSubSubGroupCode: null,
                children: [
                  {
                    _id: 626,
                    en_label: 'Fans & Coolers',
                    ar_label: 'مراوح ومبردات هواء',
                    parent_id: 560,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 629,
                    en_label: 'Switches & Sockets',
                    ar_label: 'مأخذ التوصيل',
                    parent_id: 560,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 632,
                    en_label: 'Lightings',
                    ar_label: 'إضاءة',
                    parent_id: 560,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 995,
                    en_label: 'Security System',
                    ar_label: 'أنظمة أمان',
                    parent_id: 560,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 998,
                    en_label: 'Accessories-BC',
                    ar_label: 'اكسسوارات',
                    parent_id: 560,
                    enabled: false,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '0',
                  },
                ],
              },
              {
                _id: 620,
                en_label: 'Power Tools',
                ar_label: 'أدوات كهربائية',
                parent_id: 59,
                enabled: false,
                countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                margin: 0,
                marginRange: [],
                level: 3,
                backToBackGroupCode: '',
                backToBackSubGroupCode: '',
                backToBackSubSubGroupCode: '1',
                children: [
                  {
                    _id: 2968,
                    en_label: 'Pressure Washer',
                    ar_label: 'غسالة ضغط',
                    parent_id: 620,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2969,
                    en_label: 'Vacuum Cleaners',
                    ar_label: 'مكانس كهربائية',
                    parent_id: 620,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2970,
                    en_label: 'Blowers',
                    ar_label: 'منفاخات',
                    parent_id: 620,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2971,
                    en_label: 'Cutters Grinders & Sanders',
                    ar_label: 'آلات القطع والطحن والصقل',
                    parent_id: 620,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2972,
                    en_label: 'Drills & Wrenches',
                    ar_label: 'مثاقب ومفاتيح',
                    parent_id: 620,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2973,
                    en_label: 'Heat Glue & Staple Guns',
                    ar_label: 'بنادق لاصق ساخن وبنادق التعشيق',
                    parent_id: 620,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2974,
                    en_label: 'Garden Tools',
                    ar_label: 'أدوات الحدائق',
                    parent_id: 620,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2975,
                    en_label: 'Power Tool Combos',
                    ar_label: 'مجموعات أدوات كهربائية',
                    parent_id: 620,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3036,
                    en_label: 'Power Tools Accessories',
                    ar_label: 'ملحقات أدوات الكهرباء',
                    parent_id: 620,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                ],
              },
              {
                _id: 3010,
                en_label: 'Hardware Tools',
                ar_label: 'أدوات الأجهزة',
                parent_id: 59,
                enabled: true,
                countries: ['qat', 'uae', 'bah', 'omn', 'ksa', 'kwt'],
                margin: 0,
                marginRange: [],
                level: 3,
                backToBackGroupCode: '',
                backToBackSubGroupCode: '',
                backToBackSubSubGroupCode: '',
                children: [
                  {
                    _id: 3011,
                    en_label: 'Toolbox',
                    ar_label: 'صندوق الأدوات',
                    parent_id: 3010,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                ],
              },
            ],
          },
          {
            _id: 80,
            en_label: 'Decor',
            ar_label: 'ديكور منزلي',
            parent_id: 2,
            enabled: null,
            countries: null,
            margin: 0,
            marginRange: null,
            level: 2,
            backToBackGroupCode: null,
            backToBackSubGroupCode: null,
            backToBackSubSubGroupCode: null,
            children: [
              {
                _id: 281,
                en_label: 'Home Accessories-BC',
                ar_label: 'أكسسوارات منزلية',
                parent_id: 80,
                enabled: false,
                countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                margin: 0,
                marginRange: [],
                level: 3,
                backToBackGroupCode: '',
                backToBackSubGroupCode: '0',
                backToBackSubSubGroupCode: '',
                children: [
                  {
                    _id: 293,
                    en_label: 'Figurines',
                    ar_label: 'تماثيل',
                    parent_id: 281,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 296,
                    en_label: 'Candle Holders',
                    ar_label: 'حاملات شموع',
                    parent_id: 281,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 299,
                    en_label: 'Vases',
                    ar_label: 'مزهريات',
                    parent_id: 281,
                    enabled: false,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '1',
                  },
                  {
                    _id: 302,
                    en_label: 'Bowls',
                    ar_label: 'الطاسات',
                    parent_id: 281,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 305,
                    en_label: 'Bowls & Trays',
                    ar_label: 'صواني وصحون',
                    parent_id: 281,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 308,
                    en_label: 'Trinkets & Jewelry Boxes',
                    ar_label: 'صناديق المجوهرات والبرطمنات',
                    parent_id: 281,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 311,
                    en_label: 'Tissue Holders',
                    ar_label: 'حاملات مناديل',
                    parent_id: 281,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 314,
                    en_label: 'Globes',
                    ar_label: 'كرة أرضية',
                    parent_id: 281,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 317,
                    en_label: 'Fountains',
                    ar_label: 'نوافير',
                    parent_id: 281,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 320,
                    en_label: 'Flower Decorations',
                    ar_label: 'زهور ديكور',
                    parent_id: 281,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 323,
                    en_label: 'Bathroom Accessories',
                    ar_label: 'أكسسوارات الحمام',
                    parent_id: 281,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 326,
                    en_label: 'Laundry',
                    ar_label: 'الغسيل',
                    parent_id: 281,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 911,
                    en_label: 'Storages',
                    ar_label: 'خزانات',
                    parent_id: 281,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                ],
              },
              {
                _id: 284,
                en_label: 'Home Fragrance',
                ar_label: 'عطور المنزل',
                parent_id: 80,
                enabled: null,
                countries: null,
                margin: 0,
                marginRange: null,
                level: 3,
                backToBackGroupCode: null,
                backToBackSubGroupCode: null,
                backToBackSubSubGroupCode: null,
                children: [
                  {
                    _id: 329,
                    en_label: 'Candles',
                    ar_label: 'شموع',
                    parent_id: 284,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 332,
                    en_label: 'Car Fragrances',
                    ar_label: 'عطور السيارات',
                    parent_id: 284,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 335,
                    en_label: 'Reed Diffusers',
                    ar_label: 'فواحات',
                    parent_id: 284,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 338,
                    en_label: 'Fragrance Oils',
                    ar_label: 'زيوت معطرة',
                    parent_id: 284,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 341,
                    en_label: 'Incense Sticks & Holders',
                    ar_label: 'بخور',
                    parent_id: 284,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 344,
                    en_label: 'LED & Lanterns',
                    ar_label: 'LED فوانيس ومصابيح ',
                    parent_id: 284,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 347,
                    en_label: 'Aroma Diffusers & Humidifiers',
                    ar_label: 'فواحات عطرية و مرطبات هواء',
                    parent_id: 284,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 350,
                    en_label: 'Room & Car Sprays',
                    ar_label: 'عطور الغرف',
                    parent_id: 284,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 353,
                    en_label: 'Bakhoor & Burners',
                    ar_label: 'بخور وحاملات بخور',
                    parent_id: 284,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 2951,
                    en_label: 'Perfumes',
                    ar_label: 'عطور',
                    parent_id: 284,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3018,
                    en_label: 'Incense Holders',
                    ar_label: 'حاملات البخور',
                    parent_id: 284,
                    enabled: true,
                    countries: ['qat', 'uae', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3033,
                    en_label: 'Incense',
                    ar_label: 'بخور',
                    parent_id: 284,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                ],
              },
              {
                _id: 287,
                en_label: 'Wall Decor',
                ar_label: 'ديكور الجدران',
                parent_id: 80,
                enabled: null,
                countries: null,
                margin: 0,
                marginRange: null,
                level: 3,
                backToBackGroupCode: null,
                backToBackSubGroupCode: null,
                backToBackSubSubGroupCode: null,
                children: [
                  {
                    _id: 356,
                    en_label: 'Mirrors',
                    ar_label: 'مرآيا',
                    parent_id: 287,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 359,
                    en_label: 'Wall Arts',
                    ar_label: 'أعمال فنية للجدران',
                    parent_id: 287,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 362,
                    en_label: 'Wall Accents',
                    ar_label: 'ديكور الحائط',
                    parent_id: 287,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 365,
                    en_label: 'Photo Frames',
                    ar_label: 'إطار الصورة',
                    parent_id: 287,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 368,
                    en_label: 'Clocks',
                    ar_label: 'الساعات',
                    parent_id: 287,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                ],
              },
              {
                _id: 290,
                en_label: 'Beauty & Wellness',
                ar_label: 'العناية والجمال',
                parent_id: 80,
                enabled: null,
                countries: null,
                margin: 0,
                marginRange: null,
                level: 3,
                backToBackGroupCode: null,
                backToBackSubGroupCode: null,
                backToBackSubSubGroupCode: null,
                children: [
                  {
                    _id: 920,
                    en_label: 'Skin Care',
                    ar_label: null,
                    parent_id: 290,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 1976,
                    en_label: 'Sanitizer & Disinfectant',
                    ar_label: null,
                    parent_id: 290,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                ],
              },
              {
                _id: 914,
                en_label: 'Bathroom Accessories',
                ar_label: 'اكسسوارات الحمام',
                parent_id: 80,
                enabled: null,
                countries: null,
                margin: 0,
                marginRange: null,
                level: 3,
                backToBackGroupCode: null,
                backToBackSubGroupCode: null,
                backToBackSubSubGroupCode: null,
                children: null,
              },
              {
                _id: 917,
                en_label: 'Laundry',
                ar_label: 'مستلزمات الغسيل',
                parent_id: 80,
                enabled: null,
                countries: null,
                margin: 0,
                marginRange: null,
                level: 3,
                backToBackGroupCode: null,
                backToBackSubGroupCode: null,
                backToBackSubSubGroupCode: null,
                children: null,
              },
              {
                _id: 3051,
                en_label: 'Festive Collections',
                ar_label: 'مجموعات احتفالية',
                parent_id: 80,
                enabled: true,
                countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                margin: 0,
                marginRange: [],
                level: 3,
                backToBackGroupCode: '',
                backToBackSubGroupCode: '',
                backToBackSubSubGroupCode: '',
                children: null,
              },
            ],
          },
          {
            _id: 86,
            en_label: 'Bath',
            ar_label: 'الحمام',
            parent_id: 2,
            enabled: null,
            countries: null,
            margin: 0,
            marginRange: null,
            level: 2,
            backToBackGroupCode: null,
            backToBackSubGroupCode: null,
            backToBackSubSubGroupCode: null,
            children: [
              {
                _id: 89,
                en_label: 'Mixer',
                ar_label: 'خلاطات  الماء',
                parent_id: 86,
                enabled: null,
                countries: null,
                margin: 0,
                marginRange: null,
                level: 3,
                backToBackGroupCode: null,
                backToBackSubGroupCode: null,
                backToBackSubSubGroupCode: null,
                children: [
                  {
                    _id: 671,
                    en_label: 'Basin Mixer',
                    ar_label: 'حنفية خلاط مغسلة ',
                    parent_id: 89,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 674,
                    en_label: 'Shower Mixer',
                    ar_label: 'حنفية خلاط شاور',
                    parent_id: 89,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 677,
                    en_label: 'Sink Mixer',
                    ar_label: 'حنفية خلاط مطبخ',
                    parent_id: 89,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 680,
                    en_label: 'Tall Mixer',
                    ar_label: 'خلاط طويل',
                    parent_id: 89,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 683,
                    en_label: 'Free Standing Shower Mixer',
                    ar_label: 'حنفية دش أرضية',
                    parent_id: 89,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 686,
                    en_label: 'Sink Mixer-Wall Mounted',
                    ar_label: 'حنفية خلاط مطبخ - مثبت في الحائط',
                    parent_id: 89,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 689,
                    en_label: 'Complete Set',
                    ar_label: 'مجموعة وتشكيلة كاملة ',
                    parent_id: 89,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 929,
                    en_label: 'Pull Out Sink Mixer',
                    ar_label: 'حنفيات مع أنبوب قابل للسحب',
                    parent_id: 89,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                ],
              },
              {
                _id: 635,
                en_label: 'Rain Shower',
                ar_label: 'مجموعات الدش',
                parent_id: 86,
                enabled: null,
                countries: null,
                margin: 0,
                marginRange: null,
                level: 3,
                backToBackGroupCode: null,
                backToBackSubGroupCode: null,
                backToBackSubSubGroupCode: null,
                children: [
                  {
                    _id: 692,
                    en_label: 'Head Shower',
                    ar_label: 'رؤوس دش',
                    parent_id: 635,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 695,
                    en_label: 'Shower Column',
                    ar_label: 'عمود شاور',
                    parent_id: 635,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 698,
                    en_label: 'Hand Shower with Handle',
                    ar_label: 'دش يدوي مع حامل',
                    parent_id: 635,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 701,
                    en_label: 'Shower Panel',
                    ar_label: 'لوحة الدش',
                    parent_id: 635,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 704,
                    en_label: 'Complete Shower Set',
                    ar_label: 'طقم شاور كامل',
                    parent_id: 635,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 707,
                    en_label: 'Hand Shower',
                    ar_label: 'شاور يدوي',
                    parent_id: 635,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 710,
                    en_label: 'Concealed Shower Complete Set',
                    ar_label: 'مجموعة دش مخفية كاملة',
                    parent_id: 635,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                ],
              },
              {
                _id: 638,
                en_label: 'Mirrors & Cabinets',
                ar_label: 'الخزائن والمرايا',
                parent_id: 86,
                enabled: null,
                countries: null,
                margin: 0,
                marginRange: null,
                level: 3,
                backToBackGroupCode: null,
                backToBackSubGroupCode: null,
                backToBackSubSubGroupCode: null,
                children: [
                  {
                    _id: 713,
                    en_label: 'LED Mirror',
                    ar_label: 'مرآة مضيئة',
                    parent_id: 638,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 974,
                    en_label: 'Mirror Cabinet',
                    ar_label: 'خزانات ذات مرايا',
                    parent_id: 638,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                ],
              },
              {
                _id: 641,
                en_label: 'Accessories-Bath',
                ar_label: 'اكسسوارات',
                parent_id: 86,
                enabled: false,
                countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                margin: 0,
                marginRange: [],
                level: 3,
                backToBackGroupCode: '',
                backToBackSubGroupCode: '0',
                backToBackSubSubGroupCode: '',
                children: [
                  {
                    _id: 716,
                    en_label: 'Corner Shelf',
                    ar_label: 'رف الزاوية',
                    parent_id: 641,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 719,
                    en_label: 'Full Accessories Set',
                    ar_label: 'مجموعة اكسسوارات كاملة',
                    parent_id: 641,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 722,
                    en_label: 'Glass Shelf',
                    ar_label: 'رفوف زجاجية',
                    parent_id: 641,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 725,
                    en_label: 'Grab Bar',
                    ar_label: 'مقابض يد',
                    parent_id: 641,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 728,
                    en_label: 'Paper Holder',
                    ar_label: 'حاملات مناديل ',
                    parent_id: 641,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 731,
                    en_label: 'Robe Hook',
                    ar_label: 'حاملات ملابس',
                    parent_id: 641,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 734,
                    en_label: 'Soap Dish',
                    ar_label: 'صحون صابون',
                    parent_id: 641,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 737,
                    en_label: 'Soap Dispenser',
                    ar_label: 'موزع الصابون',
                    parent_id: 641,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 740,
                    en_label: 'Tissue Dispenser',
                    ar_label: 'موزع مناديل ورقية',
                    parent_id: 641,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 743,
                    en_label: 'Towel Holder',
                    ar_label: 'حامل مناشف',
                    parent_id: 641,
                    enabled: false,
                    countries: ['uae'],
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 746,
                    en_label: 'Towel Ring',
                    ar_label: 'حاملات مناشف دائرية',
                    parent_id: 641,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 749,
                    en_label: 'Tumbler Holder',
                    ar_label: 'حاملات اكواب',
                    parent_id: 641,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 923,
                    en_label: 'Hair & Hand Dryer',
                    ar_label: 'مجففات الشعر واليد',
                    parent_id: 641,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 926,
                    en_label: 'Magnifying Mirror',
                    ar_label: null,
                    parent_id: 641,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 2459,
                    en_label: 'Touchless Products',
                    ar_label: 'منتجات استشعار باللمس',
                    parent_id: 641,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                ],
              },
              {
                _id: 644,
                en_label: 'Bathtub & Shower Room',
                ar_label: 'أحواض الاستحمام وغرف الشاور',
                parent_id: 86,
                enabled: null,
                countries: null,
                margin: 0,
                marginRange: null,
                level: 3,
                backToBackGroupCode: null,
                backToBackSubGroupCode: null,
                backToBackSubSubGroupCode: null,
                children: [
                  {
                    _id: 752,
                    en_label: 'Free Standing Bathtub & Shower',
                    ar_label: 'حوض استحمام مستقل',
                    parent_id: 644,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 755,
                    en_label: 'Massage Bathtub',
                    ar_label: 'حوض استحمام مع تدليك',
                    parent_id: 644,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 758,
                    en_label: 'Shower Tray and Cubicle',
                    ar_label: 'صواني دش وكبائن شاور',
                    parent_id: 644,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 761,
                    en_label: 'Luxury Shower Room',
                    ar_label: 'غرف استحمام فاخرة',
                    parent_id: 644,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                ],
              },
              {
                _id: 647,
                en_label: 'Valves & Drains',
                ar_label: 'الصمامات والمصارف',
                parent_id: 86,
                enabled: null,
                countries: null,
                margin: 0,
                marginRange: null,
                level: 3,
                backToBackGroupCode: null,
                backToBackSubGroupCode: null,
                backToBackSubSubGroupCode: null,
                children: [
                  {
                    _id: 764,
                    en_label: 'Angle Valve',
                    ar_label: 'صمامات زاوية',
                    parent_id: 647,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 767,
                    en_label: 'Floor Drain',
                    ar_label: 'مصرف المياه الأرضي',
                    parent_id: 647,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                ],
              },
              {
                _id: 650,
                en_label: 'Wash Basin',
                ar_label: 'الأحواض',
                parent_id: 86,
                enabled: null,
                countries: null,
                margin: 0,
                marginRange: null,
                level: 3,
                backToBackGroupCode: null,
                backToBackSubGroupCode: null,
                backToBackSubSubGroupCode: null,
                children: [
                  {
                    _id: 770,
                    en_label: 'Pedestal',
                    ar_label: 'أحواض  بعمود ',
                    parent_id: 650,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 773,
                    en_label: 'Wall Hung',
                    ar_label: 'أحواض  معلقه',
                    parent_id: 650,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 2534,
                    en_label: 'Counter Top',
                    ar_label: 'حوض غسيل أعلى الكونتر',
                    parent_id: 650,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                ],
              },
              {
                _id: 653,
                en_label: 'Kitchen Sink',
                ar_label: 'أحواض المطبخ',
                parent_id: 86,
                enabled: null,
                countries: null,
                margin: 0,
                marginRange: null,
                level: 3,
                backToBackGroupCode: null,
                backToBackSubGroupCode: null,
                backToBackSubSubGroupCode: null,
                children: [
                  {
                    _id: 776,
                    en_label: 'Single Bowl',
                    ar_label: 'حوض منفرد',
                    parent_id: 653,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 941,
                    en_label: 'Double Bowl',
                    ar_label: 'أحواض مزدوجة',
                    parent_id: 653,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                ],
              },
              {
                _id: 944,
                en_label: 'Water Closet',
                ar_label: 'المرحاض',
                parent_id: 86,
                enabled: null,
                countries: null,
                margin: 0,
                marginRange: null,
                level: 3,
                backToBackGroupCode: null,
                backToBackSubGroupCode: null,
                backToBackSubSubGroupCode: null,
                children: [
                  {
                    _id: 947,
                    en_label: 'Asian WC',
                    ar_label: 'مرحاض اسيوي',
                    parent_id: 944,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 950,
                    en_label: 'Flush Mechanism',
                    ar_label: 'آليات تدفق المياه',
                    parent_id: 944,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 953,
                    en_label: 'WC Accessories',
                    ar_label: 'اكسسوارات المرحاض',
                    parent_id: 944,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 956,
                    en_label: 'WC P Trap',
                    ar_label: 'P مراحيض حمام',
                    parent_id: 944,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 959,
                    en_label: 'WC S Trap',
                    ar_label: 'S مراحيض حمام ',
                    parent_id: 944,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 2677,
                    en_label: 'Wall Hung',
                    ar_label: 'مرحاض معلق',
                    parent_id: 944,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                ],
              },
              {
                _id: 962,
                en_label: 'Vanity',
                ar_label: 'خزائن الحمام',
                parent_id: 86,
                enabled: null,
                countries: null,
                margin: 0,
                marginRange: null,
                level: 3,
                backToBackGroupCode: null,
                backToBackSubGroupCode: null,
                backToBackSubSubGroupCode: null,
                children: [
                  {
                    _id: 3055,
                    en_label: 'Single Basin',
                    ar_label: 'حوض واحد',
                    parent_id: 962,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3056,
                    en_label: 'Double Basin',
                    ar_label: 'حوض مزدوج',
                    parent_id: 962,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3057,
                    en_label: 'Thriple Basin',
                    ar_label: 'ثلاثي الأحواض',
                    parent_id: 962,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                ],
              },
              {
                _id: 965,
                en_label: 'Shattaf',
                ar_label: 'شطاف',
                parent_id: 86,
                enabled: null,
                countries: null,
                margin: 0,
                marginRange: null,
                level: 3,
                backToBackGroupCode: null,
                backToBackSubGroupCode: null,
                backToBackSubSubGroupCode: null,
                children: [
                  {
                    _id: 3058,
                    en_label: 'Brass Shattaf',
                    ar_label: 'شطاف نحاس',
                    parent_id: 965,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3059,
                    en_label: 'PVC Shattaf',
                    ar_label: 'شطاف PVC',
                    parent_id: 965,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                ],
              },
              {
                _id: 968,
                en_label: 'Water Heater',
                ar_label: 'سخانات المياه',
                parent_id: 86,
                enabled: null,
                countries: null,
                margin: 0,
                marginRange: null,
                level: 3,
                backToBackGroupCode: null,
                backToBackSubGroupCode: null,
                backToBackSubSubGroupCode: null,
                children: null,
              },
              {
                _id: 3034,
                en_label: 'Ancillaries',
                ar_label: 'الملحقات',
                parent_id: 86,
                enabled: true,
                countries: ['qat', 'uae', 'bah', 'omn', 'ksa', 'kwt'],
                margin: 0,
                marginRange: [],
                level: 3,
                backToBackGroupCode: '',
                backToBackSubGroupCode: '',
                backToBackSubSubGroupCode: '',
                children: null,
              },
            ],
          },
          {
            _id: 92,
            en_label: 'Lights',
            ar_label: 'إضاءة',
            parent_id: 2,
            enabled: null,
            countries: null,
            margin: 0,
            marginRange: null,
            level: 2,
            backToBackGroupCode: null,
            backToBackSubGroupCode: null,
            backToBackSubSubGroupCode: null,
            children: [
              {
                _id: 377,
                en_label: 'Outdoor Lighting',
                ar_label: 'إضاءة خارجية',
                parent_id: 92,
                enabled: null,
                countries: null,
                margin: 0,
                marginRange: null,
                level: 3,
                backToBackGroupCode: null,
                backToBackSubGroupCode: null,
                backToBackSubSubGroupCode: null,
                children: [
                  {
                    _id: 401,
                    en_label: 'Landscape Lights',
                    ar_label: 'إضاءة حدائق',
                    parent_id: 377,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 404,
                    en_label: 'Flood Lights & Spike Lights',
                    ar_label: 'إضاءة قوية وكشافات',
                    parent_id: 377,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 1025,
                    en_label: 'Wall Lights',
                    ar_label: 'إضاءة حائط',
                    parent_id: 377,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 1028,
                    en_label: 'Underground Lights',
                    ar_label: 'إضاءة أرضية',
                    parent_id: 377,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 3103,
                    en_label: 'String Lights',
                    ar_label: 'المصابيح الطولية',
                    parent_id: 377,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '0',
                  },
                  {
                    _id: 3104,
                    en_label: 'Solar Floor Lamp ',
                    ar_label: 'مصباح أرضي يعمل بالطاقة الشمسية',
                    parent_id: 377,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '0',
                  },
                  {
                    _id: 3105,
                    en_label: 'Solar Lanterns',
                    ar_label: 'مصابيح شمسية',
                    parent_id: 377,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '0',
                  },
                  {
                    _id: 3106,
                    en_label: 'Camping Lights',
                    ar_label: 'مصابيح التخييم',
                    parent_id: 377,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '0',
                  },
                  {
                    _id: 3107,
                    en_label: 'Outdoor Accessories',
                    ar_label: 'إكسسوارات خارجية',
                    parent_id: 377,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '0',
                  },
                  {
                    _id: 3117,
                    en_label: 'Hanging Lights',
                    ar_label: 'أضواء معلقة',
                    parent_id: 377,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                ],
              },
              {
                _id: 3099,
                en_label: 'Indoor',
                ar_label: 'الداخلية',
                parent_id: 92,
                enabled: true,
                countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                margin: 0,
                marginRange: [],
                level: 3,
                backToBackGroupCode: '',
                backToBackSubGroupCode: '',
                backToBackSubSubGroupCode: '',
                children: [
                  {
                    _id: 371,
                    en_label: 'Ceiling Lights',
                    ar_label: 'إضاءة السقف',
                    parent_id: 3099,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '0',
                    backToBackSubSubGroupCode: 'Ceiling Lights',
                  },
                  {
                    _id: 383,
                    en_label: 'Chandeliers',
                    ar_label: 'ثريات',
                    parent_id: 3099,
                    enabled: false,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '0',
                  },
                  {
                    _id: 392,
                    en_label: 'Indoor Wall Lights',
                    ar_label: 'أضواء حائط داخلية',
                    parent_id: 3099,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '0',
                    backToBackSubSubGroupCode: '0',
                  },
                  {
                    _id: 395,
                    en_label: 'Table Lamps',
                    ar_label: 'مصابيح طاولة',
                    parent_id: 3099,
                    enabled: false,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '0',
                  },
                  {
                    _id: 398,
                    en_label: 'Floor Lamps',
                    ar_label: 'مصابيح أرضية',
                    parent_id: 3099,
                    enabled: false,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '0',
                  },
                  {
                    _id: 3100,
                    en_label: 'Staircase Chandeliers',
                    ar_label: 'ثريات السلالم',
                    parent_id: 3099,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3101,
                    en_label: 'Pendants',
                    ar_label: 'القلادات',
                    parent_id: 3099,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3102,
                    en_label: 'Fan Lights',
                    ar_label: 'مصابيح المروحة',
                    parent_id: 3099,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3115,
                    en_label: 'Bulbs',
                    ar_label: 'المصابيح',
                    parent_id: 3099,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                ],
              },
              {
                _id: 3108,
                en_label: 'Smart Home',
                ar_label: 'المنزل الذكي',
                parent_id: 92,
                enabled: true,
                countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                margin: 0,
                marginRange: [],
                level: 3,
                backToBackGroupCode: '',
                backToBackSubGroupCode: '',
                backToBackSubSubGroupCode: '',
                children: [
                  {
                    _id: 3109,
                    en_label: 'Home Automation',
                    ar_label: 'المنزل المميكن',
                    parent_id: 3108,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3110,
                    en_label: 'Smart Lights & Bulbs',
                    ar_label: 'مصابيح ذكية',
                    parent_id: 3108,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                ],
              },
              {
                _id: 3111,
                en_label: 'Electricals',
                ar_label: 'الكهرباء',
                parent_id: 92,
                enabled: true,
                countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                margin: 0,
                marginRange: [],
                level: 3,
                backToBackGroupCode: '',
                backToBackSubGroupCode: '',
                backToBackSubSubGroupCode: '',
                children: [
                  {
                    _id: 3112,
                    en_label: 'Spot Lights',
                    ar_label: 'مصابيح سبوت',
                    parent_id: 3111,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3113,
                    en_label: 'Led Strip Lights',
                    ar_label: 'شرائط LED',
                    parent_id: 3111,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3114,
                    en_label: 'LED Down lights',
                    ar_label: 'أضواء LED متجهة للأسفل',
                    parent_id: 3111,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3116,
                    en_label: 'Panel Lights',
                    ar_label: 'مصابيح لوحية',
                    parent_id: 3111,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                ],
              },
            ],
          },
          {
            _id: 2265,
            en_label: 'Gift Cards',
            ar_label: 'Gift Cards',
            parent_id: 2,
            enabled: true,
            countries: ['uae', 'bah', 'omn'],
            margin: 0,
            marginRange: [],
            level: 2,
            backToBackGroupCode: '0',
            backToBackSubGroupCode: '',
            backToBackSubSubGroupCode: '',
            children: null,
          },
          {
            _id: 2350,
            en_label: 'Kitchen',
            ar_label: 'المطابخ',
            parent_id: 2,
            enabled: null,
            countries: null,
            margin: 0,
            marginRange: null,
            level: 2,
            backToBackGroupCode: null,
            backToBackSubGroupCode: null,
            backToBackSubSubGroupCode: null,
            children: [
              {
                _id: 2353,
                en_label: 'Table Top',
                ar_label: 'سطح المائدة',
                parent_id: 2350,
                enabled: null,
                countries: null,
                margin: 0,
                marginRange: null,
                level: 3,
                backToBackGroupCode: null,
                backToBackSubGroupCode: null,
                backToBackSubSubGroupCode: null,
                children: [
                  {
                    _id: 2365,
                    en_label: 'Dinner Sets',
                    ar_label: 'أطقم السفرة',
                    parent_id: 2353,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 2368,
                    en_label: 'Loose Crockery',
                    ar_label: 'أواني منفردة',
                    parent_id: 2353,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 2371,
                    en_label: 'Cutlery',
                    ar_label: 'أدوات المائدة',
                    parent_id: 2353,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 2374,
                    en_label: 'Glassware',
                    ar_label: 'أواني زجاجية',
                    parent_id: 2353,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 2377,
                    en_label: 'Mugs Cup & Saucers',
                    ar_label: 'أكواب, كؤؤس وصحون الفنجان',
                    parent_id: 2353,
                    enabled: false,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '0',
                  },
                  {
                    _id: 2380,
                    en_label: 'Water Bottles & Jugs',
                    ar_label: 'زجاجات وأباريق المياه',
                    parent_id: 2353,
                    enabled: false,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '0',
                  },
                  {
                    _id: 2383,
                    en_label: 'Flask',
                    ar_label: 'قوارير الترمس',
                    parent_id: 2353,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 2386,
                    en_label: 'Hot Pot',
                    ar_label: 'أواني طهي حرارية',
                    parent_id: 2353,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 2389,
                    en_label: 'Serveware',
                    ar_label: 'أواني تقديم',
                    parent_id: 2353,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 2392,
                    en_label: 'Table Linen & Accessories',
                    ar_label: 'الاكسسوارات ومفارش الطاولة',
                    parent_id: 2353,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 2843,
                    en_label: 'Tea Pots & Kettles',
                    ar_label: 'أواني الشاي والغلّايات',
                    parent_id: 2353,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '0',
                  },
                  {
                    _id: 2881,
                    en_label: 'Juice Dispenser',
                    ar_label: 'موزع عصير',
                    parent_id: 2353,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'kwt', 'ksa'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                ],
              },
              {
                _id: 2356,
                en_label: 'Kitchenware',
                ar_label: 'مستلزمات المطيخ',
                parent_id: 2350,
                enabled: null,
                countries: null,
                margin: 0,
                marginRange: null,
                level: 3,
                backToBackGroupCode: null,
                backToBackSubGroupCode: null,
                backToBackSubSubGroupCode: null,
                children: [
                  {
                    _id: 2395,
                    en_label: 'Cookware Sets',
                    ar_label: 'طقم أدوات الطهي',
                    parent_id: 2356,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 2398,
                    en_label: 'Pots & Pans',
                    ar_label: 'القدولر والمقالي',
                    parent_id: 2356,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 2401,
                    en_label: 'Pressure Cooker',
                    ar_label: 'أواني الضغط',
                    parent_id: 2356,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 2404,
                    en_label: 'Tools & Gadgets',
                    ar_label: 'الأدوات والمعدات',
                    parent_id: 2356,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 2407,
                    en_label: 'Knife & Cutting Board',
                    ar_label: 'السكاكين وألواح التقطيع',
                    parent_id: 2356,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 2410,
                    en_label: 'Food Storage',
                    ar_label: 'تخزين الطعام',
                    parent_id: 2356,
                    enabled: false,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '1',
                  },
                  {
                    _id: 2413,
                    en_label: 'Dish Rack',
                    ar_label: 'رفوف الصحون',
                    parent_id: 2356,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 2416,
                    en_label: 'Bakeware',
                    ar_label: 'أدوات الخبز',
                    parent_id: 2356,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 2844,
                    en_label: 'Spice Racks & Jars',
                    ar_label: 'رفوف البهارات والجرّات',
                    parent_id: 2356,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2883,
                    en_label: 'Bread Baskets & Bins',
                    ar_label: 'سلال وصناديق الخبز',
                    parent_id: 2356,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3017,
                    en_label: 'Food Service Equipment & Supplies',
                    ar_label: 'معدات ولوازم خدمة الطعام',
                    parent_id: 2356,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                ],
              },
              {
                _id: 2359,
                en_label: 'Bath & Laundry',
                ar_label: 'الغسيل والتنظيف',
                parent_id: 2350,
                enabled: null,
                countries: null,
                margin: 0,
                marginRange: null,
                level: 3,
                backToBackGroupCode: null,
                backToBackSubGroupCode: null,
                backToBackSubSubGroupCode: null,
                children: [
                  {
                    _id: 2419,
                    en_label: 'Bath Accessories',
                    ar_label: 'اكسسوارات الحمام',
                    parent_id: 2359,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 2422,
                    en_label: 'Laundry Accessories',
                    ar_label: 'اكسسوارات الغسيل',
                    parent_id: 2359,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 2425,
                    en_label: 'Cleaning Accessories',
                    ar_label: 'اكسسوارات التنظيف',
                    parent_id: 2359,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 2885,
                    en_label: 'Waste Disposal Bin',
                    ar_label: 'صندوق التخلص من النفايات',
                    parent_id: 2359,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                ],
              },
              {
                _id: 2362,
                en_label: 'Appliances',
                ar_label: 'الأدوات',
                parent_id: 2350,
                enabled: null,
                countries: null,
                margin: 0,
                marginRange: null,
                level: 3,
                backToBackGroupCode: null,
                backToBackSubGroupCode: null,
                backToBackSubSubGroupCode: null,
                children: [
                  {
                    _id: 2428,
                    en_label: 'Kitchen Appliances-BC',
                    ar_label: 'أدوات المطبخ',
                    parent_id: 2362,
                    enabled: false,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '1',
                  },
                  {
                    _id: 2431,
                    en_label: 'Household Appliances',
                    ar_label: 'أدوات منزلية',
                    parent_id: 2362,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 2434,
                    en_label: 'Water Purifier',
                    ar_label: 'منقي المياه',
                    parent_id: 2362,
                    enabled: null,
                    countries: null,
                    margin: 0,
                    marginRange: null,
                    level: 4,
                    backToBackGroupCode: null,
                    backToBackSubGroupCode: null,
                    backToBackSubSubGroupCode: null,
                  },
                  {
                    _id: 2842,
                    en_label: 'Home Appliances-BC',
                    ar_label: 'أجهزة منزلية',
                    parent_id: 2362,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '0',
                  },
                ],
              },
            ],
          },
          {
            _id: 2849,
            en_label: 'Tiles and Bricks',
            ar_label: 'Tiles and Bricks',
            parent_id: 2,
            enabled: true,
            countries: ['uae', 'qat', 'bah', 'omn'],
            margin: 0,
            marginRange: [],
            level: 2,
            backToBackGroupCode: '',
            backToBackSubGroupCode: '',
            backToBackSubSubGroupCode: '',
            children: [
              {
                _id: 2850,
                en_label: 'Commercial (Tiles)',
                ar_label: 'Commercial (Tiles)',
                parent_id: 2849,
                enabled: true,
                countries: ['uae', 'qat', 'bah', 'omn'],
                margin: 0,
                marginRange: [],
                level: 3,
                backToBackGroupCode: '',
                backToBackSubGroupCode: '1',
                backToBackSubSubGroupCode: '',
                children: [
                  {
                    _id: 2857,
                    en_label: 'Wall Tiles',
                    ar_label: 'Wall Tiles',
                    parent_id: 2850,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2858,
                    en_label: 'Floor Tiles',
                    ar_label: 'بلاط الأرضية',
                    parent_id: 2850,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '0',
                  },
                  {
                    _id: 2859,
                    en_label: 'Islabz',
                    ar_label: 'Islabz',
                    parent_id: 2850,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2860,
                    en_label: 'Outdoor Tiles',
                    ar_label: 'Outdoor Tiles',
                    parent_id: 2850,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2861,
                    en_label: 'Wooden Tiles',
                    ar_label: 'Wooden Tiles',
                    parent_id: 2850,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2862,
                    en_label: 'Kitchstone',
                    ar_label: 'Kitchstone',
                    parent_id: 2850,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2863,
                    en_label: 'Pool Tiles',
                    ar_label: 'Pool Tiles',
                    parent_id: 2850,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                ],
              },
              {
                _id: 2851,
                en_label: 'Bathroom (Tiles)',
                ar_label: 'Bathroom (Tiles)',
                parent_id: 2849,
                enabled: true,
                countries: ['uae', 'qat', 'bah', 'omn'],
                margin: 0,
                marginRange: [],
                level: 3,
                backToBackGroupCode: '',
                backToBackSubGroupCode: '1',
                backToBackSubSubGroupCode: '',
                children: [
                  {
                    _id: 2864,
                    en_label: 'Wall Tiles',
                    ar_label: 'Wall Tiles',
                    parent_id: 2851,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2865,
                    en_label: 'Floor Tiles',
                    ar_label: 'Floor Tiles',
                    parent_id: 2851,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2866,
                    en_label: 'Islabz',
                    ar_label: 'Islabz',
                    parent_id: 2851,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2884,
                    en_label: 'By Family',
                    ar_label: 'By Family',
                    parent_id: 2851,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '1',
                  },
                ],
              },
              {
                _id: 2852,
                en_label: 'Bedroom (Tiles)',
                ar_label: 'Bedroom (Tiles)',
                parent_id: 2849,
                enabled: true,
                countries: ['uae', 'qat', 'bah', 'omn'],
                margin: 0,
                marginRange: [],
                level: 3,
                backToBackGroupCode: '',
                backToBackSubGroupCode: '1',
                backToBackSubSubGroupCode: '',
                children: [
                  {
                    _id: 2867,
                    en_label: 'Floor Tiles',
                    ar_label: 'Floor Tiles',
                    parent_id: 2852,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2868,
                    en_label: 'Islabz',
                    ar_label: 'Islabz',
                    parent_id: 2852,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2869,
                    en_label: 'Wooden Tiles',
                    ar_label: 'Wooden Tiles',
                    parent_id: 2852,
                    enabled: true,
                    countries: ['uae', 'qat', 'omn', 'bah'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                ],
              },
              {
                _id: 2853,
                en_label: 'Kitchen (Tiles)',
                ar_label: 'المطابخ',
                parent_id: 2849,
                enabled: true,
                countries: ['uae', 'qat', 'bah', 'omn'],
                margin: 0,
                marginRange: [],
                level: 3,
                backToBackGroupCode: '',
                backToBackSubGroupCode: '0',
                backToBackSubSubGroupCode: '',
                children: [
                  {
                    _id: 2870,
                    en_label: 'Wall Tiles',
                    ar_label: 'Wall Tiles',
                    parent_id: 2853,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2871,
                    en_label: 'Floor Tiles',
                    ar_label: 'Floor Tiles',
                    parent_id: 2853,
                    enabled: true,
                    countries: ['uae', 'qat', 'omn', 'bah'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2872,
                    en_label: 'Islabz',
                    ar_label: 'Islabz',
                    parent_id: 2853,
                    enabled: true,
                    countries: ['uae', 'qat', 'omn', 'bah'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2873,
                    en_label: 'Wooden Tiles',
                    ar_label: 'Wooden Tiles',
                    parent_id: 2853,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2874,
                    en_label: 'Kitchstone',
                    ar_label: 'Kitchstone',
                    parent_id: 2853,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                ],
              },
              {
                _id: 2854,
                en_label: 'Living Room (Tiles)',
                ar_label: 'غرفة المعيشة',
                parent_id: 2849,
                enabled: true,
                countries: ['uae', 'qat', 'bah', 'omn'],
                margin: 0,
                marginRange: [],
                level: 3,
                backToBackGroupCode: '',
                backToBackSubGroupCode: '1',
                backToBackSubSubGroupCode: '',
                children: [
                  {
                    _id: 2875,
                    en_label: 'Wall Tiles',
                    ar_label: 'Wall Tiles',
                    parent_id: 2854,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2876,
                    en_label: 'Floor Tiles',
                    ar_label: 'Floor Tiles',
                    parent_id: 2854,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2877,
                    en_label: 'Islabz',
                    ar_label: 'Islabz',
                    parent_id: 2854,
                    enabled: true,
                    countries: ['uae', 'qat', 'omn', 'bah'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2878,
                    en_label: 'Wooden Tiles',
                    ar_label: 'Wooden Tiles',
                    parent_id: 2854,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2886,
                    en_label: 'By Family',
                    ar_label: 'By Family',
                    parent_id: 2854,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                ],
              },
              {
                _id: 2855,
                en_label: 'Outdoor (Tiles)',
                ar_label: 'الأثاث الخارجي',
                parent_id: 2849,
                enabled: true,
                countries: ['uae', 'qat', 'bah', 'omn'],
                margin: 0,
                marginRange: [],
                level: 3,
                backToBackGroupCode: '',
                backToBackSubGroupCode: '1',
                backToBackSubSubGroupCode: '',
                children: [
                  {
                    _id: 2879,
                    en_label: 'Outdoor Floor Tiles',
                    ar_label: 'Outdoor Floor Tiles',
                    parent_id: 2855,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '1',
                  },
                ],
              },
              {
                _id: 2856,
                en_label: 'Swimming Pool (Tiles)',
                ar_label: 'مسابح',
                parent_id: 2849,
                enabled: true,
                countries: ['uae', 'qat', 'bah', 'omn'],
                margin: 0,
                marginRange: [],
                level: 3,
                backToBackGroupCode: '',
                backToBackSubGroupCode: '1',
                backToBackSubSubGroupCode: '',
                children: [
                  {
                    _id: 2880,
                    en_label: 'Pool Tiles',
                    ar_label: 'Pool Tiles',
                    parent_id: 2856,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                ],
              },
            ],
          },
          {
            _id: 2887,
            en_label: 'Electronics & Appliances',
            ar_label: 'الإلكترونيات والأجهزة الكهربائية',
            parent_id: 2,
            enabled: true,
            countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
            margin: 0,
            marginRange: [],
            level: 2,
            backToBackGroupCode: '1',
            backToBackSubGroupCode: '',
            backToBackSubSubGroupCode: '',
            children: [
              {
                _id: 2888,
                en_label: 'Kitchen Appliances',
                ar_label: 'أدوات المطبخ',
                parent_id: 2887,
                enabled: true,
                countries: ['uae', 'bah', 'qat', 'ksa', 'omn', 'kwt'],
                margin: 0,
                marginRange: [],
                level: 3,
                backToBackGroupCode: '',
                backToBackSubGroupCode: '',
                backToBackSubSubGroupCode: '',
                children: [
                  {
                    _id: 2892,
                    en_label: 'Refrigerators',
                    ar_label: 'ثلاجات',
                    parent_id: 2888,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2893,
                    en_label: 'Cooking Range',
                    ar_label: 'مجموعة الطبخ',
                    parent_id: 2888,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2894,
                    en_label: 'Microwave Ovens',
                    ar_label: 'أفران الميكروويف',
                    parent_id: 2888,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2895,
                    en_label: 'Dishwashers',
                    ar_label: 'غسالات الصحون',
                    parent_id: 2888,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2896,
                    en_label: 'Chimneys',
                    ar_label: 'مَداخِن',
                    parent_id: 2888,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2897,
                    en_label: 'Mixer and Food Processor',
                    ar_label: 'خلاط ومحضر طعام',
                    parent_id: 2888,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2898,
                    en_label: 'Electric Kettles',
                    ar_label: 'غلايات كهربائية',
                    parent_id: 2888,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2899,
                    en_label: 'Bread and Sandwich Makers',
                    ar_label: 'صناع الخبز والساندويتشات',
                    parent_id: 2888,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2900,
                    en_label: 'Coffee Makers & Grinders',
                    ar_label: 'آلات صنع القهوة والمطاحن',
                    parent_id: 2888,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2901,
                    en_label: 'Juicers',
                    ar_label: 'عصارات',
                    parent_id: 2888,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2902,
                    en_label: 'Hand Mixers & Blenders',
                    ar_label: 'خلاطات يدوية وخلاطات',
                    parent_id: 2888,
                    enabled: true,
                    countries: ['uae', 'bah', 'omn', 'ksa', 'kwt', 'qat'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2954,
                    en_label: 'Air Fryer',
                    ar_label: 'مقلاة الهواء',
                    parent_id: 2888,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3032,
                    en_label: 'Packing Accessories',
                    ar_label: 'ملحقات التعبئة',
                    parent_id: 2888,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                ],
              },
              {
                _id: 2889,
                en_label: 'Home Appliances',
                ar_label: 'أجهزة منزلية',
                parent_id: 2887,
                enabled: true,
                countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                margin: 0,
                marginRange: [],
                level: 3,
                backToBackGroupCode: '',
                backToBackSubGroupCode: '',
                backToBackSubSubGroupCode: '',
                children: [
                  {
                    _id: 2903,
                    en_label: 'Fans & Air Coolers',
                    ar_label: 'المراوح ومبردات الهواء',
                    parent_id: 2889,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2904,
                    en_label: 'Irons',
                    ar_label: 'مكاوي',
                    parent_id: 2889,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2907,
                    en_label: 'Vacuum Cleaners',
                    ar_label: 'مكنسة كهربائية',
                    parent_id: 2889,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3014,
                    en_label: 'Washing Machines',
                    ar_label: 'آلات الغسيل',
                    parent_id: 2889,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3015,
                    en_label: 'Water Purifiers',
                    ar_label: 'منقيات المياه',
                    parent_id: 2889,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3016,
                    en_label: 'Water Heaters',
                    ar_label: 'سخانات مياه',
                    parent_id: 2889,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3019,
                    en_label: 'Heaters',
                    ar_label: 'سخانات',
                    parent_id: 2889,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                ],
              },
              {
                _id: 2890,
                en_label: 'Entertainment',
                ar_label: 'ترفيه',
                parent_id: 2887,
                enabled: true,
                countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                margin: 0,
                marginRange: [],
                level: 3,
                backToBackGroupCode: '',
                backToBackSubGroupCode: '',
                backToBackSubSubGroupCode: '',
                children: [
                  {
                    _id: 2961,
                    en_label: 'Media Players',
                    ar_label: 'مشغلات الوسائط',
                    parent_id: 2890,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2964,
                    en_label: 'TV',
                    ar_label: 'التلفزيون',
                    parent_id: 2890,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                ],
              },
              {
                _id: 2891,
                en_label: 'Personal Grooming',
                ar_label: 'الاستمالة الشخصية',
                parent_id: 2887,
                enabled: true,
                countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                margin: 0,
                marginRange: [],
                level: 3,
                backToBackGroupCode: '',
                backToBackSubGroupCode: '',
                backToBackSubSubGroupCode: '',
                children: [
                  {
                    _id: 2905,
                    en_label: 'Trimmers & Shavers',
                    ar_label: 'أدوات التشذيب والحلاقة',
                    parent_id: 2891,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2906,
                    en_label: 'Hair Straighteners',
                    ar_label: 'منعمات شعر',
                    parent_id: 2891,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                ],
              },
              {
                _id: 2909,
                en_label: 'Mobile & Accessories',
                ar_label: 'اكسسوارات المحمول',
                parent_id: 2887,
                enabled: true,
                countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                margin: 0,
                marginRange: [],
                level: 3,
                backToBackGroupCode: '',
                backToBackSubGroupCode: '1',
                backToBackSubSubGroupCode: '',
                children: [
                  {
                    _id: 2929,
                    en_label: 'Accessories',
                    ar_label: 'مُكَمِّلات',
                    parent_id: 2909,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2963,
                    en_label: 'Tablets',
                    ar_label: 'أجهزة اللوحي',
                    parent_id: 2909,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3020,
                    en_label: 'Mobiles',
                    ar_label: 'هواتف محمولة',
                    parent_id: 2909,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                ],
              },
              {
                _id: 2911,
                en_label: 'Laptops & Computers',
                ar_label: 'أجهزة الكمبيوتر المحمولة وأجهزة الكمبيوتر',
                parent_id: 2887,
                enabled: true,
                countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                margin: 0,
                marginRange: [],
                level: 3,
                backToBackGroupCode: '',
                backToBackSubGroupCode: '1',
                backToBackSubSubGroupCode: '',
                children: [
                  {
                    _id: 2930,
                    en_label: 'Laptops & Computers Accessories',
                    ar_label: 'أجهزة الكمبيوتر المحمولة وملحقات الكمبيوتر',
                    parent_id: 2911,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2938,
                    en_label: 'Desk & Monitors',
                    ar_label: 'مكتب وشاشات',
                    parent_id: 2911,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2960,
                    en_label: 'Laptops',
                    ar_label: 'أجهزة الكمبيوتر المحمولة',
                    parent_id: 2911,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '1',
                  },
                ],
              },
              {
                _id: 2912,
                en_label: 'Video Games',
                ar_label: 'ألعاب الفيديو',
                parent_id: 2887,
                enabled: true,
                countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                margin: 0,
                marginRange: [],
                level: 3,
                backToBackGroupCode: '',
                backToBackSubGroupCode: '1',
                backToBackSubSubGroupCode: '',
                children: [
                  {
                    _id: 2955,
                    en_label: 'Consoles',
                    ar_label: 'أجهزة الألعاب',
                    parent_id: 2912,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2957,
                    en_label: 'Gaming Laptops',
                    ar_label: 'أجهزة الكمبيوتر المحمولة للألعاب',
                    parent_id: 2912,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2958,
                    en_label: 'Head Sets',
                    ar_label: 'سماعات الرأس',
                    parent_id: 2912,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2966,
                    en_label: 'Video Games Accessories',
                    ar_label: 'إكسسوارات ألعاب الفيديو',
                    parent_id: 2912,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                ],
              },
              {
                _id: 2913,
                en_label: 'Televisions',
                ar_label: 'أجهزة التلفاز',
                parent_id: 2887,
                enabled: true,
                countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                margin: 0,
                marginRange: [],
                level: 3,
                backToBackGroupCode: '',
                backToBackSubGroupCode: '1',
                backToBackSubSubGroupCode: '',
                children: [
                  {
                    _id: 2921,
                    en_label: 'TV Accessories',
                    ar_label: 'اكسسوارات التلفزيون',
                    parent_id: 2913,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2923,
                    en_label: 'Home Theatres',
                    ar_label: 'المسارح المنزلية',
                    parent_id: 2913,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2928,
                    en_label: 'Speaker',
                    ar_label: 'مكبر الصوت',
                    parent_id: 2913,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2965,
                    en_label: 'TV Mounts',
                    ar_label: 'حوامل التلفزيون',
                    parent_id: 2913,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3021,
                    en_label: 'Sound Bars',
                    ar_label: 'شريط صوت',
                    parent_id: 2913,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                ],
              },
              {
                _id: 2914,
                en_label: 'Speakers',
                ar_label: 'مكبرات الصوت',
                parent_id: 2887,
                enabled: true,
                countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                margin: 0,
                marginRange: [],
                level: 3,
                backToBackGroupCode: '',
                backToBackSubGroupCode: '1',
                backToBackSubSubGroupCode: '',
                children: [
                  {
                    _id: 2918,
                    en_label: 'Smart Speakers & Radios',
                    ar_label: 'مكبرات الصوت وأجهزة الراديو الذكية',
                    parent_id: 2914,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2919,
                    en_label: 'Head Phones & Earphones',
                    ar_label: 'سماعات الرأس وسماعات الأذن',
                    parent_id: 2914,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2920,
                    en_label: 'Wired Earphones',
                    ar_label: 'سماعات سلكية',
                    parent_id: 2914,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2924,
                    en_label: 'Portable Bluetooth Speakers',
                    ar_label: 'مكبرات صوت بلوتوث محمولة',
                    parent_id: 2914,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2925,
                    en_label: 'Wireless Earphones',
                    ar_label: 'سماعات لاسلكية',
                    parent_id: 2914,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2959,
                    en_label: 'Home & Multimedia Speakers',
                    ar_label: 'مكبرات الصوت المنزلية ومتعددة الوسائط',
                    parent_id: 2914,
                    enabled: true,
                    countries: ['qat', 'uae', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3049,
                    en_label: 'Portable Speakers',
                    ar_label: 'سماعات محمولة',
                    parent_id: 2914,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                ],
              },
              {
                _id: 2916,
                en_label: 'Wearable Devices',
                ar_label: 'الأجهزة القابلة للارتداء',
                parent_id: 2887,
                enabled: true,
                countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                margin: 0,
                marginRange: [],
                level: 3,
                backToBackGroupCode: '',
                backToBackSubGroupCode: '1',
                backToBackSubSubGroupCode: '',
                children: [
                  {
                    _id: 2926,
                    en_label: 'Smart Watches',
                    ar_label: 'الساعات الذكية',
                    parent_id: 2916,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '1',
                  },
                  {
                    _id: 2967,
                    en_label: 'Wearable Devices Accessories',
                    ar_label: 'إكسسوارات أجهزة الارتداء',
                    parent_id: 2916,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '1',
                  },
                ],
              },
              {
                _id: 2917,
                en_label: 'Camera-Photo & Video',
                ar_label: 'الكاميرا والصور والفيديو',
                parent_id: 2887,
                enabled: true,
                countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                margin: 0,
                marginRange: [],
                level: 3,
                backToBackGroupCode: '',
                backToBackSubGroupCode: '1',
                backToBackSubSubGroupCode: '',
                children: [
                  {
                    _id: 2922,
                    en_label: 'Surveillances Cameras',
                    ar_label: 'كاميرات المراقبة',
                    parent_id: 2917,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2927,
                    en_label: 'Action Cameras',
                    ar_label: 'كاميرات الأكشن',
                    parent_id: 2917,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '1',
                  },
                  {
                    _id: 2956,
                    en_label: 'Drones',
                    ar_label: 'الطائرات بدون طيار',
                    parent_id: 2917,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2962,
                    en_label: 'Mirrorless',
                    ar_label: 'بدون مرآة',
                    parent_id: 2917,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                ],
              },
            ],
          },
          {
            _id: 2931,
            en_label: 'Toys & Games',
            ar_label: 'الألعاب والألعاب',
            parent_id: 2,
            enabled: true,
            countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
            margin: 0,
            marginRange: [],
            level: 2,
            backToBackGroupCode: '',
            backToBackSubGroupCode: '',
            backToBackSubSubGroupCode: '',
            children: [
              {
                _id: 2932,
                en_label: 'Dressingg up & costumes',
                ar_label: 'خلع الملابس والأزياء',
                parent_id: 2931,
                enabled: true,
                countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                margin: 0,
                marginRange: [],
                level: 3,
                backToBackGroupCode: '',
                backToBackSubGroupCode: '',
                backToBackSubSubGroupCode: '',
                children: [
                  {
                    _id: 2933,
                    en_label: 'Dressingg & Costume Accessories',
                    ar_label: 'خلع الملابس وإكسسوارات الأزياء',
                    parent_id: 2932,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '1',
                  },
                  {
                    _id: 2934,
                    en_label: 'Masks',
                    ar_label: 'أقنعة',
                    parent_id: 2932,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2992,
                    en_label: 'Beauty & Fashion',
                    ar_label: 'الجمال والأزياء',
                    parent_id: 2932,
                    enabled: true,
                    countries: ['qat', 'uae', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                ],
              },
              {
                _id: 2935,
                en_label: 'Party Supplies',
                ar_label: 'لوازم الحفلات',
                parent_id: 2931,
                enabled: true,
                countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                margin: 0,
                marginRange: [],
                level: 3,
                backToBackGroupCode: '',
                backToBackSubGroupCode: '',
                backToBackSubSubGroupCode: '',
                children: [
                  {
                    _id: 2936,
                    en_label: 'Party Favors',
                    ar_label: 'تفضيلات الحفلة',
                    parent_id: 2935,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2937,
                    en_label: 'Balloons',
                    ar_label: 'بالونات',
                    parent_id: 2935,
                    enabled: true,
                    countries: ['qat', 'uae', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2996,
                    en_label: 'Candles',
                    ar_label: 'شموع',
                    parent_id: 2935,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3006,
                    en_label: 'Party Tableware',
                    ar_label: 'أواني وأدوات الحفلات',
                    parent_id: 2935,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3043,
                    en_label: 'Banners Streamers & Confetti',
                    ar_label: 'رايات، أعلام وأوراق ملونة',
                    parent_id: 2935,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                ],
              },
              {
                _id: 2945,
                en_label: 'Sports & Outdoor Play',
                ar_label: 'الرياضة واللعب في الهواء الطلق',
                parent_id: 2931,
                enabled: true,
                countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                margin: 0,
                marginRange: [],
                level: 3,
                backToBackGroupCode: '',
                backToBackSubGroupCode: '',
                backToBackSubSubGroupCode: '',
                children: [
                  {
                    _id: 2946,
                    en_label: 'Balls',
                    ar_label: 'كرات',
                    parent_id: 2945,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2981,
                    en_label: 'Gym Sets & Swings',
                    ar_label: 'أطقم الصالة الرياضية والأرجوحات',
                    parent_id: 2945,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3002,
                    en_label: "Kid's Sports",
                    ar_label: 'رياضة الأطفال',
                    parent_id: 2945,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3008,
                    en_label: 'Sand & Water Tables',
                    ar_label: '"طاولات الرمل والماء',
                    parent_id: 2945,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3035,
                    en_label: 'Play Sets & Playground Equipment',
                    ar_label: 'ألعاب ومعدات اللعب والملعب',
                    parent_id: 2945,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                ],
              },
              {
                _id: 2982,
                en_label: 'Baby & Toddler Toys',
                ar_label: 'ألعاب الأطفال والرضع',
                parent_id: 2931,
                enabled: true,
                countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                margin: 0,
                marginRange: [],
                level: 3,
                backToBackGroupCode: '',
                backToBackSubGroupCode: '',
                backToBackSubSubGroupCode: '',
                children: [
                  {
                    _id: 2983,
                    en_label: 'Baby Gyms & Playmats',
                    ar_label: 'صالات اللعب للأطفال والبسط والمرايل',
                    parent_id: 2982,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2995,
                    en_label: 'Blocks',
                    ar_label: 'كتل',
                    parent_id: 2982,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3005,
                    en_label: 'Music & Sound',
                    ar_label: 'الموسيقى والصوت',
                    parent_id: 2982,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                ],
              },
              {
                _id: 2993,
                en_label: 'Games',
                ar_label: 'ألعاب',
                parent_id: 2931,
                enabled: true,
                countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                margin: 0,
                marginRange: [],
                level: 3,
                backToBackGroupCode: '',
                backToBackSubGroupCode: '',
                backToBackSubSubGroupCode: '',
                children: [
                  {
                    _id: 2994,
                    en_label: 'Board Games',
                    ar_label: 'ألعاب اللوح',
                    parent_id: 2993,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                ],
              },
              {
                _id: 2998,
                en_label: 'Building Toys',
                ar_label: 'ألعاب البناء',
                parent_id: 2931,
                enabled: true,
                countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                margin: 0,
                marginRange: [],
                level: 3,
                backToBackGroupCode: '',
                backToBackSubGroupCode: '',
                backToBackSubSubGroupCode: '',
                children: [
                  {
                    _id: 2999,
                    en_label: 'Die-Cast & Play Vehicles',
                    ar_label: 'مركبات القوالب الزنكية وألعاب المركبات',
                    parent_id: 2998,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3009,
                    en_label: 'Stacking Blocks & Games',
                    ar_label: 'كتل التراص وألعاب',
                    parent_id: 2998,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3023,
                    en_label: 'Pretend Play',
                    ar_label: 'لعب التظاهر',
                    parent_id: 2998,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3024,
                    en_label: 'Electronics For Kids',
                    ar_label: 'إلكترونيات للأطفال',
                    parent_id: 2998,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                ],
              },
              {
                _id: 3026,
                en_label: 'Figures & Statues',
                ar_label: 'تماثيل وتماثيل صغيرة',
                parent_id: 2931,
                enabled: true,
                countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                margin: 0,
                marginRange: [],
                level: 3,
                backToBackGroupCode: '',
                backToBackSubGroupCode: '',
                backToBackSubSubGroupCode: '',
                children: [
                  {
                    _id: 3027,
                    en_label: 'Action Figures',
                    ar_label: 'شخصيات حركية',
                    parent_id: 3026,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3028,
                    en_label: 'Playsets',
                    ar_label: 'أطقم اللعب',
                    parent_id: 3026,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3029,
                    en_label: 'Animal Figures',
                    ar_label: 'شكل حيوانات',
                    parent_id: 3026,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                ],
              },
              {
                _id: 3030,
                en_label: 'Kids Bikes Tricycles & Scooters',
                ar_label:
                  'دراجات للأطفال ودراجات ثلاثية العجلات وسكوترات الأطفال',
                parent_id: 2931,
                enabled: true,
                countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                margin: 0,
                marginRange: [],
                level: 3,
                backToBackGroupCode: '',
                backToBackSubGroupCode: '',
                backToBackSubSubGroupCode: '',
                children: [
                  {
                    _id: 3031,
                    en_label: 'Scooters & Equipment',
                    ar_label: 'سكوترات ومعدات',
                    parent_id: 3030,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3042,
                    en_label: 'Bike Accessories',
                    ar_label: 'ملحقات الدراجة',
                    parent_id: 3030,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                ],
              },
              {
                _id: 3046,
                en_label: 'Remote Control & Play Vehicles',
                ar_label: 'أجهزة التحكم عن بعد وسيارات اللعب',
                parent_id: 2931,
                enabled: true,
                countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                margin: 0,
                marginRange: [],
                level: 3,
                backToBackGroupCode: '',
                backToBackSubGroupCode: '',
                backToBackSubSubGroupCode: '',
                children: [
                  {
                    _id: 3047,
                    en_label: 'RC Vehicles & Parts',
                    ar_label: 'المركبات بالتحكم عن بعد وقطع الغيار',
                    parent_id: 3046,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                ],
              },
            ],
          },
          {
            _id: 3060,
            en_label: 'School',
            ar_label: 'المدرسة',
            parent_id: 2,
            enabled: false,
            countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
            margin: 0,
            marginRange: [],
            level: 2,
            backToBackGroupCode: '',
            backToBackSubGroupCode: '',
            backToBackSubSubGroupCode: '',
            children: [
              {
                _id: 2845,
                en_label: 'Stationery',
                ar_label: 'Stationery',
                parent_id: 3060,
                enabled: true,
                countries: ['uae'],
                margin: 1,
                marginRange: [],
                level: 3,
                backToBackGroupCode: '',
                backToBackSubGroupCode: '1',
                backToBackSubSubGroupCode: '',
                children: [
                  {
                    _id: 2846,
                    en_label: 'Back Pack & Bags',
                    ar_label: 'Back Pack & Bags',
                    parent_id: 2845,
                    enabled: true,
                    countries: ['uae'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2847,
                    en_label: 'School Stationery',
                    ar_label: 'قرطاسية المدرسة',
                    parent_id: 2845,
                    enabled: true,
                    countries: ['uae'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3048,
                    en_label: 'Office Stationery',
                    ar_label: 'مستلزمات مكتبية',
                    parent_id: 2845,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3087,
                    en_label: 'Pencil Cases',
                    ar_label: 'علب الأقلام',
                    parent_id: 2845,
                    enabled: true,
                    countries: ['uae'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3088,
                    en_label: 'Pencils & Sharpeners',
                    ar_label: 'أقلام رصاص ومبرايات',
                    parent_id: 2845,
                    enabled: true,
                    countries: ['uae'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3089,
                    en_label: 'Pens & Writing Instruments',
                    ar_label: 'أقلام وأدوات الكتابة',
                    parent_id: 2845,
                    enabled: true,
                    countries: ['uae'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3090,
                    en_label: 'Markers & Highlighters',
                    ar_label: 'أقلام تعليم وتحديد',
                    parent_id: 2845,
                    enabled: true,
                    countries: ['uae'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3091,
                    en_label: 'Glue & Adhesives',
                    ar_label: 'صمغ ولواصق',
                    parent_id: 2845,
                    enabled: true,
                    countries: ['uae'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3092,
                    en_label: 'Erasers & Correctors',
                    ar_label: 'ممحاة ومصححات',
                    parent_id: 2845,
                    enabled: true,
                    countries: ['uae'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3093,
                    en_label: 'Rulers & Geometry Sets',
                    ar_label: 'مساطر وأدوات الهندسة',
                    parent_id: 2845,
                    enabled: true,
                    countries: ['uae'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3094,
                    en_label: 'Scissors & Cutting Tools',
                    ar_label: 'مقصات وأدوات القطع',
                    parent_id: 2845,
                    enabled: true,
                    countries: ['uae'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3095,
                    en_label: 'Notebooks & Pads',
                    ar_label: 'دفاتر ومفكرات',
                    parent_id: 2845,
                    enabled: true,
                    countries: ['uae'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3096,
                    en_label: 'Folders & Organization',
                    ar_label: 'ملفات وتنظيم',
                    parent_id: 2845,
                    enabled: true,
                    countries: ['uae'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3097,
                    en_label: 'Paper Supplies',
                    ar_label: 'مستلزمات الورق',
                    parent_id: 2845,
                    enabled: true,
                    countries: ['uae'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3098,
                    en_label: 'Refills & Extras',
                    ar_label: 'عبوات وإضافات',
                    parent_id: 2845,
                    enabled: true,
                    countries: ['uae'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                ],
              },
              {
                _id: 3061,
                en_label: 'School Bags',
                ar_label: 'شنط المدرسة',
                parent_id: 3060,
                enabled: true,
                countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                margin: 0,
                marginRange: [],
                level: 3,
                backToBackGroupCode: '',
                backToBackSubGroupCode: '',
                backToBackSubSubGroupCode: '',
                children: [
                  {
                    _id: 3062,
                    en_label: 'Trolley Bags',
                    ar_label: 'حقائب العربات',
                    parent_id: 3061,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3063,
                    en_label: 'Soft Bags',
                    ar_label: 'حقائب ناعمة',
                    parent_id: 3061,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3064,
                    en_label: 'Backpacks',
                    ar_label: 'حقائب الظهر',
                    parent_id: 3061,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                ],
              },
              {
                _id: 3065,
                en_label: 'Lunch Essentials',
                ar_label: 'لوازم الغداء',
                parent_id: 3060,
                enabled: true,
                countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                margin: 0,
                marginRange: [],
                level: 3,
                backToBackGroupCode: '',
                backToBackSubGroupCode: '',
                backToBackSubSubGroupCode: '',
                children: [
                  {
                    _id: 2848,
                    en_label: 'Lunch Boxes',
                    ar_label: 'صناديق الغداء',
                    parent_id: 3065,
                    enabled: true,
                    countries: ['uae', 'bah', 'omn'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '0',
                  },
                  {
                    _id: 3066,
                    en_label: 'Food Jars & Snack Boxes',
                    ar_label: 'برطمانات الطعام وصناديق الوجبات الخفيفة',
                    parent_id: 3065,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3067,
                    en_label: 'Utensils & Accessories',
                    ar_label: 'الأواني والإكسسوارات',
                    parent_id: 3065,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3068,
                    en_label: 'Lunch Bags',
                    ar_label: 'حقائب الغداء',
                    parent_id: 3065,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3069,
                    en_label: 'Lunch Kit',
                    ar_label: 'مجموعة الغداء',
                    parent_id: 3065,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                ],
              },
              {
                _id: 3070,
                en_label: 'Water Bottles',
                ar_label: 'زجاجات المياه',
                parent_id: 3060,
                enabled: true,
                countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                margin: 0,
                marginRange: [],
                level: 3,
                backToBackGroupCode: '',
                backToBackSubGroupCode: '',
                backToBackSubSubGroupCode: '',
                children: [
                  {
                    _id: 3071,
                    en_label: 'Regular Water Bottle',
                    ar_label: 'زجاجة مياه عادية',
                    parent_id: 3070,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3072,
                    en_label: 'Stainless Steel Bottle',
                    ar_label: 'زجاجة من الفولاذ المقاوم للصدأ',
                    parent_id: 3070,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                ],
              },
              {
                _id: 3073,
                en_label: 'Labels & Personalized',
                ar_label: 'الملصقات والملصقات الشخصية',
                parent_id: 3060,
                enabled: true,
                countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                margin: 0,
                marginRange: [],
                level: 3,
                backToBackGroupCode: '',
                backToBackSubGroupCode: '',
                backToBackSubSubGroupCode: '',
                children: [
                  {
                    _id: 3074,
                    en_label: 'Iron-on Labels',
                    ar_label: 'الملصقات التي يتم كيها',
                    parent_id: 3073,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3075,
                    en_label: 'Labelling Machines & Stamps',
                    ar_label: 'آلات وضع العلامات والطوابع',
                    parent_id: 3073,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3076,
                    en_label: 'Sticker Labels',
                    ar_label: 'الملصقات',
                    parent_id: 3073,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3077,
                    en_label: 'Refills & Accessories',
                    ar_label: 'عبوات إعادة التعبئة والإكسسوارات',
                    parent_id: 3073,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                ],
              },
              {
                _id: 3078,
                en_label: 'Toys and Games',
                ar_label: 'الألعاب والدمى',
                parent_id: 3060,
                enabled: true,
                countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                margin: 0,
                marginRange: [],
                level: 3,
                backToBackGroupCode: '',
                backToBackSubGroupCode: '',
                backToBackSubSubGroupCode: '',
                children: [
                  {
                    _id: 3040,
                    en_label: 'Dolls & Accessories',
                    ar_label: 'الدمى والإكسسوارات',
                    parent_id: 3078,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '0',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2947,
                    en_label: 'Educational Toys',
                    ar_label: 'ألعاب تعليمية',
                    parent_id: 3078,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '0',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 2949,
                    en_label: 'Arts & Crafts',
                    ar_label: 'الفنون والحرف اليدوية',
                    parent_id: 3078,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: -2,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '0',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3079,
                    en_label: 'Board & Card Games',
                    ar_label: 'ألعاب الطاولة والورق',
                    parent_id: 3078,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3080,
                    en_label: 'Puzzles',
                    ar_label: 'ألعاب تجميع القطع',
                    parent_id: 3078,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3118,
                    en_label: 'Ride on Toys',
                    ar_label: 'ألعاب الركوب',
                    parent_id: 3078,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                ],
              },
              {
                _id: 3081,
                en_label: 'Mothers Choice',
                ar_label: 'اختيار الأم',
                parent_id: 3060,
                enabled: true,
                countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                margin: 0,
                marginRange: [],
                level: 3,
                backToBackGroupCode: '',
                backToBackSubGroupCode: '',
                backToBackSubSubGroupCode: '',
                children: [
                  {
                    _id: 3082,
                    en_label: 'Baby Furniture',
                    ar_label: 'أثاث الأطفال',
                    parent_id: 3081,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3083,
                    en_label: 'Strollers & Prams',
                    ar_label: 'عربات الأطفال',
                    parent_id: 3081,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3084,
                    en_label: 'Baby Car Seats',
                    ar_label: 'مقاعد سيارات الأطفال',
                    parent_id: 3081,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3085,
                    en_label: 'Potty Training',
                    ar_label: 'أدوات تدريب الأطفال على استخدام المرحاض',
                    parent_id: 3081,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                  {
                    _id: 3086,
                    en_label: 'Bags',
                    ar_label: 'الحقائب',
                    parent_id: 3081,
                    enabled: true,
                    countries: ['uae', 'qat', 'bah', 'omn', 'ksa', 'kwt'],
                    margin: 0,
                    marginRange: [],
                    level: 4,
                    backToBackGroupCode: '',
                    backToBackSubGroupCode: '',
                    backToBackSubSubGroupCode: '',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];
const CustomValueEditor = (props) => {
  const { handleOnChange, schema, path, rule, fieldData } = props;

  const treeData = useMemo(() => {
    if (fieldData.treeData) {
      return JSON.parse(fieldData.treeData);
    } else [];
  }, [fieldData]);

  if (props.field === 'item.collections') {
    return (
      <Collections
        handleCollection={(value) => {
          handleOnChange(value?.ids);
          schema?.dispatchQuery(
            update(schema?.getQuery(), 'collections', value?.collections, path)
          );
        }}
        ids={rule?.value}
        collectionsProp={rule?.collections}
      />
    );
  } else if (props.field === 'item.categories') {
    return (
      <CheckTreePicker
        defaultExpandAll
        data={treeData}
        onChange={(values) => {
          console.log(values);
          handleOnChange(values);
        }}
        name="treePickerValue"
        labelKey="en_label"
        valueKey="_id"
        popupClassName="zIndex-10000"
        className="w-100 "
        cascade={false}
        value={(rule?.value?.map && rule?.value?.map((v) => +v)) || []}
      />
    );
  }

  const MemoisedValueEditors = useMemo(() => {
    const type = ['multiselect'].includes(props.fieldData?.fieldType)
      ? ['in', 'notIn'].includes(props.operator)
        ? 'multiselect'
        : 'select'
      : props.type;
    return <ValueEditor {...props} type={type} />;
  }, [props.fieldData?.fieldType, props.operator, props.type, props.value]);
  return MemoisedValueEditors;
};

export default CustomValueEditor;
