import React from 'react';
import { Table } from 'reactstrap';
import PromotionReportConfig from '../PromotionReportConfig';

const labels = {
  '24Hours': 'Last 24 Hours',
  '48Hours': 'Last 48 Hours',
  '7Days': 'Last 7 Days',
  '14Days': 'Last 14 Days',
  '30Days': 'Last 30 Days',
  weekly: 'Weekly',
  monthly: 'Monthly',
  yearly: 'Yearly',
  allTime: 'All Time',
  quarterly: 'Quarterly',
  promotionDuration: 'Promotion Duration',
};

const PromotionAnalysis = ({
  promotionId,
  promotionDetail,
  fetchPromotionDetail,
}) => {
  const { reports } = promotionDetail;

  return (
    <div className="container-fluid p-4 ">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h5>Promotion Reports</h5>
        <PromotionReportConfig
          promotionId={promotionId}
          promotionDetail={promotionDetail}
          fetchPromotionDetail={fetchPromotionDetail}
        />
      </div>

      {reports && (
        <Table bordered hover responsive className="table-striped">
          <thead className="table-primary text-white">
            <tr>
              <th>Label</th>
              <th className="text-end">Spends (AED)</th>
              <th className="text-end">Revenue (AED)</th>
              <th className="text-center">No. of Transactions</th>
              <th className="text-center">ROI (%)</th>
              <th>Rewards</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(reports).map(([key, value]) =>
              value?.noOfTransactions ? (
                <tr key={key}>
                  <td>{labels[key]}</td>
                  <td className="text-end">
                    {value?.spends ? `${value.spends.toFixed(2)} AED` : '-'}
                  </td>
                  <td className="text-end">
                    {value?.revenue ? `${value.revenue.toFixed(2)} AED` : '-'}
                  </td>
                  <td className="text-center">
                    {value?.noOfTransactions || '-'}
                  </td>
                  <td
                    className={`text-center ${
                      value?.roi > 0
                        ? 'text-success fw-bold'
                        : value?.roi < 0
                        ? 'text-danger fw-bold'
                        : 'text-muted'
                    }`}
                  >
                    {value?.roi || '-'}%
                  </td>
                  <td>
                    {Object.keys(reports?.rewards || {}).length > 0
                      ? Object.entries(reports.rewards).map(([rKey, reward]) =>
                          reward[key] ? (
                            <div key={rKey} className="border rounded p-2 mb-2">
                              <strong>{rKey}:</strong>{' '}
                              {reward[key]?.pointsIssued} Pts,{' '}
                              {reward[key]?.noOfTransactions} Transactions
                            </div>
                          ) : null
                        )
                      : '-'}
                  </td>
                </tr>
              ) : (
                Object.entries(value).map(([key1, value1]) => (
                  <React.Fragment key={key1}>
                    <tr className="table-secondary">
                      <td colSpan="6" className="text-uppercase fw-bold">
                        {`${key} - ${key1}`}
                      </td>
                    </tr>
                    {Object.entries(value1).map(([key2, subValue]) => (
                      <tr key={key2}>
                        <td>{labels[key2]}</td>
                        <td className="text-end">
                          {subValue?.spends
                            ? `${subValue.spends.toFixed(2)} AED`
                            : '-'}
                        </td>
                        <td className="text-end">
                          {subValue?.revenue
                            ? `${subValue.revenue.toFixed(2)} AED`
                            : '-'}
                        </td>
                        <td className="text-center">
                          {subValue?.noOfTransactions || '-'}
                        </td>
                        <td
                          className={`text-center ${
                            subValue?.roi > 0
                              ? 'text-success fw-bold'
                              : subValue?.roi < 0
                              ? 'text-danger fw-bold'
                              : 'text-muted'
                          }`}
                        >
                          {subValue?.roi || '-'}%
                        </td>
                        <td>
                          {subValue?.pointsIssued ? (
                            <div className="border rounded p-2">
                              <strong>Points:</strong> {subValue.pointsIssued}{' '}
                              Pts
                            </div>
                          ) : (
                            '-'
                          )}
                        </td>
                      </tr>
                    ))}
                  </React.Fragment>
                ))
              )
            )}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default PromotionAnalysis;
