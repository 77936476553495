import React from 'react';
import { Input, Button } from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import CustomPagination from '../../Promotion/CustomPagination';
import { discountTypes } from './constants';
import debounce from 'lodash.debounce';

const CouponList = ({
  coupons,
  updateCoupon,
  totalPages,
  pageOffset,
  setPageOffset,
  pageSize,
  createOrUpdateCouponFunc,
  deleteCouponByIdFunc,
  onCreateClick,
  getCouponsFunc,
}) => {
  const handleSearch = (keyword = '') => {
    try {
      getCouponsFunc({
        offset: 0,
        size: pageSize,
        searchKeyword: keyword,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const debouncedSearchHandler = debounce(handleSearch, 400);

  return (
    <>
      <div className="table-responsive table-responsive bg-light border rounded border-bottom-0">
        <div className="p-3 d-flex gap-3 justify-content-end border-bottom">
          <Button
            size="sm"
            outline
            color="secondary"
            className="px-3"
            onClick={onCreateClick}
          >
            <i class="ri-coupon-2-line label-icon align-middle fs-16 me-2"></i>
            Create Coupon Code
          </Button>
          <Button size="sm" outline color="secondary" className="px-3">
            <i class="ri-download-2-line label-icon align-middle fs-16 me-2"></i>
            Download
          </Button>
          <div
            className="search-box d-inline-block w-75"
            style={{ maxWidth: 260 }}
          >
            <Input
              className="form-control search"
              placeholder="Search by Code"
              type="text"
              onChange={(e) => {
                debouncedSearchHandler(e?.target?.value);
              }}
            />
            <i className="bx bx-search-alt search-icon"></i>
          </div>
        </div>
        <table className="table table-nowrap table-centered align-middle mb-0">
          <thead className="bg-light text-muted">
            <tr>
              <th className="text-center"></th>
              <th>Coupon Code</th>
              <th>Start Date</th>
              <th>End date</th>
              <th>Discount Type</th>
              <th>Redeemable QTY</th>
              <th>Remaining QTY</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {(coupons || [])?.map((coupon, index) => (
              <tr key={index}>
                <td>
                  <div className="d-flex align-items-center justify-content-center">
                    <h5 className="m-0 fs-13 fw-normal">
                      {(pageOffset - 1) * pageSize + (index + 1)}
                    </h5>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <h5 className="m-0 fs-13 fw-normal">{coupon?.code}</h5>
                  </div>
                </td>
                <td>
                  <Flatpickr
                    className="form-control"
                    style={{ maxWidth: 180 }}
                    value={coupon?.startsAt}
                    options={{
                      enableTime: true,
                      // dateFormat: 'Y-m-d H:i',
                      dateFormat: 'Y-m-d h:i K',
                      time_24hr: false,
                    }}
                    onChange={(values) => {
                      updateCoupon(index, {
                        ...coupon,
                        startsAt: values?.[0],
                        isEdited: true,
                      });
                    }}
                    disabled={!coupon?.isEdited}
                  />
                </td>
                <td>
                  <Flatpickr
                    className="form-control"
                    style={{ maxWidth: 180 }}
                    value={coupon?.expiresAt}
                    options={{
                      enableTime: true,
                      // dateFormat: 'Y-m-d H:i',
                      dateFormat: 'Y-m-d h:i K',
                      time_24hr: false,
                    }}
                    onChange={(values) => {
                      updateCoupon(index, {
                        ...coupon,
                        expiresAt: values?.[0],
                        isEdited: true,
                      });
                    }}
                    disabled={!coupon?.isEdited}
                  />
                </td>
                <td>
                  <Input
                    style={{ maxWidth: 120 }}
                    type="select"
                    id="discountType"
                    name="discountType"
                    value={coupon?.discountType}
                    min={1}
                    onChange={(e) => {
                      updateCoupon(index, {
                        ...coupon,
                        text: e?.target?.value,
                        isEdited: true,
                      });
                    }}
                    disabled={!coupon?.isEdited}
                  >
                    {discountTypes?.map((item) => (
                      <option key={item?.value} value={item?.value}>
                        {item?.label}
                      </option>
                    ))}
                  </Input>
                </td>
                <td>
                  <Input
                    style={{ maxWidth: 120 }}
                    type="number"
                    id="redeemableQuantity"
                    name="redeemableQuantity"
                    value={coupon?.redeemableQuantity}
                    onChange={(e) => {
                      updateCoupon(index, {
                        ...coupon,
                        redeemableQuantity: e?.target?.value,
                        isEdited: true,
                      });
                    }}
                    disabled={!coupon?.isEdited}
                  />
                </td>

                <td>
                  <h5 className="m-0 fs-13 fw-normal">
                    {coupon?.redeemableQuantity -
                      (coupon?.redeemedQuantityOnHold +
                        coupon?.redeemedQuantityConfirmed)}
                  </h5>
                </td>
                <td>
                  <div
                    style={{ maxWidth: 160 }}
                    className="form-check form-switch form-switch-secondary d-flex gap-2 align-items-center"
                  >
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="customSwitchsizesm"
                      checked={coupon?.isActive}
                      onChange={(event) => {
                        createOrUpdateCouponFunc(
                          [
                            {
                              ...coupon,
                              isActive: event?.target?.checked,
                            },
                          ],
                          pageOffset
                        );
                      }}
                    />
                    <label
                      className={`fs-12 my-1 fw-normal ${
                        coupon?.isActive ? 'text-success' : 'text-danger'
                      } `}
                      htmlFor="customSwitchsizesm"
                    >
                      {coupon?.isActive ? 'Enabled' : 'Disabled'}
                    </label>
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center gap-2">
                    {coupon?.isEdited ? (
                      <Button
                        type="submit"
                        className="btn btn-label btn-success btn-sm fw-medium fs-12"
                      >
                        <i class="mdi mdi-clipboard-check-multiple-outline label-icon align-middle fs-16"></i>
                        UPDATE
                      </Button>
                    ) : (
                      <>
                        <Button
                          type="button"
                          size="lg"
                          outline
                          color="danger"
                          className="btn-ghost-danger shadow-none px-2 py-1"
                          onClick={() => deleteCouponByIdFunc(coupon?._id)}
                        >
                          <i class="ri-delete-bin-line align-middle"></i>
                        </Button>
                        <Button
                          type="button"
                          size="lg"
                          outline
                          color="secondary"
                          className="btn-ghost-secondary shadow-none px-2 py-1"
                          onClick={() => {
                            updateCoupon(index, {
                              ...coupon,
                              isEdited: true,
                            });
                          }}
                        >
                          <i class="ri-pencil-line align-middle"></i>
                        </Button>
                      </>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <CustomPagination
        pageOffset={pageOffset}
        totalPages={totalPages}
        setPageOffset={setPageOffset}
      />
    </>
  );
};

export default CouponList;
