import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  fields: [],
  errorMsg: '',
  fieldsMap: {},
};

const promotionSlice = createSlice({
  name: 'promotion',
  initialState,
  reducers: {
    setFields(state, action) {
      state.fields = action.payload;
      state.itemFields = action.payload.filter((f) => f.fieldGroup === 'item');
    },
    setFieldsMap(state, action) {
      state.fieldsMap = action.payload;
    },
    setComboForPreview(state, action) {
      state.selectedComboForPreview = action.payload;
    },
    setMemberConfigs(state, action) {
      state.memberConfigs = action.payload;
    },

    setMemberConfigsFields(state, action) {
      state.memberConfigFields = action.payload;

      const fieldsMap = {};
      action.payload.forEach((conv) => {
        fieldsMap[conv.name] = conv;
      });
      console.log('converted member fields map', fieldsMap);
      state.memberConfigFieldsMap = fieldsMap;
    },
  },
});

export const {
  setFields,
  setFieldsMap,
  setComboForPreview,
  setMemberConfigs,
  setMemberConfigsFields,
} = promotionSlice.actions;
export default promotionSlice.reducer;
