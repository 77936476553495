import React, { useEffect, useState } from 'react';
import {
  Button,
  FormGroup,
  FormText,
  Col,
  Row,
  Label,
  Form,
  CardBody,
  Input,
  FormFeedback,
} from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';

import { alertError, alertSuccess } from '../../../helpers/errorHandling';
import { If } from 'react-if';
import { createPromotionLauncher } from '../../../actions/promotion/launcher';
import InnerLoader from '../../../components/Common/InnerLoader';

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const PromotionLauncherForm = ({
  promotionId,
  launcherDetails = {},
  updateHandler = () => {},
  onCancel = () => {},
  isNew = false,
  budgeting = [],
}) => {
  const launcherSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    startDate: Yup.date().required('Start Date is required'),
    endDate: Yup.date(),
    neverExpires: Yup.boolean(),
    months: Yup.array().when('isSpecificMonthsEnabled', {
      is: true,
      then: Yup.array().min(1, 'At least one month must be selected'),
      otherwise: Yup.array().notRequired(),
    }),
    weekDays: Yup.array().when('isSpecificWeekDaysEnabled', {
      is: true,
      then: Yup.array().min(1, 'At least one day must be selected'),
      otherwise: Yup.array().notRequired(),
    }),
    dates: Yup.array().when('isSpecificDatesEnabled', {
      is: true,
      then: Yup.array().min(1, 'At least one date must be selected'),
      otherwise: Yup.array().notRequired(),
    }),
    slots: Yup.array().when('isSpecificSlotsEnabled', {
      is: true,
      then: Yup.array().min(1, 'At least one slot must be specified'),
      otherwise: Yup.array().notRequired(),
    }),
  });

  const initialValues = {
    name: launcherDetails?.name || '',
    startDate: launcherDetails?.startDate
      ? new Date(launcherDetails?.startDate)
      : undefined,
    endDate: launcherDetails?.endDate
      ? new Date(launcherDetails?.endDate)
      : undefined,
    neverExpires: launcherDetails?.neverExpires || false,
    isSpecificMonthsEnabled: launcherDetails?.isSpecificMonthsEnabled || false,
    months: launcherDetails?.months || [],
    isSpecificWeekDaysEnabled:
      launcherDetails?.isSpecificWeekDaysEnabled || false,
    weekDays: launcherDetails?.weekDays || [],
    isSpecificDatesEnabled: launcherDetails?.isSpecificDatesEnabled || false,
    dates: launcherDetails?.dates || [],
    isSpecificSlotsEnabled: launcherDetails?.isSpecificSlotsEnabled || false,
    slots: launcherDetails?.slots || [],
  };

  const formik = useFormik({
    initialValues,
    validationSchema: launcherSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        const inputData = {
          _id: launcherDetails?._id || null,
          promotionId,
          budgeting: isNew ? budgeting : launcherDetails?.budgeting,
          ...values,
        };

        const { success } = await createPromotionLauncher(inputData);
        if (success) {
          alertSuccess(
            isNew
              ? 'Launcher created successfully.'
              : 'Launcher updated successfully.'
          );
          updateHandler();
        } else {
          alertError('Failed! Please try again later.');
        }
      } catch (error) {
        console.error(error);
        alertError('Failed! Please try again later.');
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (launcherDetails?.name) {
      formik?.setValues({
        name: launcherDetails?.name || '',
        startDate: launcherDetails?.startDate
          ? new Date(launcherDetails?.startDate)
          : undefined,
        endDate: launcherDetails?.endDate
          ? new Date(launcherDetails?.endDate)
          : undefined,
        neverExpires: launcherDetails?.neverExpires || false,
        isSpecificMonthsEnabled:
          launcherDetails?.isSpecificMonthsEnabled || false,
        months: launcherDetails?.months || [],
        isSpecificWeekDaysEnabled:
          launcherDetails?.isSpecificWeekDaysEnabled || false,
        weekDays: launcherDetails?.weekDays || [],
        isSpecificDatesEnabled:
          launcherDetails?.isSpecificDatesEnabled || false,
        dates: launcherDetails?.dates || [],
        isSpecificSlotsEnabled:
          launcherDetails?.isSpecificSlotsEnabled || false,
        slots: launcherDetails?.slots || [],
      });
    }
  }, [launcherDetails]);

  const {
    values,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    errors,
  } = formik;

  return (
    <CardBody>
      <FormikProvider value={formik}>
        <Form autoComplete="off" onSubmit={handleSubmit}>
          <Row className="w-75">
            <Col>
              <FormGroup>
                <Label for="name">Launcher Name</Label>
                <Input
                  {...getFieldProps('name')}
                  id="name"
                  placeholder="Enter launcher name"
                  invalid={touched?.name && Boolean(errors?.name)}
                />
                <FormFeedback>{touched?.name && errors?.name}</FormFeedback>
              </FormGroup>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="startDate">Start Date</Label>
                    <Flatpickr
                      className="form-control"
                      id="startDate"
                      value={values?.startDate || ''}
                      onChange={(date) => setFieldValue('startDate', date[0])}
                      options={{
                        enableTime: true,
                        dateFormat: 'Y-m-d h:i K',
                        time_24hr: false,
                      }}
                    />
                    {touched?.startDate && errors?.startDate && (
                      <FormText color="danger">{errors?.startDate}</FormText>
                    )}
                  </FormGroup>
                </Col>
                <Col lg={1} className="d-flex align-items-center">
                  <span>&nbsp;→&nbsp;</span>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="endDate">End Date</Label>
                    <If condition={!values?.neverExpires}>
                      <Flatpickr
                        className="form-control"
                        id="endDate"
                        value={values?.endDate || ''}
                        onChange={(date) => setFieldValue('endDate', date[0])}
                        options={{
                          enableTime: true,
                          dateFormat: 'Y-m-d h:i K',
                          time_24hr: false,
                        }}
                      />
                    </If>
                    <div className="form-check form-switch form-switch-custom form-switch-dark mb-2 mt-2 p-0">
                      <Label
                        className="form-check-label ms-2"
                        htmlFor="neverExpires"
                      >
                        Never Expire
                      </Label>
                      <Input
                        className="form-check-input m-0"
                        type="checkbox"
                        role="switch"
                        id="neverExpires"
                        checked={values?.neverExpires === true}
                        onChange={() => {
                          setFieldValue('neverExpires', !values?.neverExpires);
                        }}
                      />
                    </div>
                  </FormGroup>
                </Col>
                <Col lg={12}>
                  <FormGroup check inline className="">
                    <Input
                      {...getFieldProps('isSpecificMonthsEnabled')}
                      type="checkbox"
                      checked={values?.isSpecificMonthsEnabled}
                      onChange={() => {
                        setFieldValue(
                          'isSpecificMonthsEnabled',
                          !values?.isSpecificMonthsEnabled
                        );
                      }}
                    />
                    <Label>
                      Are specific Months required for this promotion?
                    </Label>
                  </FormGroup>
                  {values?.isSpecificMonthsEnabled && (
                    <div className="d-flex flex-row gap-2 w-75 mb-3">
                      {months?.map((item, index) => (
                        <div
                          key={index}
                          className={`btn btn-sm d-flex flex-row align-items-center gap-1 ${
                            values?.months?.includes(item)
                              ? 'btn-primary'
                              : 'btn-light'
                          }`}
                          onClick={() => {
                            const newMonths = values?.months?.includes(item)
                              ? values?.months?.filter(
                                  (month) => month !== item
                                )
                              : [...values?.months, item];
                            setFieldValue('months', newMonths);
                          }}
                        >
                          {values?.months?.includes(item) ? (
                            <i className="bx bx-check fs-16"></i>
                          ) : null}
                          {item}
                        </div>
                      ))}
                    </div>
                  )}
                </Col>
                <Col lg={12}>
                  <FormGroup check inline className="">
                    <Input
                      {...getFieldProps('isSpecificWeekDaysEnabled')}
                      type="checkbox"
                      checked={values?.isSpecificWeekDaysEnabled}
                      onChange={() => {
                        setFieldValue(
                          'isSpecificWeekDaysEnabled',
                          !values?.isSpecificWeekDaysEnabled
                        );
                      }}
                    />
                    <Label>
                      Are specific Days required for this promotion?
                    </Label>
                  </FormGroup>
                  {values?.isSpecificWeekDaysEnabled && (
                    <div className="d-flex flex-row gap-2 w-75 mb-3">
                      {weekDays?.map((item, index) => (
                        <div
                          key={index}
                          className={`btn btn-sm d-flex flex-row align-items-center gap-1 ${
                            values?.weekDays?.includes(item)
                              ? 'btn-primary'
                              : 'btn-light'
                          }`}
                          onClick={() => {
                            const newDays = values?.weekDays?.includes(item)
                              ? values?.weekDays?.filter((day) => day !== item)
                              : [...values?.weekDays, item];
                            setFieldValue('weekDays', newDays);
                          }}
                        >
                          {values?.weekDays?.includes(item) ? (
                            <i className="bx bx-check fs-16"></i>
                          ) : null}{' '}
                          {item}
                        </div>
                      ))}
                    </div>
                  )}
                </Col>
                <Col lg={12}>
                  <FormGroup check inline className="">
                    <Input
                      {...getFieldProps('isSpecificDatesEnabled')}
                      type="checkbox"
                      checked={values?.isSpecificDatesEnabled}
                      onChange={() => {
                        setFieldValue(
                          'isSpecificDatesEnabled',
                          !values?.isSpecificDatesEnabled
                        );
                      }}
                    />
                    <Label>
                      Are specific Dates required for this promotion?
                    </Label>
                  </FormGroup>
                  {values?.isSpecificDatesEnabled && (
                    <div className="d-flex flex-row flex-wrap gap-2 w-75 mb-3">
                      {[...Array(31)]?.map((_, index) => {
                        const date = index + 1;
                        return (
                          <div
                            key={date}
                            className={`btn btn-sm d-flex flex-row align-items-center gap-1 ${
                              values?.dates?.includes(date)
                                ? 'btn-primary'
                                : 'btn-light'
                            }`}
                            onClick={() => {
                              const newDates = values?.dates?.includes(date)
                                ? values?.dates?.filter((d) => d !== date)
                                : [...values?.dates, date];
                              setFieldValue('dates', newDates);
                            }}
                          >
                            {values.dates.includes(date) ? (
                              <i className="bx bx-check fs-16"></i>
                            ) : null}{' '}
                            {date}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </Col>
                {/* Slots selection logic */}
                <Col lg={12}>
                  <FormGroup check inline className="">
                    <Input
                      {...getFieldProps('isSpecificSlotsEnabled')}
                      type="checkbox"
                      checked={values?.isSpecificSlotsEnabled}
                      onChange={() => {
                        setFieldValue(
                          'isSpecificSlotsEnabled',
                          !values?.isSpecificSlotsEnabled
                        );
                      }}
                    />
                    <Label>
                      Are specific Slots required for this promotion?
                    </Label>
                  </FormGroup>
                  {values?.isSpecificSlotsEnabled && (
                    <div>
                      {/* Render existing slots */}
                      {values?.slots?.map((slot, index) => (
                        <div key={index}>
                          <FormGroup>
                            <Label>Slot {index + 1}</Label>
                            <div className="d-flex gap-2">
                              <Flatpickr
                                className="form-control"
                                value={slot?.startDate || ''}
                                onChange={(date) => {
                                  const newSlots = [...values?.slots];
                                  newSlots[index].startDate = date[0];
                                  setFieldValue('slots', newSlots);
                                }}
                                placeholder="Start Date"
                                options={{
                                  enableTime: true,
                                  dateFormat: 'Y-m-d h:i K',
                                  time_24hr: false,
                                }}
                              />
                              <Flatpickr
                                className="form-control"
                                value={slot?.endDate || ''}
                                onChange={(date) => {
                                  const newSlots = [...values?.slots];
                                  newSlots[index].endDate = date[0];
                                  setFieldValue('slots', newSlots);
                                }}
                                placeholder="End Date"
                                options={{
                                  enableTime: true,
                                  dateFormat: 'Y-m-d h:i K',
                                  time_24hr: false,
                                }}
                              />
                            </div>
                          </FormGroup>
                        </div>
                      ))}

                      {/* Button to add new slot */}
                      <div className="mt-3">
                        <Button
                          type="button"
                          size="sm"
                          color="secondary"
                          onClick={() => {
                            const newSlot = { startDate: '', endDate: '' };
                            const newSlots = [...values?.slots, newSlot];
                            setFieldValue('slots', newSlots);
                          }}
                        >
                          Add New Slot
                        </Button>
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
          <hr />
          <div className="d-flex gap-2 justify-content-end w-100">
            <Button outline color="danger" size="md" onClick={onCancel}>
              Cancel
            </Button>

            <Button
              loading={false}
              type="submit"
              color="primary"
              size="md"
              className="bg-gradient px-5"
            >
              Save
            </Button>
          </div>
        </Form>
      </FormikProvider>
    </CardBody>
  );
};

export default PromotionLauncherForm;
