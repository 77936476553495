import { useCallback, useEffect, useState } from 'react';
import {
  defaultValidator,
  QueryBuilder,
  Rule,
  update,
  RuleGroup,
  RuleComponents,
} from 'react-querybuilder';
import toArray from 'lodash.toarray';
import Select from 'react-select';

import 'react-querybuilder/dist/query-builder.css';
import './queryBuilder.scss';
// import './style.scss';
import { getAllFields } from '../../../actions/promotion/fields';
import { QueryBuilderDnD } from '@react-querybuilder/dnd';
import * as ReactDnD from 'react-dnd';
import * as ReactDndHtml5Backend from 'react-dnd-html5-backend';
import PropTypes from 'prop-types';
import {
  FormGroup,
  Label,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Input,
} from 'reactstrap';
import ErrorMessageCaptureModal from '../../../components/Common/ErrorMessageCaptureModal';
import { QueryBuilderBootstrap } from '@react-querybuilder/bootstrap';
import CustomValueEditor from './CustomValueEditor';
import { Else, If, Then } from 'react-if';
import { useSelector } from 'react-redux';
import RuleText from './RuleText';
import CustomCombinator from './CustomCombinator';
import RewardTierRuleGroup from '../../Reward/RewardTypes/RewardTierRuleGroup';
import { isEmpty } from 'lodash';
import GlobalRuleGroup from './GlobalRuleGroupModal';
import GlobalRuleGroupList from './GlobalRuleGroupListModal';

export const validator = (r) => !!r.value;

const RuleGroupRender = (props) => {
  // console.log('propspropsprops: RuleGroupRender', props);
  const ruleGroup = { ...props.ruleGroup };
  const { globalRules } = useSelector((state) => state.dashboard || {});
  const rules = props.ruleGroup?.rules.map((rule) => {
    if (rule.type === 'globalGroup') {
      let foundRuleGroup = globalRules.find(
        (r) => r._id === rule.globalGroupId
      );
      foundRuleGroup = {
        ...foundRuleGroup,
        ruleGroup: { ...foundRuleGroup.ruleGroup },
      };
      foundRuleGroup.ruleGroup.isGlobalGroup = true;

      return foundRuleGroup.ruleGroup;
    }
    return rule;
  });
  ruleGroup.rules = rules;

  return (
    <>
      <RuleGroup
        {...props}
        ruleGroup={ruleGroup}
        isGlobalGroup={ruleGroup.isGlobalGroup}
      ></RuleGroup>
    </>
  );
};

const RuleRenderer = (props) => {
  const [isEdit, setIsEdit] = useState(false);
  const { fieldsMap, memberConfigFieldsMap } = useSelector(
    (state) => state.promotions || {}
  );
  console.log('props', props);
  let rule = props.rule;

  return (
    <div
      wrap
      gap="small"
      className="mb-0 trigger-hover ruleGroup p-0 border-0 bg-transparent abcd "
    >
      <If condition={isEdit}>
        <Then>
          <RuleText
            operation={rule}
            onEditToggle={() => {
              setIsEdit(!isEdit);
            }}
            fieldsMap={fieldsMap}
            memberConfigFieldsMap={memberConfigFieldsMap}
          />
          <Modal isOpen={isEdit} toggle={() => setIsEdit(!isEdit)} size="lg">
            <ModalHeader
              className="modal-title pb-3 bg-light"
              toggle={() => setIsEdit(!isEdit)}
            >
              Create / Edit Rule
            </ModalHeader>
            <ModalBody>
              <div className="rulePopup trigger-hover">
                <Rule {...props} rule={rule} />
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={() => setIsEdit(!isEdit)}>
                Close
              </Button>
            </ModalFooter>
          </Modal>
        </Then>
        <Else>
          <RuleText
            operation={props.rule}
            onEditToggle={() => {
              setIsEdit(!isEdit);
            }}
            fieldsMap={fieldsMap}
            memberConfigFieldsMap={memberConfigFieldsMap}
          />
        </Else>
      </If>
      {/* <Row>
        <Col xl={12} lg={12} md={12} sm={12} xs={12} marginTop={1}>
          <Input
            value={props.rule?.errorMessage}
            onChange={onChange}
            required
          />
        </Col>
      </Row> */}
    </div>
  );
};

const initialQuery = { combinator: 'and', rules: [] };

export const QueryBuilderApp = (props) => {
  const {
    updateQuery = () => {},
    data,
    fieldGroups = [],
    isUseRewardTierRuleGroup = false,
    isGlobalGroup = false,
  } = props;
  const [query, setQuery] = useState(data || initialQuery);

  const { fields, memberConfigFields: memberFields = [] } = useSelector(
    (state) => state.promotions || {}
  );
  const [selectedGroup, setSelectedGroup] = useState(null);
  function handleSelectGroups(selectedGroup) {
    setSelectedGroup(selectedGroup);
  }

  const handleQueryChange = (value) => {
    setQuery(value);
    updateQuery(value);
  };

  useEffect(() => {
    if (data?.rules?.length) {
      setQuery(data);
    } else {
      setQuery(initialQuery);
    }
  }, [data]);

  const CustomRuleAction = (props) => {
    const { handleOnClick, label, ...rest } = props;

    if (rest?.ruleOrGroup?.isGlobalGroup) {
      return null;
    }
    return (
      <a
        size="sm"
        style={{ width: 82, right: 153 }}
        className="cursor-pointer text-decoration-underline position-absolute fs-11"
        color="outline-primary"
        onClick={handleOnClick}
        // icon={<SearchOutlined />}
      >
        +New Condition
      </a>
    );
  };
  const CustomGroupAction = (props) => {
    const { handleOnClick, label, ...rest } = props;
    console.log('CustomGroupActionCustomGroupAction', props);
    const handleImportRuleGroupClick = (args) => {
      console.log('...rest', rest);
      setShowGlobalGroupImportList(true);
    };
    const [showGlobalGroupImportList, setShowGlobalGroupImportList] =
      useState(false);
    return (
      <>
        <GlobalRuleGroupList
          isOpen={showGlobalGroupImportList}
          toggle={() => {
            setShowGlobalGroupImportList(!showGlobalGroupImportList);
          }}
          currentQuery={query}
          setQuery={setQuery}
          level={rest.level}
          path={rest.path}
        />
        <If condition={!rest?.ruleOrGroup?.isGlobalGroup}>
          <Then>
            <a
              size="sm"
              style={{ width: 117, right: 18 }}
              className="cursor-pointer text-decoration-underline position-absolute fs-11"
              color="outline-primary"
              onClick={handleOnClick}
              // icon={<SearchOutlined />}
            >
              +New Condition Group
            </a>
          </Then>
        </If>
        <If condition={rest.level === 0 && !rest?.ruleOrGroup?.isGlobalGroup}>
          <Then>
            <a
              size="sm"
              style={{ width: 40, right: 250 }}
              className="cursor-pointer text-decoration-underline  position-absolute fs-11"
              color="outline-primary"
              onClick={handleImportRuleGroupClick}
              // icon={<SearchOutlined />}
            >
              Import
            </a>
          </Then>
        </If>
      </>
    );
  };
  const CustomRemoveRuleAction = (props) => {
    const {
      handleOnClick,
      label,
      ruleOrGroup: rule,
      schema,
      path,
      ...rest
    } = props;
    const [isModalOpen, setModalOpen] = useState(false);

    const handleSaveClick = () => {
      setModalOpen(true);
      console.log('handleSaveClick', rule, rest);
    };
    console.log('handleSaveClick', rule, rest);

    return (
      <span className="">
        <GlobalRuleGroup
          isOpen={isModalOpen}
          toggle={() => {
            setModalOpen(!isModalOpen);
          }}
          ruleGroup={rule}
        />
        <Button
          aria-label="delete"
          color="soft-dark"
          onClick={handleOnClick}
          size="sm"
          className="shadow-none rule-remove btn-danger fs-20"
        >
          <i class="bi bi-x">X</i>
        </Button>

        <If condition={!isGlobalGroup && !rule?.isGlobalGroup}>
          <Then>
            <Button
              aria-label="save"
              color="soft-dark"
              onClick={handleSaveClick}
              size="sm"
              className="shadow-none rule-remove btn-danger fs-11"
            >
              <i class="bi bi-x fs-11">Save to Global Rule Group</i>
            </Button>
          </Then>
        </If>
      </span>
    );
  };
  const getGroupedFields = useCallback(() => {
    const mergedFields = [...fields, ...memberFields];
    const groupedFieldsObj = mergedFields?.reduce((acc, field) => {
      if (
        (fieldGroups?.length && fieldGroups?.includes(field.group)) ||
        !fieldGroups?.length
      ) {
        if (!acc[field.group]) {
          acc[field.group] = { label: field.group, options: [] };
        }
        acc[field.group].options.push({
          ...field,
          // isOptionSelected: value === field.value,
        });
        return acc;
      }

      return acc;
    }, {});
    return toArray(groupedFieldsObj);
  }, [fields]);
  const groupedFields = getGroupedFields();

  const CustomFieldSelector = ({
    handleOnChange,
    value,
    schema,
    path,
    ...rest
  }) => {
    const mergedFields = [...fields, ...memberFields];
    const [showSubOpertator, setShowSubOpertator] = useState(false);

    return (
      <>
        <Select
          value={mergedFields.find((field) => field.value === value)}
          // searchable
          onChange={(item) => {
            handleOnChange(item.value);
          }}
          styles={{ minWidth: 200 }}
          options={groupedFields}
        />
      </>
    );
  };

  const CustomOperatorSelector = ({
    handleOnChange,
    value,
    options,
    fieldData,
    schema,
    path,
    rule,
    ...rest
  }) => {
    return (
      <>
        <If condition={fieldData?.name?.indexOf(':') > -1}>
          <Then>
            <div>
              <Select
                value={fields.find(
                  (field) => field?.value === rule?.computeField
                )}
                // searchable
                onChange={(item) => {
                  schema.dispatchQuery(
                    update(schema.getQuery(), 'computeField', item.value, path)
                  );
                }}
                styles={{ minWidth: 200 }}
                options={fields.filter((field) => field.enableSumQty)}
              />
              <Input
                id="computeFieldValue"
                value={rule?.computeFieldValue}
                // searchable
                onChange={(event) => {
                  schema.dispatchQuery(
                    update(
                      schema.getQuery(),
                      'computeFieldValue',
                      event.target.value,
                      path
                    )
                  );
                  setTimeout(() => {
                    document.getElementById('computeFieldValue').focus();
                  }, 1);
                }}
                styles={{ minWidth: 400 }}
                // options={fields.filter((field) => field.enableSumQty)}
              />
            </div>
          </Then>
        </If>
        <Select
          value={options.find((option) => option.value === value)}
          // searchable
          onChange={(item) => {
            handleOnChange(item.value);
          }}
          styles={{ maxWidth: 100, width: 50 }}
          options={options}
        />
      </>
    );
  };

  let controlElements = {
    rule: RuleRenderer,

    fieldSelector: CustomFieldSelector,
    // valueSelector: CustomValueSelector,
    operatorSelector: CustomOperatorSelector,
    addRuleAction: CustomRuleAction,
    addGroupAction: CustomGroupAction,
    removeRuleAction: CustomRemoveRuleAction,
    removeGroupAction: CustomRemoveRuleAction,
    valueEditor: CustomValueEditor,
    combinatorSelector: CustomCombinator,
    ruleGroup: RuleGroupRender,
  };

  if (isUseRewardTierRuleGroup) {
    controlElements = { ...controlElements, ruleGroup: RewardTierRuleGroup };
  }

  return (
    <QueryBuilderBootstrap>
      <QueryBuilderDnD
        dnd={{ ...ReactDnD, ...ReactDndHtml5Backend }}
        enableDragAndDrop={false}
      >
        <QueryBuilder
          disabled={props.disabled}
          fields={fields}
          query={query}
          addRuleToNewGroups
          listsAsArrays
          showNotToggle={false}
          validator={defaultValidator}
          controlClassnames={{ queryBuilder: 'queryBuilder-branches' }}
          onQueryChange={handleQueryChange}
          combinators={[
            { name: 'and', label: 'All of the following' },
            { name: 'or', label: 'Any of the following' },
            // { name: 'CUSTOM', label: 'Custom Combination' },
          ]}
          controlElements={controlElements}
        />
      </QueryBuilderDnD>
    </QueryBuilderBootstrap>
  );
};

QueryBuilderApp.propTypes = {
  updateQuery: PropTypes.func,
  data: PropTypes.any,
};

export default QueryBuilderApp;
