import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import { If, Then } from 'react-if';
import FormulaCreationTool from './FormulaCreationTool';
import { SPLIT_REWARDS_BASED_ON } from '../../Tracker/TrackerRules';

export default function EffectRewards({
  effect,
  effectChangeHandler,
  eIndex,
  tIndex,
  discountTypes,
  promotionId,
}) {
  return (
    <>
      <If condition={effect.discountType === 'formula'}>
        <Then>
          <>
            <div className="w-75 mb-3">
              <Label id="effect-reward-type-select-label">
                Reward Formula{' '}
              </Label>
              <FormulaCreationTool
                formula={effect?.rewardFormula}
                updateFormula={(value) => {
                  effectChangeHandler('rewardFormula', value, eIndex, tIndex);
                }}
                style={{ height: 37.5 }}
              />
            </div>
          </>
        </Then>
      </If>
      <If condition={effect?.effectType === 'rewardPoints'}>
        <Then>
          <>
            <FormGroup>
              <Label id="effect-wallet-type-select-label">
                Split the Points based on
              </Label>
              <Input
                type="select"
                id="split-points-on-products"
                value={effect?.walletType}
                defaultValue={SPLIT_REWARDS_BASED_ON?.[0]?.value}
                onChange={(e) => {
                  effectChangeHandler(
                    'splitPoints',
                    e.target.value,
                    eIndex,
                    tIndex
                  );
                }}
                className="form-select"
              >
                {SPLIT_REWARDS_BASED_ON?.map((item, index) => (
                  <option value={item?.value} key={`wt-${index}`}>
                    {item?.label}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </>
        </Then>
      </If>
    </>
  );
}
