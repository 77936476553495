import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dashboard: {},
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setDashboard(state, action) {
      state.dashboard = action.payload;
    },
    setGlobalRules(state, action) {
      state.globalRules = action.payload;
    },
    setGlobalLanguages(state, action) {
      state.languages = action.payload;
    },
  },
});

export const { setDashboard, setGlobalRules, setGlobalLanguages } =
  dashboardSlice.actions;
export default dashboardSlice.reducer;
