import React, { useEffect } from 'react';
import { CardBody, Button, Input, FormGroup, Label, Form } from 'reactstrap';
import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { alertError, alertSuccess } from '../../../helpers/errorHandling';
import InnerLoader from '../../../components/Common/InnerLoader';
import LimitFields from './LimitFields';
import { createPromotionLauncher } from '../../../actions/promotion/launcher';
import ErrorMessagesInLang from '../../Configuration/Fields/ErrorMessagesInLang';

const PromotionBudgeting = ({
  onCancel,
  promotionId,
  promotionDetail,
  updateHandler = () => {},
  budgeting = [],
}) => {
  const budgetingSchema = Yup.object().shape({
    budgeting: Yup.array().of(
      Yup.object().shape({
        type: Yup.string().required('Type is required'),
        name: Yup.string().required('Name is required'),
        isGlobalLimitsActive: Yup.boolean().required(),
        isCustomerLimitsActive: Yup.boolean().required(),
        globalLimits: Yup.array()
          .of(
            Yup.object().shape({
              timePeriod: Yup.string().required('Time period is required'),
              redemptions: Yup.object().shape({
                maxValue: Yup.number().required('Max value is required').min(1),
                isActive: Yup.boolean().required(),
              }),
              amount: Yup.object()
                .shape({
                  maxValue: Yup.number().when('isActive', {
                    is: true,
                    then: Yup.number().required('Max value is required').min(1),
                    otherwise: Yup.number().nullable(),
                  }),
                  isActive: Yup.boolean().when('type', {
                    is: 'campaign',
                    then: Yup.boolean().required(),
                    otherwise: Yup.boolean().nullable(),
                  }),
                })
                .when('type', {
                  is: 'campaign',
                  then: Yup.object().required(
                    'Amount is required for campaign'
                  ),
                  otherwise: Yup.object().nullable(),
                }),
              points: Yup.object()
                .shape({
                  maxValue: Yup.number().when('isActive', {
                    is: true,
                    then: Yup.number().required('Max value is required').min(1),
                    otherwise: Yup.number().nullable(),
                  }),
                  isActive: Yup.boolean().when('type', {
                    is: 'reward',
                    then: Yup.boolean().required(),
                    otherwise: Yup.boolean().nullable(),
                  }),
                })
                .nullable()
                .when('type', {
                  is: 'reward',
                  then: Yup.object().required('Points are required for reward'),
                  otherwise: Yup.object().nullable(),
                }),
            })
          )
          .when('isGlobalLimitsActive', {
            is: true,
            then: Yup.array().min(1, 'At least one global limit is required'),
            otherwise: Yup.array().nullable(),
          }),
        customerLimits: Yup.array()
          .of(
            Yup.object().shape({
              timePeriod: Yup.string().required('Time period is required'),
              redemptions: Yup.object().shape({
                maxValue: Yup.number().required('Max value is required').min(1),
                isActive: Yup.boolean().required(),
              }),
              amount: Yup.object()
                .shape({
                  maxValue: Yup.number().when('isActive', {
                    is: true,
                    then: Yup.number().required('Max value is required').min(1),
                    otherwise: Yup.number().nullable(),
                  }),
                  isActive: Yup.boolean().when('type', {
                    is: 'campaign',
                    then: Yup.boolean().required(),
                    otherwise: Yup.boolean().nullable(),
                  }),
                })
                .nullable()
                .when('type', {
                  is: 'campaign',
                  then: Yup.object().required(
                    'Amount is required for campaign'
                  ),
                  otherwise: Yup.object().nullable(),
                }),
              points: Yup.object()
                .shape({
                  maxValue: Yup.number().when('isActive', {
                    is: true,
                    then: Yup.number().required('Max value is required').min(1),
                    otherwise: Yup.number().nullable(),
                  }),
                  isActive: Yup.boolean().when('type', {
                    is: 'reward',
                    then: Yup.boolean().required(),
                    otherwise: Yup.boolean().nullable(),
                  }),
                })
                .nullable()
                .when('type', {
                  is: 'reward',
                  then: Yup.object().required('Points are required for reward'),
                  otherwise: Yup.object().nullable(),
                }),
            })
          )
          .when('isCustomerLimitsActive', {
            is: true,
            then: Yup.array().min(1, 'At least one customer limit is required'),
            otherwise: Yup.array().nullable(),
          }),
      })
    ),
  });

  const initialValues = {
    budgeting: [
      {
        type: 'campaign',
        name: 'Campaign',
        isGlobalLimitsActive: false,
        isCustomerLimitsActive: false,
        globalLimits: [],
        customerLimits: [],
      },
    ],
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: budgetingSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const inputData = {
          _id: promotionDetail?.launcher?.launcherId || null,
          budgeting: values?.budgeting,
        };

        if (!promotionDetail?.launcher?.launcherId) {
          inputData.name = 'Default';
          inputData.promotionId = promotionId;
          inputData.startDate = promotionDetail?.launcher?.startDate;
          inputData.endDate = promotionDetail?.launcher?.endDate;
        }

        const { success } = await createPromotionLauncher(inputData);

        // const { success, message } = await updatePromotionBudget(inputData);
        if (success) {
          updateHandler();
          alertSuccess('Budgeting updated successfully.');
        } else {
          alertError({ message: 'Failed!, Please try again.' });
        }

        setSubmitting(false);
      } catch (error) {
        console.error(error);
      }
    },
  });

  useEffect(() => {
    try {
      if (budgeting?.length > 0) {
        formik.setFieldValue(`budgeting`, budgeting);
      } else {
        const allowedRewardTypes = getAllowedRewardTypes();
        formik.setFieldValue(`budgeting`, [
          ...initialValues?.budgeting,
          ...allowedRewardTypes,
        ]);
      }
    } catch (e) {
      console.log(e);
    }
  }, [budgeting]);

  const getAllowedRewardTypes = () => {
    try {
      const allowedRewardTypes = [];

      promotionDetail?.productEligibility?.rulesAndEffects?.map((rule) => {
        rule?.effects?.map((effect) => {
          if (effect?.effectType === 'rewardPoints') {
            allowedRewardTypes?.push({
              type: 'reward',
              name: effect?.rewardTypeId?.replace(/_/g, ' '),
              rewardTypeId: effect?.rewardTypeId,
              isGlobalLimitsActive: false,
              isCustomerLimitsActive: false,
              globalLimits: [],
              customerLimits: [],
            });
          }
        });
      });

      return allowedRewardTypes;
    } catch (e) {
      console.log(e);
    }
  };

  const addNewCustomerLimit = (budget, budgetIndex) => {
    try {
      updateBudget(
        {
          ...budget,
          customerLimits: [
            {
              timePeriod: 'weekly',
              redemptions: {
                maxValue: '',
                isActive: true,
              },
              points: {
                maxValue: '',
                isActive: false,
              },
              amount: {
                maxValue: '',
                isActive: false,
              },
            },
            ...(budget?.customerLimits || []),
          ],
        },
        budgetIndex
      );
    } catch (e) {
      console.log(e);
    }
  };

  const addNewGlobalLimit = (budget, budgetIndex) => {
    try {
      updateBudget(
        {
          ...budget,
          globalLimits: [
            {
              timePeriod: 'weekly',
              redemptions: {
                maxValue: '',
                isActive: true,
              },
              points: {
                maxValue: '',
                isActive: false,
              },
              amount: {
                maxValue: '',
                isActive: false,
              },
            },
            ...(budget?.globalLimits || []),
          ],
        },
        budgetIndex
      );
    } catch (e) {
      console.log(e);
    }
  };

  const removeGlobalLimit = (budgetingIndex, limitIndex) => {
    try {
      formik.setFieldValue(`budgeting[${budgetingIndex}]`, {
        ...values?.budgeting?.[budgetingIndex],
        globalLimits: values?.budgeting?.[budgetingIndex]?.globalLimits?.filter(
          (_, index) => index !== limitIndex
        ),
      });
    } catch (e) {
      console.log(e);
    }
  };

  const removeCustomerLimit = (budgetingIndex, limitIndex) => {
    try {
      formik.setFieldValue(`budgeting[${budgetingIndex}]`, {
        ...values?.budgeting?.[budgetingIndex],
        customerLimits: values?.budgeting?.[
          budgetingIndex
        ]?.customerLimits?.filter((_, index) => index !== limitIndex),
      });
    } catch (e) {
      console.log(e);
    }
  };

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
    handleChange,
    handleBlur,
    values,
  } = formik;

  const updateBudget = (budget, budgetIndex) => {
    formik.setFieldValue(`budgeting[${budgetIndex}]`, budget);
  };

  return (
    <CardBody className="p-1">
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          {values?.budgeting?.map((budget, budgetIndex) => {
            return (
              <div className="border border-1 p-3 mb-3">
                <div className="d-flex flex-row justify-content-between ">
                  <FormGroup switch>
                    <Label check className="fs-14">
                      {`Apply limits to the `}
                      <span className="text-capitalize">{budget?.name}</span>.
                    </Label>
                    <Input
                      type="switch"
                      role="switch"
                      checked={
                        values?.budgeting?.[budgetIndex]
                          ?.isGlobalLimitsActive || false
                      }
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        formik.setFieldValue(
                          `budgeting[${budgetIndex}].isGlobalLimitsActive`,
                          isChecked
                        );
                      }}
                    />
                  </FormGroup>
                  {values?.budgeting?.[budgetIndex]?.isGlobalLimitsActive ? (
                    <a
                      size="sm"
                      style={{ width: 82 }}
                      className="cursor-pointer text-decoration-underline fs-11"
                      color="outline-primary"
                      onClick={(e) => {
                        e?.preventDefault();
                        addNewGlobalLimit(budget, budgetIndex);
                      }}
                    >
                      +Add Limit
                    </a>
                  ) : null}
                </div>

                {errors?.budgeting?.[budgetIndex]?.globalLimits &&
                typeof errors?.budgeting?.[budgetIndex]?.globalLimits ===
                  'string' ? (
                  <span className="text-danger fs-12">
                    {errors?.budgeting?.[budgetIndex]?.globalLimits}
                  </span>
                ) : null}

                {values?.budgeting?.[budgetIndex]?.isGlobalLimitsActive &&
                  budget?.globalLimits?.map((limit, limitIndex) => (
                    <LimitFields
                      type={budget?.type}
                      key={limitIndex}
                      limitIndex={limitIndex}
                      budgetIndex={budgetIndex}
                      limit={limit}
                      isGlobal={true}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      values={values}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                      removeGlobalLimit={removeGlobalLimit}
                      removeCustomerLimit={removeCustomerLimit}
                    />
                  ))}
                <div className="d-flex flex-row justify-content-between mt-4">
                  <FormGroup switch>
                    <Label check className="fs-14">
                      {`Apply limits to the `}
                      <span className="text-capitalize">{budget?.name}</span>
                      {` per customer.`}
                    </Label>
                    <Input
                      type="switch"
                      role="switch"
                      checked={
                        values?.budgeting?.[budgetIndex]
                          ?.isCustomerLimitsActive || false
                      }
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        formik.setFieldValue(
                          `budgeting[${budgetIndex}].isCustomerLimitsActive`,
                          isChecked
                        );
                      }}
                    />
                  </FormGroup>
                  {values?.budgeting?.[budgetIndex]?.isCustomerLimitsActive ? (
                    <a
                      size="sm"
                      style={{ width: 82 }}
                      className="cursor-pointer text-decoration-underline fs-11"
                      color="outline-primary"
                      onClick={() => {
                        addNewCustomerLimit(budget, budgetIndex);
                      }}
                    >
                      +Add Limit
                    </a>
                  ) : null}
                </div>

                {errors?.budgeting?.[budgetIndex]?.customerLimits &&
                typeof errors?.budgeting?.[budgetIndex]?.customerLimits ===
                  'string' ? (
                  <span className="text-danger fs-14">
                    {errors?.budgeting?.[budgetIndex]?.customerLimits}
                  </span>
                ) : null}

                {values?.budgeting?.[budgetIndex]?.isCustomerLimitsActive &&
                  budget?.customerLimits?.map((limit, limitIndex) => (
                    <LimitFields
                      type={budget?.type}
                      limitIndex={limitIndex}
                      budgetIndex={budgetIndex}
                      limit={limit}
                      isGlobal={false}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      values={values}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                      removeGlobalLimit={removeGlobalLimit}
                      removeCustomerLimit={removeCustomerLimit}
                    />
                  ))}
              </div>
            );
          })}

          <hr />
          <ErrorMessagesInLang
            addTitle="Set error messages when budget conditions failed"
            editTitle="Set error messages when budget conditions failed"
            value={[]}
            onChange={(values) => {
              // setBudgetErrorMessages(values.filter((val) => val.value));
            }}
          />
          <div className="d-flex gap-2 justify-content-end">
            <Button outline color="danger" size="md" onClick={onCancel}>
              Cancel
            </Button>

            <Button
              loading={false}
              type="submit"
              color="primary"
              size="md"
              className="bg-gradient px-5"
            >
              Save
            </Button>
          </div>
        </Form>
      </FormikProvider>
      {isSubmitting ? <InnerLoader /> : null}
    </CardBody>
  );
};

export default PromotionBudgeting;
