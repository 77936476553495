export const discountTypes = [
  {
    label: 'Select Discount Type',
    value: '',
  },
  {
    label: 'Fixed',
    value: 'fixed',
  },
  {
    label: 'Percentage',
    value: 'percentage',
  },
];
