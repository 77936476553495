import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

const CustomPagination = ({
  totalPages = 1,
  pageOffset = 1,
  setPageOffset = () => {},
  paginationHandler = () => {},
}) => {
  const onPageChange = (offset) => {
    paginationHandler(offset);
    setPageOffset(offset);
  };

  return (
    <div className="align-items-center m-3 pt-2 justify-content-between row text-center text-sm-start">
      <div className="col-sm"></div>
      {totalPages > 1 && (
        <div className="col-sm-auto mt-3 mt-sm-0">
          <ul className="pagination pagination-separated pagination-sm mb-0 justify-content-center">
            {/* Previous Page Button */}
            <li className={`page-item ${pageOffset <= 0 ? 'disabled' : ''}`}>
              <Link
                to="#"
                className="page-link"
                onClick={() => pageOffset > 0 && onPageChange(pageOffset - 1)}
              >
                ←
              </Link>
            </li>

            {/* Show first 10 pages with ellipses in between */}
            {pageOffset > 5 && (
              <>
                <li className="page-item">
                  <Link
                    to="#"
                    className="page-link"
                    onClick={() => onPageChange(0)}
                  >
                    1
                  </Link>
                </li>
                <li className="page-item disabled">
                  <span className="page-link">...</span>
                </li>
              </>
            )}

            {/* Display pages */}
            {Array?.from({ length: 10 }).map((_, i) => {
              const pageIndex = pageOffset + i;
              if (pageIndex < totalPages) {
                return (
                  <li
                    key={pageIndex}
                    className={`page-item ${
                      pageOffset === pageIndex ? 'active' : ''
                    }`}
                  >
                    <Link
                      to="#"
                      className="page-link"
                      onClick={() => onPageChange(pageIndex)}
                    >
                      {pageIndex + 1}
                    </Link>
                  </li>
                );
              }
              return null;
            })}

            {/* Show ellipses if near the last page */}
            {pageOffset + 9 < totalPages && (
              <>
                <li className="page-item disabled">
                  <span className="page-link">...</span>
                </li>
                <li className="page-item">
                  <Link
                    to="#"
                    className="page-link"
                    onClick={() => onPageChange(totalPages - 1)}
                  >
                    {totalPages}
                  </Link>
                </li>
              </>
            )}

            {/* Next Page Button */}
            <li
              className={`page-item ${
                pageOffset >= totalPages - 1 ? 'disabled' : ''
              }`}
            >
              <Link
                to="#"
                className="page-link"
                onClick={() =>
                  pageOffset < totalPages - 1 && onPageChange(pageOffset + 1)
                }
              >
                →
              </Link>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

CustomPagination.propTypes = {
  totalPages: PropTypes.number,
  pageOffset: PropTypes.number,
  setPageOffset: PropTypes.func.isRequired,
};

export default CustomPagination;
