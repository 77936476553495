import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import QueryBuilderApp from './index';
import axios from 'axios';
import { saveGlobalRuleGroup } from './action';
import { useDispatch } from 'react-redux';

const GlobalRuleGroup = ({
  isOpen = false,
  toggle,
  ruleGroup,
  optionType = '',
}) => {
  const [groupName, setGroupName] = useState('');
  const [selectedGroupType, setselectedGroupType] = useState(optionType);
  const dispatch = useDispatch();

  const handleGroupNameChange = (e) => setGroupName(e.target.value);
  const handleOptionChange = (e) => setselectedGroupType(e.target.value);
  const [updatedRuleGroup, setUpdatedRuleGroup] = useState(ruleGroup);
  const handleSubmit = async () => {
    const payload = {
      groupName,
      selectedGroupType: selectedGroupType,
      ruleGroup: updatedRuleGroup,
    };

    try {
      await saveGlobalRuleGroup(payload, dispatch);
      toggle(); // Close the modal on successful save
    } catch (error) {
      console.error('Error saving global rule group:', error);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Global Rule Group</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="groupName">Global Group Rule Name</Label>
            <Input
              type="text"
              name="groupName"
              id="groupName"
              value={groupName}
              onChange={handleGroupNameChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="selectOption">Select Option</Label>
            <Input
              type="select"
              name="selectOption"
              id="selectOption"
              value={selectedGroupType}
              onChange={handleOptionChange}
            >
              <option value="">Select an option</option>
              <option value="Product Eligibility">Product Eligibility</option>
              <option value="Tier">Tier</option>
              <option value="Triggers">Triggers</option>
            </Input>
          </FormGroup>
        </Form>
        <QueryBuilderApp
          isGlobalGroup={true}
          data={updatedRuleGroup}
          updateQuery={setUpdatedRuleGroup}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleSubmit}>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default GlobalRuleGroup;
