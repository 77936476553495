import React, { useState } from 'react';
import PromotionLauncherForm from './PromotionLauncherForm';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import PromotionLauncherList from './PromotionLauncherList';
import PromotionBudgeting from '../PromotionBudgeting';
import PromotionCostDistribution from '../PromotionCostDistribution';
const PromotionLauncher = ({
  promotionId,
  promotionDetail,
  launcherDetails,
  updateHandler = () => {},
}) => {
  const [activeModal, setActiveModal] = useState(null);

  const closeModal = () => {
    setActiveModal(null);
  };

  const onUpdate = () => {
    try {
      updateHandler();
      if (activeModal) {
        setTimeout(closeModal, 2000);
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div>
      <div className="d-flex flex-row gap-2 mt-4 me-4 float-end">
        <Button
          color="secondary"
          size="sm"
          onClick={() => setActiveModal('budgeting')}
          className="pt-1 pb-1"
        >
          Budgeting
        </Button>

        <Button
          color="secondary"
          size="sm"
          onClick={() => setActiveModal('costDistribution')}
          className="pt-1 pb-1"
        >
          Cost Distribution
        </Button>

        <Button
          color="secondary"
          size="sm"
          onClick={() => setActiveModal('launcherSettings')}
          className="pt-1 pb-1"
        >
          Create new launcher
        </Button>
      </div>

      <PromotionLauncherForm
        launcherDetails={launcherDetails}
        promotionId={promotionId}
        updateHandler={onUpdate}
      />

      <div className="border-top">
        <h6 className="ms-4 mt-4 mb-2 font-bold font-size-16">All Launchers</h6>
        <PromotionLauncherList
          promotionId={promotionId}
          launcherDetails={launcherDetails}
        />
      </div>

      <Modal
        isOpen={!!activeModal}
        toggle={closeModal}
        centered
        size={activeModal === 'costDistribution' ? 'lg' : 'xl'}
      >
        <div class="modal-header">
          <h5 class="modal-title">
            {activeModal === 'launcherSettings'
              ? 'Create New Launcher'
              : activeModal === 'costDistribution'
              ? ' Cost Distribution'
              : 'Budgeting'}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              closeModal();
            }}
          ></button>
        </div>

        <ModalBody>
          {activeModal === 'budgeting' && (
            <PromotionBudgeting
              promotionId={promotionId}
              promotionDetail={promotionDetail}
              updateHandler={onUpdate}
              budgeting={launcherDetails?.budgeting || []}
              onCancel={closeModal}
            />
          )}
          {activeModal === 'launcherSettings' && (
            <PromotionLauncherForm
              launcherDetails={null}
              promotionId={promotionId}
              updateHandler={onUpdate}
              onCancel={closeModal}
              isNew
              budgeting={launcherDetails?.budgeting}
            />
          )}
          {activeModal === 'costDistribution' && (
            <PromotionCostDistribution
              promotionId={promotionId}
              updateHandler={onUpdate}
              onCancel={closeModal}
              promotionDetail={promotionDetail}
              costDistribution={launcherDetails?.costDistribution}
            />
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default PromotionLauncher;
