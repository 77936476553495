import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Input,
  FormGroup,
  Label,
  FormFeedback,
  Form,
  Row,
  Col,
} from 'reactstrap';

import { alertError, alertSuccess } from '../../../helpers/errorHandling';

import InnerLoader from '../../../components/Common/InnerLoader';
import './style.scss';

import { DISCOUNT_TYPES } from '../../../constants';
import {
  getCombos,
  createOrUpdateCombo,
} from '../../../actions/promotion/combo';

import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';

const CreateComboForm = ({
  promotionId,
  effectId,
  submitHandler = () => {},
  comboProp = null,
  effect = {},
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [open, setOpen] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    comboName: Yup.string()
      .required('Combo Name is required')
      .min(2, 'Combo Name must be at least 2 characters'),

    products: Yup.array()
      .of(
        Yup.object().shape({
          sku: Yup.string()
            .required('SKU is required')
            ?.min(12, 'SKU must be at least 12 characters'),
          qty: Yup.number()
            .required('Quantity is required')
            .min(1, 'Quantity must be greater than or equal to 1'),
        })
      )
      .required('Product is required')
      .min(2, 'At least two products are required')
      .test('unique-sku', 'Products must be unique', function (products) {
        const skuList = products?.map((product) => product?.sku);
        // Use filter to find duplicates
        const hasDuplicates =
          skuList?.filter((sku, index) => skuList?.indexOf(sku) !== index)
            ?.length > 0;
        return !hasDuplicates;
      }),

    discountType: Yup.string().required('Discount Type is required'),

    discountValue: Yup.number()
      .required('Discount Value is required')
      .min(1, 'Discount Value must be a positive number'),

    maxDiscount: Yup.number().when('discountType', {
      is: 'percentage', // Only validate maxDiscount if discountType is 'percentage'
      then: Yup.number()
        .required('Max Discount is required')
        .min(1, 'Max Discount must be a positive number'),
      otherwise: Yup.number().notRequired(), // If discountType is not 'percentage', maxDiscount is not required
    }),
  });

  const initialValues = {
    comboName: '',
    products: [
      { sku: 'SKU-1', qty: '' },
      { sku: 'SKU-2', qty: '' },
    ],
    discountType: DISCOUNT_TYPES?.[0]?.value,
    discountValue: 0,
    maxDiscount: 0,
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const inputData = [
          {
            _id: comboProp?._id || null,
            promotionId: promotionId,
            comboName: values?.comboName,
            version: comboProp?.version || 0,
            rule: {
              products: values?.products,
              discountType: values?.discountType,
              discountValue: Number(values?.discountValue),
              maxDiscount:
                values?.discountType === 'percentage'
                  ? Number(values?.maxDiscount)
                  : null,
            },
            effectIds:
              comboProp?.effectIds?.length > 0
                ? [...comboProp?.effectIds]
                : [effectId],
          },
        ];
        const { success, message } = await createOrUpdateCombo(inputData);
        if (success) {
          alertSuccess(message);
          submitHandler();
          setTimeout(() => {
            setIsOpenModal(false);
          }, 400);
        } else {
          alertError({
            message: message || 'Failed!,  Please try again later',
          });
        }
        setTimeout(() => {
          setIsLoading(false);
        }, 400);
      } catch (error) {
        console.log(error);
      }
    },
  });

  const handleAddProduct = () => {
    formik.setFieldValue('products', [
      ...formik?.values?.products,
      { sku: `SKU-${formik?.values?.products?.length + 1}`, qty: '' },
    ]);
  };

  const closeHandler = () => {
    // formik?.setValues({ ...initialValues });
  };

  const {
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    setValues,
    values,
    isSubmitting,
  } = formik;

  useEffect(() => {
    if (comboProp?._id) {
      setValues({
        comboName: comboProp?.comboName,
        products: comboProp?.rule?.products,
        discountType: comboProp?.rule?.discountType,
        discountValue: comboProp?.rule?.discountValue,
        maxDiscount:
          comboProp?.rule?.discountType === 'percentage'
            ? comboProp?.rule?.maxDiscount
            : 0,
      });
    }
  }, [comboProp]);

  return (
    <>
      {comboProp ? (
        <a
          size="sm"
          className="cursor-pointer text-decoration-underline fs-13"
          color="outline-primary"
          onClick={() => setIsOpenModal(true)}
        >
          View Details
        </a>
      ) : (
        <div
          onClick={() => setIsOpenModal(true)}
          className="btn btn-sm btn-secondary"
        >
          <i className="bx bx-plus fs-14 pe-2"></i>
          Create new combo
        </div>
      )}
      <Modal
        isOpen={isOpenModal}
        toggle={() => {
          setIsOpenModal(!isOpenModal);
        }}
        centered={true}
        size="lg"
        onClosed={() => closeHandler()}
      >
        <ModalHeader
          className="modal-title pb-3 bg-light"
          toggle={() => {
            setIsOpenModal(!isOpenModal);
          }}
        >
          Create Combo
        </ModalHeader>
        <ModalBody className="combos-modal">
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit}>
              <FormGroup>
                <Label for="comboName">Combo Name</Label>
                <Input
                  id="comboName"
                  name="comboName"
                  placeholder="Combo Name"
                  type="text"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values?.comboName}
                  invalid={!!errors?.comboName && touched?.comboName}
                />
                <FormFeedback>
                  {touched?.comboName && errors?.comboName}
                </FormFeedback>
              </FormGroup>
              <div className="d-flex flex-row gap-3 w-100">
                <div className="col-6 flex-shrink-1">
                  <FormGroup>
                    <Label for="discountType">Discount Type</Label>
                    <Input
                      id="discountType"
                      name="discountType"
                      type="select"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values?.discountType}
                      invalid={!!errors?.discountType && touched?.discountType}
                    >
                      {DISCOUNT_TYPES?.filter((dis) =>
                        dis.type.includes(effect.effectType)
                      )?.map((item, idx) => (
                        <option value={item?.value} key={`dt-${idx}`}>
                          {item?.label}
                        </option>
                      ))}
                    </Input>

                    <FormFeedback>
                      {touched?.discountType && errors?.discountType}
                    </FormFeedback>
                  </FormGroup>
                </div>
                <div className="col-6 flex-shrink-1">
                  <FormGroup>
                    <Label for="discountValue">Discount Value</Label>
                    <Input
                      id="discountValue"
                      name="discountValue"
                      placeholder="Discount Value"
                      type="text"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values?.discountValue}
                      invalid={
                        !!errors?.discountValue && touched?.discountValue
                      }
                    />
                    <FormFeedback>
                      {touched?.discountValue && errors?.discountValue}
                    </FormFeedback>
                  </FormGroup>
                </div>
              </div>

              {values?.discountType === 'percentage' ? (
                <div className="d-flex flex-row gap-3 w-100">
                  <div className="col-6 flex-shrink-1">
                    <FormGroup>
                      <Label for="maxDiscount">Max Discount</Label>
                      <Input
                        id="maxDiscount"
                        name="maxDiscount"
                        placeholder="Max Discount"
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values?.maxDiscount}
                        invalid={!!errors?.maxDiscount && touched?.maxDiscount}
                      />
                      <FormFeedback>
                        {touched?.maxDiscount && errors?.maxDiscount}
                      </FormFeedback>
                    </FormGroup>
                  </div>
                  <div className="col-6 flex-shrink-1" />
                </div>
              ) : null}

              <Label>Products</Label>

              {formik?.values?.products?.map((product, index) => (
                <div
                  className="d-flex flex-row gap-3 align-items-baseline"
                  key={index}
                >
                  <div className="col-6 flex-shrink-1">
                    <FormGroup>
                      <Input
                        id={`products[${index}].sku`}
                        name={`products[${index}].sku`}
                        placeholder="SKU"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.products?.[index]?.sku}
                        invalid={
                          touched?.products &&
                          errors?.products &&
                          !!errors?.products?.[index]?.sku
                        }
                      />
                      <FormFeedback>
                        {errors?.products?.[index]?.sku}
                      </FormFeedback>
                    </FormGroup>
                  </div>
                  <div className="col-3 flex-shrink-1">
                    <div>
                      <FormGroup>
                        <Input
                          id={`products[${index}].qty`}
                          name={`products[${index}].qty`}
                          placeholder="QTY"
                          type="number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.products?.[index]?.qty}
                          invalid={
                            touched?.products &&
                            errors?.products &&
                            !!errors?.products?.[index]?.qty
                          }
                        />
                        <FormFeedback>
                          {errors?.products?.[index]?.qty}
                        </FormFeedback>
                      </FormGroup>
                    </div>
                  </div>
                  <div className="col-2 flex-shrink-1">
                    <div>
                      <Button
                        color="danger"
                        size="sm"
                        onClick={() => {
                          setValues({
                            ...values,
                            products: values?.products?.filter(
                              (item) => item?.sku !== product?.sku
                            ),
                          });
                        }}
                      >
                        <i className="bx bx-trash fs-18"></i>
                      </Button>
                    </div>
                  </div>
                </div>
              ))}

              {typeof errors?.products === 'string' ? (
                <div className="text-danger pb-2">{errors?.products}</div>
              ) : null}

              <div className="d-flex flex-row w-100">
                <a
                  size="sm"
                  className="cursor-pointer text-decoration-underline fs-13"
                  color="outline-primary"
                  onClick={handleAddProduct}
                >
                  +Add Product
                </a>
              </div>

              <div className="d-flex flex-row justify-content-end mt-3 w-100">
                <Button
                  color="dark"
                  size="sm"
                  onClick={() => {
                    setIsOpenModal(false);
                  }}
                >
                  Close
                </Button>
                <Button
                  color="primary"
                  size="sm"
                  type="submit"
                  className="ms-2"
                >
                  {comboProp ? 'Update' : 'Create'}
                </Button>
              </div>
            </Form>
          </FormikProvider>
          {isLoading ? <InnerLoader /> : null}
        </ModalBody>
      </Modal>
    </>
  );
};

export default CreateComboForm;
