import { useState } from 'react';
import {
  Button,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  FormFeedback,
  ModalFooter,
} from 'reactstrap';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import {
  checkAvailabilityOfCoupons,
  createOrUpdateCoupon,
} from '../../../actions/promotion';
import { alertError, alertSuccess } from '../../../helpers/errorHandling';
import InnerLoader from '../../../components/Common/InnerLoader';
import Flatpickr from 'react-flatpickr';
import { discountTypes } from './constants';

const GenerateCouponDirect = ({
  onCancel,
  setCoupons,
  setPageOffset,
  setTotalPages,
  setIsRefreshTable,
  promotionId,
}) => {
  const [codesError, setCodesError] = useState(null);
  const [newCodesText, setNewCodesText] = useState('');

  const directCouponSchema = Yup.object().shape({
    codesText: Yup.string().required('Codes are required'),
    isUseLauncherConfig: Yup.boolean().required('This field is required'),
    isUseConditionsAndTriggers: Yup.boolean().required(
      'This field is required'
    ),
  });

  const formik = useFormik({
    initialValues: {
      codesText: '',
      startsAt: null,
      expiresAt: null,
      redeemableQuantity: null,
      isUseLauncherConfig: true,
      isUseConditionsAndTriggers: true,
      discountType: 'fixed',
    },
    validationSchema: directCouponSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const isAllow = await checkAvailabilityOfCouponsFunc(values?.codesText);
        if (isAllow) {
          const coupons = values?.codesText?.split(',')?.map((code) => ({
            code: code?.trim(),
            expiresAt: values?.expiresAt,
            redeemableQuantity: Number(values?.redeemableQuantity),
            startsAt: values?.startsAt,
            promotionId: promotionId,
            isUseLauncherConfig: values?.isUseLauncherConfig,
            isUseConditionsAndTriggers: values?.isUseConditionsAndTriggers,
            discountType: values?.discountType,
          }));

          const variables = { input: { coupons } };
          const { success, message, data } = await createOrUpdateCoupon(
            variables
          );

          if (success) {
            setTotalPages(data?.totalPages);
            setPageOffset(data?.totalPages);
            setCoupons(data?.coupons);
            setIsRefreshTable(true);
            alertSuccess('Coupons generated successfully.');
            onCancel();
          } else {
            alertError(message || 'An error occurred. Please try again later.');
          }
        }
      } catch (error) {
        console.error(error);
        alertError('An unexpected error occurred.');
      } finally {
        setSubmitting(false);
      }
    },
  });

  const checkAvailabilityOfCouponsFunc = async (codesText) => {
    try {
      const codes = codesText?.split(',')?.map((code) => code?.trim());
      if (codes.length > 0) {
        const variables = { input: { codes } };
        const { data, success } = await checkAvailabilityOfCoupons(variables);

        if (success && data?.existingCodes?.length > 0) {
          setCodesError(
            `The following coupons already exist: ${data?.existingCodes?.join(
              ', '
            )}.`
          );
          setNewCodesText(data?.newCodes?.join(','));
          return false;
        }
        setCodesError(null);
        setNewCodesText('');
        return true;
      }
    } catch (error) {
      console.error(error);
      alertError('Error checking coupon availability.');
      return false;
    }
  };

  const {
    values,
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    handleChange,
    setFieldValue,
    getFieldProps,
  } = formik;

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={handleSubmit}>
        <Row className="mt-3">
          <Col xs={12}>
            <FormGroup>
              <Label>Enter Your Coupon Codes</Label>
              <Input
                type="text"
                name="codesText"
                placeholder="e.g., APP5,DH2289ED"
                value={values?.codesText}
                onChange={(e) => {
                  handleChange(e);
                  setCodesError(null);
                }}
                invalid={touched?.codesText && !!errors?.codesText}
              />
              <FormFeedback>{errors?.codesText}</FormFeedback>
              {codesError && (
                <div className="text-danger">
                  {codesError}{' '}
                  <Button
                    color="link"
                    onClick={() => {
                      setFieldValue('codesText', newCodesText);
                      setCodesError(null);
                    }}
                  >
                    Use only new codes
                  </Button>
                </div>
              )}
            </FormGroup>
          </Col>

          <Row>
            <Col xl={3} lg={3} md={3} sm={12} xs={12}>
              <div className="form-check form-switch form-switch-custom form-switch-dark mb-2 mt-2 d-flex gap-2 align-items-center p-0">
                <Label
                  className="form-check-label"
                  htmlFor="isUseLauncherConfig"
                >
                  Use Launcher Config
                </Label>
                <Input
                  type="checkbox"
                  className="form-check-input m-0"
                  id="isUseLauncherConfig"
                  {...getFieldProps('isUseLauncherConfig')}
                  checked={values?.isUseLauncherConfig}
                />
              </div>
            </Col>
          </Row>

          {!values.isUseLauncherConfig ? (
            <Row spacing={3} className="mt-2">
              <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                <FormGroup>
                  <Label for="startsAt">Start Date</Label>
                  <Flatpickr
                    className="form-control"
                    id="startsAt"
                    name="startsAt"
                    placeholder="Start Date"
                    options={{
                      enableTime: true,
                      dateFormat: 'Y-m-d h:i K',
                      time_24hr: false,
                    }}
                    onChange={(date) => setFieldValue('startsAt', date[0])}
                  />
                  {touched?.startsAt && errors?.startsAt && (
                    <div className="text-danger">{errors?.startsAt}</div>
                  )}
                </FormGroup>
              </Col>
              <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                <FormGroup>
                  <Label for="expiresAt">End Date</Label>
                  <Flatpickr
                    className="form-control"
                    id="expiresAt"
                    name="expiresAt"
                    placeholder="End Date"
                    options={{
                      enableTime: true,
                      dateFormat: 'Y-m-d h:i K',
                      time_24hr: false,
                    }}
                    onChange={(date) => setFieldValue('expiresAt', date[0])}
                  />
                  {touched?.expiresAt && errors?.expiresAt && (
                    <div className="text-danger">{errors?.expiresAt}</div>
                  )}
                </FormGroup>
              </Col>
              <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                <FormGroup>
                  <Label for="redeemableQuantity">Redeemable Quantity</Label>
                  <Input
                    type="number"
                    id="redeemableQuantity"
                    name="redeemableQuantity"
                    placeholder="Redeemable Quantity"
                    min={0}
                    {...getFieldProps('redeemableQuantity')}
                    invalid={
                      touched?.redeemableQuantity &&
                      !!errors?.redeemableQuantity
                    }
                  />
                  <FormFeedback>{errors?.redeemableQuantity}</FormFeedback>
                </FormGroup>
              </Col>
            </Row>
          ) : null}

          <Row>
            <Col xl={3} lg={3} md={3} sm={12} xs={12}>
              <div className="form-check form-switch form-switch-custom form-switch-dark mb-2 mt-2 d-flex gap-2 align-items-center p-0">
                <Label
                  className="form-check-label"
                  htmlFor="isUseConditionsAndTriggers"
                >
                  Use conditions and triggers
                </Label>
                <Input
                  type="checkbox"
                  className="form-check-input m-0"
                  id="isUseConditionsAndTriggers"
                  {...getFieldProps('isUseConditionsAndTriggers')}
                  checked={formik?.values?.isUseConditionsAndTriggers}
                />
              </div>
            </Col>
          </Row>

          {!values?.isUseConditionsAndTriggers ? (
            <Col xl={3} lg={3} md={3} sm={12} xs={12}>
              <FormGroup>
                <Label for="type">Discount Type</Label>
                <Input
                  type="select"
                  id="discountType"
                  name="discountType"
                  {...getFieldProps('discountType')}
                  invalid={touched?.discountType && !!errors?.discountType}
                >
                  {discountTypes?.map((item) => (
                    <option key={item?.value} value={item?.value}>
                      {item?.label}
                    </option>
                  ))}
                </Input>
                <FormFeedback>{errors?.discountType}</FormFeedback>
              </FormGroup>
            </Col>
          ) : null}
        </Row>

        <ModalFooter>
          <Button
            className="btn btn-light py-1 px-4"
            size="md"
            data-bs-dismiss="modal"
            onClick={onCancel}
          >
            Close
          </Button>
          <Button
            color="secondary"
            className="py-1 px-4 ms-2"
            size="md"
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Processing...' : 'Create'}
          </Button>
        </ModalFooter>

        {isSubmitting && <InnerLoader />}
      </Form>
    </FormikProvider>
  );
};

export default GenerateCouponDirect;
