import { gql } from '@apollo/client';

const promotionDetailsOutput = `
_id
basicSettings {
  promotionName
  promotionDescription
  tags
  promotionType
  country
  priority
  discard
  alwaysRun
  combinableWithPromotion
  promotionAppliedToAllItems
  meta
}
reportConfigs{
  _id
  filters
  groupBy
  configName
  fieldCode
}
reports
triggers
triggerRules
triggerErrorMessages {
  langCode
  value
}
promotionType
triggerType
productEligibility
status
version
launcher{
  launcherId
  startDate
  endDate
  neverExpires
  costDistribution{
  teamId
  teamName
  percentage
}
},
messages
`;

export const GET_PROMOTIONS = gql`
  query getPromotions($input: PromotionsInput!) {
    getPromotions(input: $input) {
      totalPages
      totalPromotions
      currentOffset
      promotions {
        ${promotionDetailsOutput}
      }
    }
  }
`;

export const GET_PROMOTION_DETAIL = gql`
  query getPromotionDetail($promotionId: String!) {
    getPromotionDetail(promotionId: $promotionId) {
      ${promotionDetailsOutput}
    }
  }
`;

export const GET_PROMOTION_DETAIL_BY_VERSION = gql`
  query getPromotionDetailByVersion($input:PromotionVersionIdInput!) {
    getPromotionDetailByVersion(input: $input) {
      ${promotionDetailsOutput}
    }
  }
`;

export const CREATE_UPDATE_BASIC_SETTINGS = gql`
  mutation createAndUpdateBasicDetailsPromotion(
    $input: PromotionInputBasicSettings!
    $promotionId: String
  ) {
    createAndUpdateBasicDetailsPromotion(
      input: $input
      promotionId: $promotionId
    ) {
      id
      success
      message
    }
  }
`;

export const DELETE_COUPON = () => gql`
  mutation deleteCoupon($id: String) {
    deleteCoupon(id: $id)
  }
`;

const couponsOutput = `
success
message
data {
  pageOffset
  totalPages
  coupons {
    _id
    promotionId
    code
    discountType
    redeemedQuantityConfirmed
    redeemedQuantityOnHold
    redeemableQuantity
    isUseLauncherConfig
    isUseConditionsAndTriggers
    createdAt
    startsAt
    expiresAt
    isActive
  }
}`;

export const CREATE_UPDATE_COUPON = gql`
  mutation createOrUpdateCoupon($input: CouponsInput!) {
    createOrUpdateCoupon(input: $input) {
      ${couponsOutput}
    }
  }
`;

export const AUTO_GENERATE_COUPONS = gql`
  mutation autoGenerateCoupons($input: GenerateCouponInput!) {
    autoGenerateCoupons(input: $input) {
      ${couponsOutput}
    }
  }
`;

export const CHECK_AVAILABILITY_OF_COUPONS = gql`
  mutation checkAvailabilityOfCoupons($input: CouponCheckInput!) {
    checkAvailabilityOfCoupons(input: $input) {
      existingCodes
      newCodes
    }
  }
`;

export const GENERATE_COUPONS_FROM_CSV = gql`
  mutation generateCouponsFromCsv($input: CsvUploadInput!) {
    generateCouponsFromCsv(input: $input) {
      ${couponsOutput}
    }
  }
`;

export const GET_COUPONS = gql`
  query getCoupons($input: CouponListInput!) {
    getCoupons(input: $input) {
      ${couponsOutput}
    }
  }
`;

export const DELETE_COUPON_BY_ID = gql`
  mutation deleteCouponById($input: CouponDeleteInput!) {
    deleteCouponById(input: $input) {
      _id
      success
      message
    }
  }
`;

export const UPDATE_PROMOTION_TRIGGERS = gql`
  mutation updatePromotionTriggers($input: PromotionTriggersInput!) {
    updatePromotionTriggers(input: $input) {
      success
      message
    }
  }
`;

export const UPDATE_PROMOTION_PRODUCT_ELIGIBILITY = gql`
  mutation updatePromotionProductEligibility(
    $input: PromotionProductEligibilityInput!
  ) {
    updatePromotionProductEligibility(input: $input) {
      id
      success
      message
    }
  }
`;

export const PUBLISH_PROMOTION = gql`
  mutation publishPromotion($input: PublishPromotionInput!) {
    publishPromotion(input: $input) {
      id
      success
      message
    }
  }
`;

export const GET_PROMOTION_VERSIONS = gql`
  query getPromotionVersions($input: PromotionIdInput!) {
    getPromotionVersions(input: $input) {
      promotionId
      versions {
        _id
        updatedAt
        version
        status
      }
    }
  }
`;

export const UPDATE_PROMOTION_BUDGET = gql`
  mutation updatePromotionBudget($input: PromotionBudgetInput!) {
    updatePromotionBudget(input: $input) {
      id
      success
      message
    }
  }
`;

export const UPDATE_PROMOTION_COST_DISTRIBUTION = gql`
  mutation updatePromotionCostDistribution(
    $input: PromotionCostDistributionInput!
  ) {
    updatePromotionCostDistribution(input: $input) {
      id
      success
      message
    }
  }
`;
