import { useEffect, useState } from 'react';
import { getAllConfigs } from '../../../actions/promotion/generals';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Input,
  Label,
} from 'reactstrap';
import EffectMetaForm from './EffectMetaForm';

const EffectMeta = ({ effect, eIndex, tIndex, effectChangeHandler }) => {
  const [availableOutputMetaFields, setAvailableOutputMetaFields] = useState(
    []
  );
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [meta, setMeta] = useState({});

  const getConfigs = async () => {
    try {
      const { data } = await getAllConfigs({
        configName: 'output-meta',
        configEnv: 'development',
        pageOffset: 0,
        pageSize: 2000,
      });

      setAvailableOutputMetaFields(data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getConfigs();
  }, []);

  useEffect(() => {
    if (effect?.meta) {
      setMeta(effect?.meta || {});
    }
  }, [effect]);

  const closeHandler = () => {};

  const fieldSelectionHandler = async (field) => {
    try {
      const updatedMeta = { ...meta };

      const fieldId = field?.values?.find(
        (v) => v?.fieldCode === 'fieldId'
      )?.fieldValue;

      const isExist = Object?.keys(meta)?.includes(fieldId);

      if (isExist) {
        delete updatedMeta[fieldId];
      } else {
        updatedMeta[fieldId] = null;
      }

      setMeta({ ...updatedMeta });
    } catch (error) {
      console.log(error);
    }
  };

  const updateSelectedOutputMetaFields = () => {
    try {
      effectChangeHandler('meta', meta, eIndex, tIndex);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeMeta = ({ fieldId = null, value = null }) => {
    try {
      const updatedMeta = { ...meta };

      updatedMeta[fieldId] = value;

      setMeta({ ...updatedMeta });
      effectChangeHandler('meta', updatedMeta, eIndex, tIndex);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="d-flex flex-column w-100 mt-1">
      <EffectMetaForm
        meta={meta}
        fields={availableOutputMetaFields}
        handleChangeMeta={handleChangeMeta}
      />
      <Button
        color="primary"
        size="sm"
        onClick={() => setIsOpenModal(true)}
        style={{ width: 'fit-content' }}
      >
        Add Meta Fields
      </Button>
      <Modal
        isOpen={isOpenModal}
        toggle={() => {
          setIsOpenModal(!isOpenModal);
        }}
        centered={true}
        size="lg"
        onClosed={() => closeHandler()}
      >
        <ModalHeader
          className="modal-title pb-3 bg-light"
          toggle={() => {
            setIsOpenModal(!isOpenModal);
          }}
        >
          Output Meta
        </ModalHeader>
        <ModalBody className="combos-modal">
          {availableOutputMetaFields?.map((metaField) => {
            const fieldLabel = metaField?.values?.find(
              (v) => v?.fieldCode === 'configLabel'
            )?.fieldValue;

            const fieldId = metaField?.values?.find(
              (v) => v?.fieldCode === 'fieldId'
            )?.fieldValue;

            return (
              <div className="d-flex flex-row justify-content-between align-items-center w-100 pt-2 pb-2">
                <div className="form-check">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    id="auth-remember-check"
                    onClick={(e) => {
                      e?.stopPropagation();
                      fieldSelectionHandler(metaField);
                    }}
                    checked={Object?.keys(meta)?.includes(fieldId)}
                  />
                  <Label
                    className="form-check-label text-capitalize"
                    onClick={(e) => {
                      e?.stopPropagation();
                      fieldSelectionHandler(metaField);
                    }}
                  >
                    {fieldLabel}
                  </Label>
                </div>
              </div>
            );
          })}

          {availableOutputMetaFields?.length > 0 ? (
            <div className="d-flex float-end mt-4">
              <Button
                color="primary"
                size="sm"
                onClick={() => {
                  updateSelectedOutputMetaFields();
                  setIsOpenModal(false);
                }}
              >
                Update
              </Button>
            </div>
          ) : null}

          {isLoading ? <InnerLoader /> : null}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default EffectMeta;
