import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Input,
  FormGroup,
  Label,
  FormFeedback,
  Form,
  Row,
  Col,
  Card,
} from 'reactstrap';

import { alertError, alertSuccess } from '../../../helpers/errorHandling';

import InnerLoader from '../../../components/Common/InnerLoader';
import './style.scss';
import { Link } from 'react-router-dom';

import {
  getCombos,
  createOrUpdateCombo,
  createOrUpdateComboFromCsv,
} from '../../../actions/promotion/combo';

import Dropzone from 'react-dropzone';

const CreateComboFromCsv = ({
  promotionId,
  effectId,
  submitHandler = () => {},
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [csvFile, setCsvFile] = useState(null);

  const createComboFromCsvFunc = async () => {
    try {
      setIsLoading(true);
      const data = new FormData();
      data.append('promotionId', promotionId);
      data.append('effectId', effectId);
      data.append('file', csvFile);

      const { success, message } = await createOrUpdateComboFromCsv(data);

      if (success) {
        alertSuccess(message);
        setTimeout(() => {
          setIsLoading(false);
          setCsvFile(null);
          submitHandler();
          setIsOpenModal(false);
        }, 400);
      } else {
        alertError({ message: message || 'Failed!,  Please try again later' });
        setTimeout(() => {
          setIsLoading(false);
        }, 300);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div
        onClick={() => setIsOpenModal(true)}
        className="btn btn-sm btn-secondary"
      >
        <i className="bx bx-plus fs-14 pe-2"></i>
        Upload CSV
      </div>

      <Modal
        isOpen={isOpenModal}
        toggle={() => {
          setIsOpenModal(!isOpenModal);
        }}
        centered={true}
        size="lg"
        onClosed={() => {}}
      >
        <ModalHeader
          className="modal-title pb-3 bg-light"
          toggle={() => {
            setIsOpenModal(!isOpenModal);
          }}
        >
          Create Combo
        </ModalHeader>
        <ModalBody className="combos-modal">
          <div className="mt-3">
            <Dropzone
              onDrop={(files) => {
                setCsvFile(files?.[0]);
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div className="dropzone d-flex align-items-center">
                  <div
                    className="dz-message cursor-pointer mt-2"
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    <div className="mb-3">
                      <i className="display-4 text-muted bx bxs-cloud-upload" />
                    </div>
                    <h4>Drop files here or click to upload.</h4>
                  </div>
                </div>
              )}
            </Dropzone>
            {csvFile ? (
              <div className="dropzone-previews mt-3" id="file-previews">
                <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col>
                        <Link to="#" className="text-muted font-weight-bold">
                          {csvFile?.name}
                        </Link>
                        <p className="mb-0">
                          <strong>{csvFile?.formattedSize}</strong>
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Card>
              </div>
            ) : null}
          </div>

          <div className="d-flex flex-row justify-content-end  mt-3">
            <Button
              color="dark"
              size="sm"
              onClick={() => {
                setIsOpenModal(false);
              }}
            >
              Close
            </Button>
            <Button
              color="primary"
              size="sm"
              onClick={() => {
                createComboFromCsvFunc();
              }}
              className="ms-2"
              disabled={!csvFile}
            >
              Create
            </Button>
          </div>
          {isLoading ? <InnerLoader /> : null}
        </ModalBody>
      </Modal>
    </>
  );
};

export default CreateComboFromCsv;
