import * as Yup from 'yup';
export const getFieldsValidationSchema = (fields) => {
  try {
    return {
      ...fields?.reduce((schema, field) => {
        const fieldId = field?.values?.find(
          (v) => v?.fieldCode === 'fieldId'
        )?.fieldValue;

        let dataType = field?.values?.find(
          (v) => v?.fieldCode === 'dataType'
        )?.fieldValue;

        let fieldType = field?.values?.find(
          (v) => v?.fieldCode === 'fieldType'
        )?.fieldValue;

        const fieldLabel =
          field?.values?.find((v) => v?.fieldCode === 'configLabel')
            ?.fieldValue || 'This field';

        const isRequired = field?.values?.find(
          (v) => v?.fieldCode === 'isRequired'
        )?.fieldValue;

        dataType = dataType?.toLowerCase();
        fieldType = fieldType?.toLowerCase();

        let validator;
        if (fieldType === 'switch' || dataType === 'boolean') {
          validator = Yup.boolean().typeError(
            `${fieldLabel} must be true or false.`
          );
        } else if (
          fieldType === 'textarea' ||
          fieldType === 'text' ||
          dataType === 'string' ||
          dataType === 'text'
        ) {
          validator = Yup.string().typeError(
            `${fieldLabel} must be a valid text.`
          );
        } else if (fieldType === 'number' || dataType === 'number') {
          validator = Yup.number()
            .typeError(`${fieldLabel} must be a valid number.`)
            .positive('This field must be a positive number');
        } else if (dataType === 'date' || dataType === 'datetime') {
          validator = Yup.date().typeError(
            `${fieldLabel} must be a valid date.`
          );
        } else if (fieldType === 'select' || fieldType === 'radio') {
          validator = Yup.string().typeError(
            `${fieldLabel} must be a valid text.`
          );
        } else if (
          fieldType === 'multiselect' ||
          fieldType === 'multiSelectCheckbox'
        ) {
          validator = Yup.array()
            .of(Yup.string().typeError('Each selection must be a valid text.'))
            .typeError(`${fieldLabel} must be a valid array.`)
            .min(1, `${fieldLabel} must have at least one selection.`);
        }

        if (isRequired === 'true') {
          validator = validator.required(`${fieldLabel} is required.`);
        }

        if (fieldType === 'textarea') {
          validator = validator
            .max(500, 'Description must be at most 500 characters')
            .min(5, 'Description must be at least 5 characters');
        }

        if (fieldType === 'text') {
          validator = validator
            .max(30, 'Description must be at most 30 characters')
            .min(3, 'Description must be at least 3 characters');
        }

        return { ...schema, [fieldId]: validator };
      }, {}),
    };
  } catch (e) {
    console.log(e);
    return {};
  }
};

export const getFieldsInitialValues = (fields) => {
  try {
    return {
      ...fields?.reduce((values, field) => {
        const fieldId = field?.values?.find(
          (v) => v?.fieldCode === 'fieldId'
        )?.fieldValue;
        return { ...values, [fieldId]: '' };
      }, {}),
    };
  } catch (e) {
    console.log(e);
    return {};
  }
};

export const convertInputMetaToFields = (inputMeta) => {
  try {
    const output = inputMeta?.reduce((result, { groupName, key, value }) => {
      result[groupName] = result[groupName] || {};
      result[groupName][key] = value;
      return result;
    }, {});

    return output;
  } catch (e) {
    console.log(e);
  }
};

export const convertFieldsToInputMeta = (output) => {
  try {
    const inputMeta = Object?.keys(output)?.reduce((result, groupName) => {
      const group = output[groupName];

      Object?.keys(group)?.forEach((key) => {
        result?.push({
          groupName: groupName,
          key: key,
          value: group[key],
        });
      });

      return result;
    }, []);

    return inputMeta;
  } catch (e) {
    console.log(e);
  }
};
