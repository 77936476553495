import React from 'react';
import {
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
} from 'reactstrap';
import { If, Then } from 'react-if';
import EffectTransactionUnitBased from './EffectTransactionUnitBased';
import { DISCOUNT_TYPES } from '../../../constants';
import EffectTransactionConditional from './EffectTransactionConditional';
import Combo from '../Combo';
import { useSelector } from 'react-redux';
export default function EffectTransaction({
  effect,
  effectChangeHandler,
  eIndex,
  tIndex,
  discountTypes,
  promotionId,
}) {
  const { itemFields } = useSelector((state) => state.promotions || {});
  // console.log('fields', itemFields);
  return (
    <>
      <FormGroup>
        <Label for="discount-type-select">Discount Type</Label>
        <Input
          type="select"
          id="discount-type-select"
          className="form-select"
          value={effect?.discountType}
          onChange={(e) => {
            effectChangeHandler(
              'discountType',
              e?.target?.value,
              eIndex,
              tIndex
            );
          }}
        >
          <option value={''}>
            -- Choose{' '}
            {effect.effectType === 'transactions' ? 'Discount' : 'Reward'}{' '}
            Calculation Type --
          </option>
          {discountTypes
            ?.filter((item) => item.type.includes(effect.effectType))
            .map((item, index) => (
              <option value={item?.value} key={`dt-${index}`}>
                {item?.label}
              </option>
            ))}
        </Input>
      </FormGroup>
      <If condition={effect.discountType === 'dynamicGroup'}>
        <Then>
          <FormGroup>
            <Label for="discount-type-select">Choose a item field</Label>
            <Input
              type="select"
              id="discount-type-select"
              className="form-select"
              value={effect?.dynamicGroupField}
              onChange={(e) => {
                effectChangeHandler(
                  'dynamicGroupField',
                  e?.target?.value,
                  eIndex,
                  tIndex
                );
              }}
            >
              {itemFields.map((item, index) => (
                <option value={item?.fieldCode} key={`${item.fieldCode}`}>
                  {item?.fieldName}
                </option>
              ))}
            </Input>
          </FormGroup>
        </Then>
      </If>

      <If
        condition={
          effect?.combinedUnitBasedDiscount === true &&
          effect.discountType === 'unitBased'
        }
      >
        <Then>
          <>
            <FormGroup>
              <Label for="discount-type-select">Discount Type</Label>
              <Input
                type="select"
                id="discount-type-select"
                value={effect?.combinedDiscountType}
                onChange={(e) => {
                  effectChangeHandler(
                    'combinedDiscountType',
                    e?.target?.value,
                    eIndex,
                    tIndex
                  );
                  // handleValueChange(index, e.target.value, 'discountType');
                }}
              >
                {DISCOUNT_TYPES?.filter((dis) =>
                  dis.type.includes(effect.effectType)
                ).map((item, idx) => (
                  <option value={item?.value} key={`dt-${idx}`}>
                    {item?.label}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="combined-discount-value">Discount Value</Label>
              <Input
                id="combined-discount-value"
                value={`${effect?.combinedDiscountValue}`}
                label="Discount Value"
                type="number"
                size="small"
                required
                style={{
                  width: '100%',
                }}
                LabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  effectChangeHandler(
                    'combinedDiscountValue',
                    Number(e?.target?.value),
                    eIndex,
                    tIndex
                  );
                }}
              />
            </FormGroup>
            <If condition={effect?.combinedDiscountType === 'percentage'}>
              <Then>
                <FormGroup>
                  <Label for="combinedMaxValue">Maximum Value</Label>
                  <Input
                    id="combinedMaxValue"
                    value={`${effect?.combinedMaxValue}`}
                    label="Maximum Limit"
                    type="number"
                    size="small"
                    required
                    style={{
                      width: '100%',
                    }}
                    LabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      effectChangeHandler(
                        'combinedMaxValue',
                        Number(e?.target?.value),
                        eIndex,
                        tIndex
                      );
                    }}
                  />
                </FormGroup>
              </Then>
            </If>
          </>
        </Then>
      </If>
      <If condition={effect.applyLimitedCombination}>
        <Then>
          <FormGroup>
            <Label for="unitBasedLimitedCombination">Limited Combination</Label>
            <Input
              id="unitBasedLimitedCombination"
              value={`${effect?.unitBasedLimitedCombination}`}
              label="Limited Combination"
              type="number"
              size="small"
              required
              style={{
                width: '100%',
              }}
              LabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                effectChangeHandler(
                  'unitBasedLimitedCombination',
                  Number(e?.target?.value),
                  eIndex,
                  tIndex
                );
              }}
            />
          </FormGroup>
        </Then>
      </If>
      <If
        condition={
          effect?.useSegmentedMaxDiscount === true &&
          (effect.discountType === 'segmented' ||
            effect?.discountType === 'dynamicGroup')
        }
      >
        <Then>
          <>
            <FormGroup>
              <Label for="segmentedMaxDiscount">Maximum Value</Label>
              <Input
                id="segmentedMaxDiscount"
                value={`${effect?.segmentedMaxDiscount}`}
                label="Maximum Limit"
                type="number"
                size="small"
                required
                style={{
                  width: '100%',
                }}
                LabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  effectChangeHandler(
                    'segmentedMaxDiscount',
                    Number(e?.target?.value),
                    eIndex,
                    tIndex
                  );
                }}
              />
            </FormGroup>
          </>
        </Then>
      </If>
      <If condition={effect?.discountType === 'unitBased'}>
        <Then>
          <div className="mt-n3 w-100">
            <FormGroup check inline className="">
              <Input
                id="combinedUnitBasedDiscount"
                type="checkbox"
                checked={effect?.combinedUnitBasedDiscount}
                // color="primary"
                onChange={() => {
                  effectChangeHandler(
                    'combinedUnitBasedDiscount',
                    effect?.combinedUnitBasedDiscount ? false : true,
                    eIndex,
                    tIndex
                  );
                }}
                // size="sm"
              ></Input>
              <Label for="combinedUnitBasedDiscount">
                Apply Combined Discount
              </Label>
            </FormGroup>
            <FormGroup check inline className="">
              <Input
                id={`applyLimitedCombination_${effect.id}`}
                type="checkbox"
                checked={effect?.applyLimitedCombination}
                // color="primary"
                onChange={() => {
                  effectChangeHandler(
                    'applyLimitedCombination',
                    effect?.applyLimitedCombination ? false : true,
                    eIndex,
                    tIndex
                  );
                }}
                // size="sm"
              ></Input>
              <Label for={`applyLimitedCombination_${effect.id}`}>
                Apply Limited Combination
              </Label>
            </FormGroup>
          </div>
        </Then>
      </If>
      <If
        condition={
          effect?.discountType === 'segmented' ||
          effect?.discountType === 'dynamicGroup'
        }
      >
        <Then>
          <>
            <div className="mt-n3 w-100">
              <FormGroup check inline className="">
                <Input
                  id="useSegmentedMaxDiscount"
                  type="checkbox"
                  checked={effect?.useSegmentedMaxDiscount}
                  // color="primary"
                  onChange={() => {
                    effectChangeHandler(
                      'useSegmentedMaxDiscount',
                      effect?.useSegmentedMaxDiscount ? false : true,
                      eIndex,
                      tIndex
                    );
                  }}
                  // size="sm"
                ></Input>
                <Label for="useSegmentedMaxDiscount">
                  Apply Maximum Discount For all segments
                </Label>
              </FormGroup>
            </div>
            <div className=" w-100">
              <FormGroup check inline className="">
                <Input
                  id={`{weightedDiscountOnEachSegment${eIndex}-${tIndex}`}
                  type="checkbox"
                  checked={effect?.weightedDiscountOnEachSegment}
                  // color="primary"
                  onChange={() => {
                    effectChangeHandler(
                      'weightedDiscountOnEachSegment',
                      effect?.weightedDiscountOnEachSegment ? false : true,
                      eIndex,
                      tIndex
                    );
                  }}
                  // size="sm"
                ></Input>
                <Label
                  for={`{weightedDiscountOnEachSegment${eIndex}-${tIndex}`}
                >
                  Apply Weighted Discount on Each Group
                </Label>
              </FormGroup>
            </div>
          </>
        </Then>
      </If>
      <If
        condition={
          effect?.discountType === 'percentage' ||
          effect?.discountType === 'fixed'
        }
      >
        <Then>
          <>
            <If condition={!effect?.conditionalDiscounts}>
              <Then>
                <>
                  <FormGroup>
                    <Label for="discount-value-number">Discount Value</Label>
                    <InputGroup>
                      <Input
                        type="number"
                        id="discount-value-number"
                        value={effect?.discountValue}
                        onChange={(e) => {
                          effectChangeHandler(
                            'discountValue',
                            Number(e?.target?.value),
                            eIndex,
                            tIndex
                          );
                        }}
                        required
                        className="form-control"
                      />
                      {/* {effect?.discountType === "percentage" && (
                <InputGroupAddon addonType="append">
                  <InputGroupText>%</InputGroupText>
                </InputGroupAddon>
              )} */}
                    </InputGroup>
                  </FormGroup>
                </>
              </Then>
            </If>

            <div className="w-100">
              <FormGroup check inline className="">
                <Input
                  id="conditionalDiscounts"
                  type="checkbox"
                  checked={effect?.conditionalDiscounts}
                  // color="primary"
                  onChange={() => {
                    effectChangeHandler(
                      'conditionalDiscounts',
                      effect?.conditionalDiscounts ? false : true,
                      eIndex,
                      tIndex
                    );
                  }}
                  // size="sm"
                ></Input>
                <Label for="conditionalDiscounts">
                  Use Conditional Discounts
                </Label>
              </FormGroup>
            </div>
            {effect?.conditionalDiscounts && (
              <div className="w-100">
                <EffectTransactionConditional
                  onChange={effectChangeHandler}
                  eIndex={eIndex}
                  tIndex={tIndex}
                  effect={effect}
                />
              </div>
            )}
          </>
        </Then>
      </If>
      <If
        condition={
          effect?.discountType === 'percentage' ||
          effect?.discountType === 'fixed' ||
          effect?.discountType === 'formula'
        }
      >
        <Then>
          <div className="">
            <Label for="max-limit">Maximum Limit</Label>
            <Input
              id="max-limit"
              value={`${effect?.maxLimit}`}
              label="Maximum Limit"
              type="number"
              size="small"
              required
              style={{
                width: '100%',
              }}
              LabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                effectChangeHandler(
                  'maxLimit',
                  Number(e?.target?.value),
                  eIndex,
                  tIndex
                );
              }}
            />
          </div>
        </Then>
      </If>
      <If condition={effect.discountType === 'combo'}>
        <Then>
          <>
            <div className="d-flex w-100">
              <Combo
                promotionId={promotionId}
                effectId={effect?.id}
                effect={effect}
                // handleComboItemClick={handleComboItemClick}
              />
            </div>
          </>
        </Then>
      </If>
      {(effect?.discountType === 'unitBased' ||
        effect?.discountType === 'segmented' ||
        effect?.discountType === 'dynamicGroup' ||
        effect?.discountType === 'gift') && (
        <>
          <EffectTransactionUnitBased
            onChange={effectChangeHandler}
            eIndex={eIndex}
            tIndex={tIndex}
            effect={effect}
          />
        </>
      )}
    </>
  );
}
