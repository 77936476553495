import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { alertError, alertSuccess } from '../../../helpers/errorHandling';
import {
  getFieldsValidationSchema,
  getFieldsInitialValues,
  convertFieldsToInputMeta,
  convertInputMetaToFields,
} from '../FieldsForm/FieldsFormConfig';
import FieldsForm from '../FieldsForm';
import { updatePromotionMeta } from '../../../actions/promotion';
import { Button, Form, ModalFooter } from 'reactstrap';
import InnerLoader from '../../../components/Common/InnerLoader';
import { configTypes } from '../../Configuration/General/constants';

const InputMetaForm = ({
  promotionDetail = null,
  onCancel = () => {},
  updateHandler = () => {},
  fields = [],
  fieldGroup = 'otherFields',
  locales = [],
}) => {
  useEffect(() => {
    if (promotionDetail) {
      const res = convertInputMetaToFields(promotionDetail?.messages);
      formik.setValues({
        promotionId: promotionDetail?._id || '',
        [`${fieldGroup}`]: {
          ...getFieldsInitialValues(fields),
          ...(res?.[`${fieldGroup}`] || {}),
        },
      });
    }
  }, [promotionDetail]);

  const inputMetaSchema = Yup?.object()?.shape({
    [`${fieldGroup}`]: Yup?.object()?.shape({
      ...getFieldsValidationSchema(fields),
    }),
  });

  const formik = useFormik({
    initialValues: {
      promotionId: promotionDetail?._id || '',
      [`${fieldGroup}`]: {
        ...getFieldsInitialValues(fields),
      },
    },
    // validationSchema: inputMetaSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        const { promotionId, ...rest } = values;

        const inputMeta = [
          ...(promotionDetail?.messages?.filter(
            (item) => item?.groupName !== fieldGroup
          ) || []),
          ...(convertFieldsToInputMeta(rest) || []),
        ];

        const { success } = await updatePromotionMeta({
          promotionId: promotionId,
          messages: inputMeta,
        });
        if (success) {
          alertSuccess('Updated successfully.');
          updateHandler();
        } else {
          alertError('Failed! Please try again later.');
        }
      } catch (error) {
        console.log(error, 'error');
        alertError('Failed! Please try again later.');
      } finally {
        setSubmitting(false);
      }
    },
  });

  const { handleSubmit, isSubmitting } = formik;

  if (fields?.length === 0) {
    return null;
  }

  return (
    <div className="p-3 border-bottom border-start border-end ">
      {fieldGroup ? (
        <h5 className="mb-3 text-capitalize">{fieldGroup}</h5>
      ) : null}
      <FormikProvider value={formik}>
        <Form autoComplete="off" onSubmit={handleSubmit}>
          <FieldsForm
            fieldConfigs={fields || []}
            formik={formik}
            parentField={`${fieldGroup}`}
          />
          <div className="d-flex gap-2 justify-content-end">
            <Button outline color="danger" size="sm" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              loading={false}
              type="submit"
              color="primary"
              disabled={isSubmitting}
              size="sm"
              className="bg-gradient"
            >
              Save
            </Button>
          </div>
          {isSubmitting ? <InnerLoader /> : null}
        </Form>
      </FormikProvider>
    </div>
  );
};

InputMetaForm.propTypes = {
  promotionDetail: PropTypes.any,
  onCancel: PropTypes.func,
  updateHandler: PropTypes.func,
  fields: PropTypes.array,
  fieldGroup: PropTypes.string,
  locales: PropTypes.array,
};

export default InputMetaForm;
