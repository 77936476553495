import { PROMOTION_API_SERVICE_BASE_URL } from '../../../constants';
import promoRestService from '../../../services/PromoRestService';
import { setGlobalRules } from '../../../slices/dashboard/reducer';

export const saveGlobalRuleGroup = async (ruleGroupData, dispatch) => {
  const apiUrl = `${PROMOTION_API_SERVICE_BASE_URL}rulegroup/save`;

  try {
    const response = await promoRestService.post(apiUrl, ruleGroupData);
    console.log('Rule group saved successfully:', response.data);
    if (dispatch) {
      listGlobalRuleGroup({ type: ruleGroupData.selectedGroupType }, dispatch);
    }
    return response.data;
  } catch (error) {
    console.error('Error saving rule group:', error);
    throw error;
  }
};

export const listGlobalRuleGroup = async ({ type }, dispatch) => {
  const apiUrl = `${PROMOTION_API_SERVICE_BASE_URL}rulegroup/list`;

  try {
    const response = await promoRestService.post(apiUrl, { type });
    // console.log('Rule group list successfully:', response.data);
    if (dispatch) {
      dispatch(setGlobalRules(response.data));
    }
    return response.data;
  } catch (error) {
    console.error('Error saving rule group:', error);
    throw error;
  }
};
