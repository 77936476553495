import React, { useEffect, useState } from 'react';
import {
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
const OptionValues = ({ onChange = () => {}, ...rest }) => {
  const [options, setOptions] = useState([]);

  const handleLabelChange = (index, value) => {
    const updatedOptions = [...options];
    updatedOptions[index].label = value;
    setOptions(updatedOptions);
  };

  const handleValueChange = (index, value) => {
    const updatedOptions = [...options];
    updatedOptions[index].value = value;
    setOptions(updatedOptions);
  };

  const handleAddOption = () => {
    setOptions([...options, { label: '', value: '' }]);
  };

  const handleRemoveOption = (index) => {
    const updatedOptions = [...options];
    updatedOptions.splice(index, 1);
    setOptions(updatedOptions);
  };

  useEffect(() => {
    onChange(options);
  }, [options]);

  useEffect(() => {
    if (typeof rest?.value === 'string') {
      const parsedOptions = JSON.parse(rest?.value);
      setOptions(parsedOptions || []);
    } else {
      setOptions(rest.value || []);
    }
  }, [rest.value]);

  return (
    <>
      {options?.map((option, index) => (
        <>
          <Row className="align-items-end">
            <Col xs={5}>
              <FormGroup>
                <Label for="field-fieldGroup">Label</Label>
                <Input
                  label="Label"
                  value={option.label}
                  onChange={(e) => handleLabelChange(index, e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col xs={5}>
              <FormGroup>
                <Label for="field-fieldGroup">Value</Label>
                <Input
                  label="Value"
                  value={option.value}
                  onChange={(e) => handleValueChange(index, e.target.value)}
                />
              </FormGroup>
            </Col>

            <Col>
              <FormGroup>
                <Button
                  size="sm"
                  color="danger"
                  onClick={() => handleRemoveOption(index)}
                >
                  <i className="bx bx-trash fs-14"></i>
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </>
      ))}
      <div className="hstack gap-2 justify-content-start">
        <Button size="sm" color="primary" onClick={handleAddOption}>
          Add Option
        </Button>
      </div>
    </>
  );
};

export default OptionValues;
