import React, { useEffect, useState } from 'react';
import BreadCrumb from '../../../components/Common/BreadCrumb';
import { getRedemptionDetails } from '../../../actions/promotion/redemption';
import { useParams } from 'react-router-dom';

const PromotionRedemptionDetails = ({ redemption }) => {
  const [redemptionDetails, setRedemptionDetails] = useState(null);

  useEffect(() => {
    getRedemptionDetailsFunc();
  }, [redemption]);

  const getRedemptionDetailsFunc = async () => {
    const res = await getRedemptionDetails({
      _id: redemption?._id,
    });

    setRedemptionDetails(res);
  };

  const { user, request, response, transactionRef, status } =
    redemptionDetails || {};

  console.log(redemptionDetails, 'redemptionDetails');

  return (
    redemptionDetails && (
      <div>
        {/* <h4 className="card-title mb-4">Promotion Redemption Details</h4> */}
        <div className="row">
          <div className="col-md-7">
            <div className="mb-4 border p-3">
              <h5>Products in Cart</h5>
              <div>
                {request?.items?.map((product) => {
                  const resItem = response?.items?.find(
                    (item) => item.id === product.id
                  );
                  return (
                    <div className="col-sm-12 mb-4 w-100" key={product.id}>
                      <div className="d-flex flex-row">
                        <img
                          className="flex-shrink-0"
                          style={{ width: '100px', height: '100px' }}
                          src={product.image}
                          alt={product.name}
                        />
                        <div className="d-flex flex-row justify-content-between align-items-center w-100 px-2">
                          <div className="d-flex flex-column w-50">
                            <h6 className="p-0">{product.name}</h6>
                            <span>SKU - {product?.id}</span>
                            <span>
                              <span
                                style={{
                                  textDecoration: 'line-through',
                                  marginRight: '10px',
                                  color: '#6c757d',
                                }}
                              >
                                AED {product._ap}
                              </span>
                              <strong>AED {product._sp}</strong>
                            </span>
                          </div>
                          <div className="d-flex flex-column">
                            <span>QTY - {product?._qty}</span>

                            <span>
                              Sub Total - AED{' '}
                              {resItem?.result?.itemSubTotal?.toFixed(2)}
                            </span>

                            <span>
                              Total Discount -{' '}
                              <strong
                                style={{
                                  color: '#28a745',
                                }}
                                className="font-weight-bold"
                              >
                                AED{' '}
                                {resItem?.result?.discountAmount?.toFixed(2)}
                              </strong>
                            </span>
                            <span>
                              Grand Total -
                              <strong>
                                {' '}
                                AED{' '}
                                {resItem?.result?.itemGrandTotal?.toFixed(2)}
                              </strong>
                            </span>
                          </div>

                          {/* {resItem?.result?.discountList?.map((ds) => {
                                return (
                                  <span>
                                    <span>
                                      {ds?.promotionName} - AED{' '}
                                      {ds?.discountAmount?.toFixed(2)}
                                    </span>
                                  </span>
                                );
                              })} */}
                          {/* {resItem?.result?.rewardList?.map((rs) => {
                                return (
                                  <span>
                                    <span>
                                      {rs?.promotionName} ({rs?.rewardTypeId}) -{' '}
                                      {rs?.points?.toFixed(2)} Pts
                                    </span>
                                  </span>
                                );
                              })} */}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="border p-3">
              <div className="d-flex flex-column gap-2">
                <span>
                  <strong>Email:</strong> {user?._em}
                </span>
                <span>
                  <strong>Phone:</strong> {user?._ph}
                </span>
                <span>
                  <strong>Segment:</strong> {user?.segment}
                </span>
              </div>
            </div>
          </div>

          <div className="col-md-5">
            <div className="d-flex flex-column mb-2 text-capitalize">
              <span>
                Ref: <strong>#{transactionRef}</strong>
              </span>
              <span>
                status - <strong>{status}</strong>
              </span>
            </div>

            <div>
              <h6 className="">Order Summary</h6>

              <div className="d-flex justify-content-between mb-2">
                <span>Subtotal:</span>{' '}
                <strong>AED {response?.summary?.subTotal}</strong>
              </div>

              <div className="d-flex justify-content-between mb-2">
                <span>Total Discount:</span>{' '}
                <strong
                  style={{
                    color: '#28a745',
                  }}
                  className="font-weight-bold"
                >
                  - AED {response?.summary?.totalDiscount}
                </strong>
              </div>

              <div className="d-flex justify-content-between mb-2">
                <span>Final Value:</span>{' '}
                <strong>AED {response?.summary?.finalValue}</strong>
              </div>
            </div>

            <div>
              <h6 className="">Discount Summary</h6>
              {response?.summary?.discountSummary?.map((ds) => {
                return (
                  <div className="d-flex justify-content-between mb-2">
                    <span>{ds?.summaryTitle}</span>{' '}
                    <strong>AED {ds?.discountAmount}</strong>
                  </div>
                );
              })}
            </div>

            <div>
              <h6>Reward Summary</h6>
              {response?.summary?.rewardSummary?.map((rs) => {
                return (
                  <div className="d-flex justify-content-between mb-2">
                    <span>
                      {rs?.summaryTitle} - {rs?.rewardTypeId}
                    </span>{' '}
                    <strong>{rs?.points?.toFixed(2)} Pts</strong>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default PromotionRedemptionDetails;
