import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';

import { listGlobalRuleGroup } from './action';
import { useDispatch } from 'react-redux';

const GlobalRuleGroupList = ({
  isOpen = false,
  toggle,
  type,
  currentQuery: query,
  setQuery,
  level,
  path,
}) => {
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const fetchList = async () => {
    const result = await listGlobalRuleGroup({ type }, dispatch);
    setList(result);
  };
  const [selectedGroupRuleId, setSelectedRuleGroupId] = useState();
  useEffect(() => {
    if (isOpen) {
      fetchList();
    }
  }, [isOpen]);
  const handleImport = async () => {
    //   query,
    // setQuery,

    const newQuery = { ...query, rules: [...query.rules] };

    newQuery.rules.push({
      id: 'dcc8cfb4-7a89-496d-ac5e-8f1c880d645c', //TOBE FIXED
      type: 'globalGroup',
      globalGroupId: selectedGroupRuleId,
    });
    setQuery(newQuery);
    // try {
    //   await saveGlobalRuleGroup(payload);
    //   toggle(); // Close the modal on successful save
    // } catch (error) {
    //   console.error('Error saving global rule group:', error);
    // }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Global Rule Group</ModalHeader>
      <ModalBody>
        <Form>
          {list.map((group, index) => (
            <FormGroup key={index} check>
              <Label check>
                <Input
                  type="radio"
                  name="ruleGroup"
                  value={group.groupName}
                  checked={selectedGroupRuleId === group._id}
                  onChange={() => {
                    setSelectedRuleGroupId(group._id);
                  }}
                />
                {group.groupName} ({group.selectedGroupType})
              </Label>
            </FormGroup>
          ))}
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleImport}>
          Choose
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default GlobalRuleGroupList;
