import { useState } from 'react';
import {
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col,
  ModalFooter,
  FormFeedback,
} from 'reactstrap';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { autoGenerateCoupons } from '../../../actions/promotion';
import Flatpickr from 'react-flatpickr';
import InnerLoader from '../../../components/Common/InnerLoader';
import { alertError, alertSuccess } from '../../../helpers/errorHandling';
import { discountTypes } from './constants';

const couponLetterTypes = [
  {
    label: 'Alphanumeric',
    value: 'alphanumeric',
  },
  {
    label: 'Alphabetic',
    value: 'alphabetic',
  },
  {
    label: 'Numeric',
    value: 'numeric',
  },
];

const AutoGenerateCoupons = ({
  onCancel = () => {},
  getStores = () => {},
  setCoupons,
  coupons,
  setPageOffset,
  setTotalPages,
  setIsRefreshTable,
  promotionId,
  promotionDetail,
}) => {
  const autoCouponConfigSchema = Yup?.object()?.shape({
    length: Yup.number()
      .required('Coupon length is required')
      .min(1, 'Must be at least 1'),
    noOfCoupons: Yup.number()
      .required('Number of coupons is required')
      .min(1, 'Must be at least 1'),
    type: Yup.string().required('Letter type is required'),
    prefix: Yup.string().optional(),
    suffix: Yup.string().optional(),

    isUseLauncherConfig: Yup.boolean().required('This field is required'),
    isUseConditionsAndTriggers: Yup.boolean().required(
      'This field is required'
    ),
  });

  const formik = useFormik({
    initialValues: {
      length: '',
      type: 'alphanumeric',
      prefix: '',
      suffix: '',
      startsAt: null,
      expiresAt: null,
      redeemableQuantity: null,
      noOfCoupons: 1,
      isUseLauncherConfig: true,
      isUseConditionsAndTriggers: true,
      discountType: 'fixed',
    },
    validationSchema: autoCouponConfigSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const variables = {
          input: {
            promotionId: promotionId,
            ...values,
          },
        };

        const { success, message, data } = await autoGenerateCoupons(variables);
        if (success) {
          setCoupons([...data?.coupons]);
          setPageOffset(data?.pageOffset);
          setTotalPages(data?.totalPages);
          setIsRefreshTable(true);
          alertSuccess('Coupons generated successfully.');
        } else {
          alertError({ message: message });
        }

        setSubmitting(false);
        onCancel();
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
    values,
  } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={handleSubmit}>
        <Row spacing={3} className="mt-4">
          <Col xl={2} lg={2} md={3} sm={12} xs={12}>
            <FormGroup>
              <Label for="length">Length of Coupon Code</Label>
              <Input
                type="number"
                id="length"
                name="length"
                placeholder="Coupon length"
                min={1}
                {...getFieldProps('length')}
                invalid={touched.length && !!errors.length}
              />
              <FormFeedback>{errors.length}</FormFeedback>
            </FormGroup>
          </Col>
          <Col xl={2} lg={2} md={3} sm={12} xs={12}>
            <FormGroup>
              <Label for="noOfCoupons">Number of Coupons</Label>
              <Input
                type="number"
                id="noOfCoupons"
                name="noOfCoupons"
                placeholder="Number of Coupons"
                min={1}
                {...getFieldProps('noOfCoupons')}
                invalid={touched.noOfCoupons && !!errors.noOfCoupons}
              />
              <FormFeedback>{errors.noOfCoupons}</FormFeedback>
            </FormGroup>
          </Col>
          <Col xl={3} lg={3} md={3} sm={12} xs={12}>
            <FormGroup>
              <Label for="type">Letters type</Label>
              <Input
                type="select"
                id="type"
                name="type"
                {...getFieldProps('type')}
                invalid={touched.type && !!errors.type}
              >
                {couponLetterTypes.map((type) => (
                  <option key={type.value} value={type.value}>
                    {type.label}
                  </option>
                ))}
              </Input>
              <FormFeedback>{errors.type}</FormFeedback>
            </FormGroup>
          </Col>
          <Col xl={3} lg={3} md={3} sm={12} xs={12}>
            <FormGroup>
              <Label for="prefix">Prefix</Label>
              <Input
                type="text"
                id="prefix"
                name="prefix"
                placeholder="Prefix"
                {...getFieldProps('prefix')}
              />
            </FormGroup>
          </Col>
          <Col xl={2} lg={2} md={3} sm={12} xs={12}>
            <FormGroup>
              <Label for="suffix">Suffix</Label>
              <Input
                type="text"
                id="suffix"
                name="suffix"
                placeholder="Suffix"
                {...getFieldProps('suffix')}
              />
            </FormGroup>
          </Col>

          <Row>
            <Col xl={3} lg={3} md={3} sm={12} xs={12}>
              <div className="form-check form-switch form-switch-custom form-switch-dark mb-2 mt-2 d-flex gap-2 align-items-center p-0">
                <Label
                  className="form-check-label"
                  htmlFor="isUseLauncherConfig"
                >
                  Use Launcher Config
                </Label>
                <Input
                  type="checkbox"
                  className="form-check-input m-0"
                  id="isUseLauncherConfig"
                  {...getFieldProps('isUseLauncherConfig')}
                  checked={formik.values.isUseLauncherConfig}
                />
              </div>
            </Col>
          </Row>

          {!values.isUseLauncherConfig ? (
            <Row spacing={3} className="mt-2">
              <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                <FormGroup>
                  <Label for="startsAt">Start Date</Label>
                  <Flatpickr
                    className="form-control"
                    id="startsAt"
                    name="startsAt"
                    placeholder="Start Date"
                    options={{
                      enableTime: true,
                      dateFormat: 'Y-m-d h:i K',
                      time_24hr: false,
                    }}
                    onChange={(date) => setFieldValue('startsAt', date[0])}
                  />
                  {touched.startsAt && errors.startsAt && (
                    <div className="text-danger">{errors.startsAt}</div>
                  )}
                </FormGroup>
              </Col>
              <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                <FormGroup>
                  <Label for="expiresAt">End Date</Label>
                  <Flatpickr
                    className="form-control"
                    id="expiresAt"
                    name="expiresAt"
                    placeholder="End Date"
                    options={{
                      enableTime: true,
                      dateFormat: 'Y-m-d h:i K',
                      time_24hr: false,
                    }}
                    onChange={(date) => setFieldValue('expiresAt', date[0])}
                  />
                  {touched.expiresAt && errors.expiresAt && (
                    <div className="text-danger">{errors.expiresAt}</div>
                  )}
                </FormGroup>
              </Col>
              <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                <FormGroup>
                  <Label for="redeemableQuantity">Redeemable Quantity</Label>
                  <Input
                    type="number"
                    id="redeemableQuantity"
                    name="redeemableQuantity"
                    placeholder="Redeemable Quantity"
                    min={0}
                    {...getFieldProps('redeemableQuantity')}
                    invalid={
                      touched.redeemableQuantity && !!errors.redeemableQuantity
                    }
                  />
                  <FormFeedback>{errors.redeemableQuantity}</FormFeedback>
                </FormGroup>
              </Col>
            </Row>
          ) : null}

          <Row>
            <Col xl={3} lg={3} md={3} sm={12} xs={12}>
              <div className="form-check form-switch form-switch-custom form-switch-dark mb-2 mt-2 d-flex gap-2 align-items-center p-0">
                <Label
                  className="form-check-label"
                  htmlFor="isUseConditionsAndTriggers"
                >
                  Use conditions and triggers
                </Label>
                <Input
                  type="checkbox"
                  className="form-check-input m-0"
                  id="isUseConditionsAndTriggers"
                  {...getFieldProps('isUseConditionsAndTriggers')}
                  checked={formik.values.isUseConditionsAndTriggers}
                />
              </div>
            </Col>
          </Row>

          {!values?.isUseConditionsAndTriggers ? (
            <Col xl={3} lg={3} md={3} sm={12} xs={12}>
              <FormGroup>
                <Label for="type">Discount Type</Label>
                <Input
                  type="select"
                  id="discountType"
                  name="discountType"
                  {...getFieldProps('discountType')}
                  invalid={touched?.discountType && !!errors?.discountType}
                >
                  {discountTypes?.map((item) => (
                    <option key={item?.value} value={item?.value}>
                      {item?.label}
                    </option>
                  ))}
                </Input>
                <FormFeedback>{errors?.discountType}</FormFeedback>
              </FormGroup>
            </Col>
          ) : null}
        </Row>

        <Row className="mt-2">
          <Col></Col>
        </Row>
        <ModalFooter>
          <Button
            className="btn btn-light py-1 px-4"
            size="md"
            data-bs-dismiss="modal"
            onClick={onCancel}
          >
            Close
          </Button>
          <Button
            color="secondary"
            className="py-1 px-4"
            size="md"
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Generating...' : 'Generate'}
          </Button>
        </ModalFooter>
      </Form>
      {isSubmitting && <InnerLoader />}
    </FormikProvider>
  );
};

export default AutoGenerateCoupons;
