import React, { useState, useEffect } from 'react';
import { CardBody } from 'reactstrap';
import InputMetaForm from './InputMetaForm';
import { getAllConfigs } from '../../../actions/promotion/generals';

const PromotionMessagesAndNotifications = ({ promotionDetail = {} }) => {
  const [inputMetaFields, setInputMetaFields] = useState([]);
  const [groupedFields, setGroupedFields] = useState({});
  const [availableLocales, setAvailableLocales] = useState([]);

  useEffect(() => {
    getInputMetaFields();
    getLanguages();
  }, []);

  const getInputMetaFields = async () => {
    try {
      const { data } = await getAllConfigs({
        configName: 'input-meta',
        configEnv: 'development',
        pageOffset: 0,
        pageSize: 2000,
      });

      const groupedData = data?.reduce((result, item) => {
        item?.values?.forEach(({ fieldCode, fieldValue }) => {
          if (fieldCode === 'fieldGroup') {
            const group = fieldValue;
            if (!result[group]) {
              result[group] = [];
            }
            result[group].push(item);
          }
        });
        return result;
      }, {});

      setTimeout(() => {
        setInputMetaFields(data);
        setGroupedFields({ ...groupedData });
      }, 400);
    } catch (e) {
      console.log(e);
    }
  };

  const getLanguages = async () => {
    try {
      const { data } = await getAllConfigs({
        configName: 'languages',
        configEnv: 'development',
        pageOffset: 0,
        pageSize: 2000,
      });

      const result = data?.map((item) => {
        const name =
          item?.values?.find((v) => v?.fieldCode === 'name')?.fieldValue || '';
        const code =
          item?.values?.find((v) => v?.fieldCode === 'code')?.fieldValue || '';
        return { name, code };
      });

      setAvailableLocales(result);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <CardBody>
      {Object?.keys(groupedFields)?.map((group, index) => (
        <InputMetaForm
          key={index}
          promotionDetail={promotionDetail}
          fields={groupedFields[group]}
          fieldGroup={group}
          locales={availableLocales}
        />
      ))}
    </CardBody>
  );
};

export default PromotionMessagesAndNotifications;
