import React, { useEffect, useState } from 'react';
import InnerLoader from '../../../components/Common/InnerLoader';
import { formatDate } from '../../../helpers/formatDate';
import { formatTime } from '../../../helpers/formatTime';
import { getPromotionLauncherList } from '../../../actions/promotion/launcher';
import CustomPagination from '../CustomPagination';
const PromotionLauncherList = ({ promotionId, launcherDetails }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [pageOffset, setPageOffset] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [launchers, setLaunchers] = useState([]);
  const [pageSize, setPageSize] = useState(8);

  const getLaunchersFunc = async ({ offset = pageOffset } = {}) => {
    try {
      setIsLoading(true);
      const data = {
        pageOffset: offset,
        pageSize: pageSize,
        promotionId: promotionId,
      };
      const res = await getPromotionLauncherList(data);

      setTotalPages(res?.data?.totalPages);
      setLaunchers(res?.data?.launchers || []);
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (launcherDetails?._id) {
      getLaunchersFunc();
    }
  }, [launcherDetails]);

  const paginationHandler = (offset) => {
    getLaunchersFunc({ offset });
  };

  return (
    <div className="col-8 p-4 ms-3 me-3">
      <div className="table-responsive table-card ">
        <table className="table table-nowrap table-centered align-middle">
          <thead className="bg-light text-muted">
            <tr>
              <th>Name</th>
              <th>Starts At</th>
              <th>Ends At</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {(launchers || [])?.map((launcher, key) => (
              <tr key={key}>
                <td>
                  <div className="d-flex align-items-center">
                    <div>
                      <h5 className="fs-13 mb-1">{launcher?.name}</h5>
                    </div>
                  </div>
                </td>

                <td>
                  <h5 className="fs-13 mb-1 fw-normal">
                    {launcher?.startDate
                      ? formatDate(launcher?.startDate)
                      : '-'}
                  </h5>
                  <span className="fs-10 text-muted">
                    {launcher?.startDate
                      ? formatTime(launcher?.startDate)
                      : '-'}
                  </span>
                </td>

                <td>
                  <h5 className="fs-13 mb-1 fw-normal">
                    {launcher?.endDate && !launcher?.neverExpires
                      ? formatDate(launcher?.endDate)
                      : '-'}
                  </h5>
                  <span className="fs-10 text-muted">
                    {launcher?.endDate && !launcher?.neverExpires
                      ? formatTime(launcher?.endDate)
                      : '-'}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <CustomPagination
        pageOffset={pageOffset}
        totalPages={totalPages}
        setPageOffset={setPageOffset}
        paginationHandler={paginationHandler}
      />
      {isLoading ? <InnerLoader /> : null}
    </div>
  );
};

export default PromotionLauncherList;
